import * as _ from 'underscore';
import * as ko from 'knockout';
import * as $ from "jquery";

import 'pubsub';

import {P} from 'Core/Common/Promise';
import {BlockUI} from 'Core/Common/BlockUi';
import {Notifier} from 'Core/Common/Notifier';

import {IFormParam, IScreen, IScreenState} from 'Core/Screens/IScreen';
import {IControl} from 'Core/Controls/IControl';
import {SubForm} from 'Core/Screens/Forms/SubForm/SubForm';
import {ActionBar} from 'Core/Screens/Forms/ActionBar/ActionBar';
import {BottomBar} from 'Core/Screens/Forms/BottomBar/BottomBar';
import {Modal} from 'Core/Common/Modal';
import {ScreenModel} from 'Core/Models/Screens/ScreenModel';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {SubFormModel} from 'Core/Models/Screens/SubFormModel';
import {BaseControl, IControlValue} from 'Core/Controls/BaseControl/BaseControl';
import {Event} from 'Core/Common/Event';
import {
    ControlTypes,
    CONTROL_TYPES,
    FIELD_TYPES,
    FONT_NAME,
    RenderModes,
    SYSTEM_TABLE_NAMES,
    SCREEN_PROPERTY_TYPE
} from 'Core/Constant';
import {ScreenDataModel} from 'Core/ScreenManager/Models/ScreenDataModel';
import {RecordSpecsModel} from 'Core/ScreenManager/Models/RecordSpecsModel';
import {ControlDataModel} from 'Core/ScreenManager/Models/ControlDataModel';
import {Guid} from 'Core/Common/Guid';
import {Icon} from 'Core/Icon/Icon';
import {StaticControlsInitializer} from 'Core/Controls/StaticControlsInitializer';
import {TabPage} from 'Core/Controls/TabPage/TabPage';
import {Tab} from 'Core/Controls/Tab/Tab';
import {Tag} from 'Core/Controls/Tag/Tag';
import {UserManager} from "User/UserManager";
import {SelectUser} from "Core/Controls/SelectUser/SelectUser";
import {AttachedFieldModel} from "Core/Controls/BaseControl/Models/AttachedFieldModel";
import {DataModes} from "Core/Enums/DataModes";
import {TagsScreenDataModel} from "Core/Controls/Tag/Models/TagsScreenDataModel";
import {QueryBuilder} from 'Core/Controls/QueryBuilder/QueryBuilder';
import {HelpBook} from 'HelpBook/HelpBook';
import {IModalComponent} from 'Core/Common/Interfaces/IModalComponent';
import {GridRow} from 'Core/Controls/Grid/BaseGrid/GridRow/GridRow';
import {ScreenSwitcher} from "Core/Components/ScreenSwitcher/ScreenSwitcher";
import {TranslationManager} from "../Components/Translation/TranslationManager";
import {TranslationModel} from "Core/Controls/BaseControl/Models/TranslationModel";
import {EntityTypesStore} from 'Core/Screens/TypeScreen/Stores/EntityTypesStore';
import {IGetDependsOnResponse, RecordStore} from 'Core/Common/Stores/RecordStore';
import {CONFIRMATIONS, LABELS, NOTIFICATIONS} from "../Components/Translation/Locales";
import {PUB_SUB_EVENTS} from '../../MenuManager/PubSubEvents';
import { EVENTS } from './Events';
import { ActionSubjectRecordModel } from 'Core/ScreenManager/Models/ActionSubjectRecordModel';

import {GenericDeserialize} from 'libs/cerialize';
import {FormProperties} from 'Core/Screens/DesignScreen/Models/FormProperties';
import {ISettingsModal} from "Core/Controls/FormDesigner/SettingsModal/SettingsModal";

export interface IDictionary<T> {
    [K: string]: T;
}

export interface IScreenVariable {
    Field: AttachedFieldModel;
    Value: string;

    FullName: string;
}


export interface IControlAttachedField {
    Field: AttachedFieldModel,
    ControlType: string
}

interface CustomStyleProperties {
    [key: string]: string | null;
}

export class BaseScreen extends Event implements IScreen {
    protected _modal: Modal;
    protected _subForms: SubForm[];
    protected _actionBar: ActionBar;
    protected _bottomBar: BottomBar;
    protected _recordId: number;
    protected _tableTypeId: number;
    protected _model: ScreenModel;
    protected _isReady: KnockoutObservable<boolean>;
    private _source: IScreen;
    protected _controls: Array<IControl>;
    protected _renderMode: RenderModes;
    protected _dataModel: ScreenDataModel;
    protected _guid: string;
    protected _inModal: boolean;
    protected _isNested: boolean;
    protected _nestedLevel: number;
    protected _icon: Icon;
    protected _el: any;
    protected _modalOptions: any;
    protected _state: IScreenState;
    protected _isDashMain: boolean;
    protected _showFollowUp: boolean;
    protected _target: HTMLElement;
    protected _isListScreenExist: boolean;
    protected _isSpecialScreenExist: boolean;
    protected _isDashboardScreenExist: boolean;
    protected _isEditScreenExist: boolean;
    protected _isConsultScreenExist: boolean;
    protected _isStepsScreenExist: boolean;
    protected _dataMode: DataModes;
    protected _subjectLifeStatusId: number;
    protected _screenVariables: IDictionary<IScreenVariable>;
    protected _help: HelpBook;
    protected _gridRow: GridRow;
    protected _gridRowList: Array<GridRow>;
    protected _screenSwitcher: KnockoutObservable<ScreenSwitcher>;
    protected _actionSubjectRecord: ActionSubjectRecordModel;
    protected _labels = LABELS;
    private _modalComponents: IModalComponent[];
    private _controlsToUpdate: any;
    public IsReferenceScreen: boolean;
    public IsPathRunnerRefreshed: boolean;
    private _isQueryBuilderScreen: boolean;
    public AllowTimerAutoStop: boolean;
    private _screenFormProperties: FormProperties = {};
    private _screenFormEntityProperties: FormProperties = {};
    public ScreenBackgroundColor: string;
    public ScreenHeaderBackgroundColor: string;
    public TargetId: string;
    public IsOpenedViaBookControl: boolean

    public TransactionId: string;

    constructor(model: ScreenModel, renderMode: RenderModes) {
        super();
        this._isDashMain = model.IsDashMain;
        this._isEditScreenExist = model.IsEditScreenExist;
        this._isListScreenExist = model.IsListScreenExist;
        this._isSpecialScreenExist = model.IsSpecialScreenExist;
        this._isDashboardScreenExist = model.IsDashboardScreenExist;
        this._isConsultScreenExist = model.IsConsultScreenExist;
        this._isStepsScreenExist = model.IsStepsScreenExist;
        this._showFollowUp = model.ShowFollowUp;
        this._guid = Guid.NewGuid();
        this._renderMode = renderMode;
        this._subForms = [];
        this._model = model;
        this._dataMode = model.DataMode;
        this._subjectLifeStatusId = model.SubjectLifestatusId;
        this.TargetId = null;
        this.IsOpenedViaBookControl = null

        this._actionSubjectRecord = model.ActionSubjectRecord;

        this.InitUIColors(model);

        this.InitActionBar();
        this.InitBottomBar();
        this.InitSubForms();

        this.SetDefaultUserSelection();

        this._state = {ActiveControlId: null, ActiveSubControlId: null};
        StaticControlsInitializer.AddStaticControls(this);
        this._controls = this.GetAllControls();

        this._help = HelpBook.Instance;
        this._gridRow = null;
        this._gridRowList = null;

        this._isReady = ko.observable(false);
        this._inModal = false;
        this._isNested = model.IsNested;
        this._nestedLevel = model.NestedLevel || 0;
        this._modalOptions = {
            width: null
        };
        this._modalComponents = [];
        this._screenSwitcher = ko.observable(null);
        this._controlsToUpdate = {};

        this._icon = new Icon(this._model.EntityIcon);

        this.On('CLOSE', this, (eventArgs: any) => {
            this.Close();
        });
        this.On('SHOW_LIST_SCREEN', this, (eventArgs: any) => {
            this.ShowListScreen();
        });
        this.On('SHOW_SPECIAL_SCREEN', this, (eventArgs: any) => this.ShowSpecialScreen(eventArgs.data.ScreenType));
        this.On('SHOW_DASHBOARD_SCREEN', this, () => this.ShowDashboardScreen());
        this.On('SHOW_CONSULT_SCREEN', this, () => this.ShowConsultScreen());
        this.On('SHOW_PREVIOUS_SCREEN', this, (eventArgs: any) => {
            this.ShowPreviousScreen();
        });

        _.each(this._controls, (control: IControl) =>{
            control.On('CONTROL_MODIFY', this, (eventArgs: any) => {
                this.Trigger('CONTROL_MODIFY', {
                    Control: eventArgs.data.Control,
                    Screen: this
                })
            })
        });

        this.IsPathRunnerRefreshed = false;

        this._isQueryBuilderScreen = null;

        this.AllowTimerAutoStop = true;
    }

    get IsQueryBuilderScreen() {
        return this._isQueryBuilderScreen;
    }

    get IsLookupEditorNewRecord(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.LookupEditorNewRecord];
    }

    get IsLinkEditor(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.LinkEditor];
    }

    get IsConsultScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.ConsultScreen];
    }

    get IsPortlet(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.Portlet];
    }

    get IsEditScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.EditScreen];
    }

    get IsProcessCardPageScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.ProcessCardPage];
    }

    get IsCardScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.CardScreen];
    }

    get IsProcessCardScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.ProcessCardPage];
    }

    get IsListScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.ListScreen];
    }

    get IsSpecialScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.SpecialScreen];
    }

    get IsDashboard(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.Dashboard];
    }

    get IsQueryScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.QueryScreen];
    }

    get IsStepsScreen(): boolean {
        return this.GetTypeName() === ScreenTypes[ScreenTypes.StepsScreen];
    }

    InitUIColors(model: ScreenModel): void {
        const deserializeProperties = (properties: string | undefined | null) =>
            properties ? GenericDeserialize(JSON.parse(properties), FormProperties) : new FormProperties();

        this._screenFormProperties = deserializeProperties(model.Properties);
        this._screenFormEntityProperties = deserializeProperties(model.EntityProperties);

        const propertyTypes = [
            SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR,
            SCREEN_PROPERTY_TYPE.SCREEN_HEADER_BACKGROUND_COLOR
        ];

        const [screenBGrColorProperties, screenHeaderBGrColorProperties] = propertyTypes.map(type =>
            this.FindElementByType(this._screenFormProperties.Options, type)?.Value
        );

        const [screenBGrColorEntityProperties, screenHeaderBGrColorEntityProperties] = propertyTypes.map(type =>
            this.FindElementByType(this._screenFormEntityProperties.Options, type)?.Value
        );

        this.ScreenBackgroundColor = screenBGrColorProperties || screenBGrColorEntityProperties;
        this.ScreenHeaderBackgroundColor = screenHeaderBGrColorProperties || screenHeaderBGrColorEntityProperties;
    }

    CopyTextToClipboard(value, callback) {
        if (!navigator.clipboard) {
            const textArea = document.createElement("textarea");
            textArea.value = value;

            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "absolute";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                let successful = document.execCommand("copy");
                if (successful) {
                    callback();
                }
            } catch (error) {
                new Notifier().Failed(error);
                return false;
            }

            document.body.removeChild(textArea);
            return;
        }

        navigator.clipboard.writeText(value)
            .then(() => {
                callback();
            }, (error) => {
                new Notifier().Failed(error);
                return false;
            });
    }

    RecordOpenInPopUp(reference: boolean, isScreen: boolean): boolean{
        return this._inModal = reference && isScreen;
    }

    IsSubjectScreen() {
        return false;
    }

    SetModalOptions(options: any) {
        this._modalOptions = {
            ...options
        };
    }

    GetDataMode(): DataModes {
        return this._dataMode;
    }

    GetDataModel(): ScreenDataModel {
        return this._dataModel;
    }

    GetSubjectLifeStatusId(): number {
        return this._subjectLifeStatusId;
    }

    SetRenderMode(renderMode: RenderModes) {
        this._renderMode = renderMode;
        _.each(this._controls, (item) => {
            item.SetRenderMode(renderMode);
        });
    }

    GetGuid(): string {
        return this._guid;
    }

    SearchRecord(): void {
    }

    EditRecord(): void {
    }

    GetRecordId(): number {
        return this._recordId;
    }

    GetScreenId(): number {
        return this._model.Id;
    }

    GetScreenName(): string {
        return this._model.Name;
    }

    GetScreenTranslatedName(): string {
        const selectedTranslation: TranslationModel = _.find(this._model.TranslationModel, (translation: TranslationModel) => translation.Selected);
        if (selectedTranslation && selectedTranslation.Translation) {
            return selectedTranslation.Translation;
        }

        return this.GetScreenName();
    }

    GetEntityId(): number {
        return this._model.EntityId;
    }

    GetFieldCollectionId(): number {
        return this._model.FieldCollectionId;
    }

    GetTableTypeId(): number {
        return this._model.TableTypeId;
    }

    GetTableTypeFlowFolder(): boolean {
        return this._model.TableTypeFlowFolder;
    }

    GetKindId(): number {
        return this._model.KindId;
    }

    GetEntityIcon(): string {
        return this._model.EntityIcon.FontName;
    }

    GetEntityName(): string {
        return this._model.EntityName;
    }

	GetEntityNameTranslation(): string {
		return this._model.EntityNameTranslation ? this._model.EntityNameTranslation : this._model.EntityName;
	}

    GetWrapper(): HTMLElement {
        return this._el;
    }

    GetControl<TControl extends IControl>(type: string): TControl {
        return <TControl>_.find(this.GetAllControls(), (control) => control.GetType() === type);
    }

    GetControlBy(predicate: (control: IControl) => boolean) {
        return this.GetAllControls().filter(predicate)[0];
    }

    SetActionSubjectRecord(value: ActionSubjectRecordModel) {
        this._actionSubjectRecord = value;
    }

    GetActionSubjectRecord(): ActionSubjectRecordModel {
        if (this._actionSubjectRecord) {
            this._actionSubjectRecord.ActionEntityId = this.GetEntityId();
        }
        return this._actionSubjectRecord;
    }

    get DeleteRecordConfirmation() {
        return this.GetEntityName() === SYSTEM_TABLE_NAMES.SYS_USERS
            ? CONFIRMATIONS.ARE_YOUR_SURE_TO_DISABLE_RECORDS
            : CONFIRMATIONS.ARE_YOUR_SURE_TO_DELETE_RECORDS;
    }

    get DeleteRecordSuccessNotification() {
        return this.GetEntityName() === SYSTEM_TABLE_NAMES.SYS_USERS
            ? NOTIFICATIONS.RECORD_DISABLED
            : NOTIFICATIONS.RECORD_REMOVED;
    }

    get RenderMode() {
        return this._renderMode;
    }

    get Icon(): Icon {
        return this._icon;
    }

    get IsDashMain(): boolean {
        return this._isDashMain;
    }

    get IsEditScreenExist(): boolean {
        return this._isEditScreenExist;
    }

    get IsListScreenExist(): boolean {
        return this._isListScreenExist;
    }

    get IsSpecialScreenExist(): boolean {
        return this._isSpecialScreenExist;
    }

    get IsDashboardScreenExist() : boolean {
        return this._isDashboardScreenExist;
    }

    get IsConsultScreenExist(): boolean {
        return this._isConsultScreenExist;
    }

    get IsStepsScreenExist(): boolean {
        return this._isStepsScreenExist;
    }

    get ScreenDoesNotExistError(): boolean {
        return this._model.ScreenDoesNotExistError;
    }

    get ScreenDoesNotExistErrorMessage(): boolean {
        return this._model.ScreenDoesNotExistErrorMessage;
    }


    get ShowFollowUp(): boolean {
        return this._showFollowUp;
    }

    GetScreenIcon(): Icon {
        return this._icon;
    }

    get SubForms(): SubForm[] {
        return this._subForms;
    }

    get FirstSubForm(): SubForm {
        return this.SubForms[0];
    }

    get ThreeSubFormsWithoutFirst(): SubForm[] {
        return _.rest(this.SubForms)
    }

    get WithLinkList() {
        return this._subForms.some((subForm: any) => subForm._model.Controls[0] && subForm._model.Controls[0].TypeName === CONTROL_TYPES.LinkList)
    }

    get NoLinkList(): boolean {
        return this._subForms.length <= 3;
    }

    get ActionBar(): ActionBar {
        return this._actionBar;
    }

    get BottomBar(): BottomBar {
        return this._bottomBar;
    }

    get IsBottomBarControls(): boolean {
        return this._bottomBar && this._bottomBar.Controls().length > 0;
    }

    HasControl(controlType: string): boolean {
        return !!this.GetControlByType(controlType);
    }

    AttachModalComponent(component: IModalComponent) {
        this._modalComponents.push(component);
    }

    private CloseModalComponents() {
        this._modalComponents.forEach((component) => {
            component.CloseComponentInModal();
        });
    }

    private InitActionBar(): void {
        if (this._model && this._model.ActionBar) {
            var params: IFormParam = {
                Model: this._model.ActionBar,
                RenderMode: this._renderMode,
                Screen: this
            };

            this._actionBar = new ActionBar(params);
        }
    }

    private InitBottomBar(): void {
        if (this._model && this._model.BottomBar) {
            var params: IFormParam = {
                Model: this._model.BottomBar,
                RenderMode: this._renderMode,
                Screen: this
            };
            this._bottomBar = new BottomBar(params);
        }
    }

    private InitSubForms(): void {
        if (this._model && this._model.SubForms) {
            _.each(this._model.SubForms, (model: SubFormModel, index) => {
                var params: IFormParam = {
                    Model: model,
                    RenderMode: this._renderMode,
                    Screen: this
                };

                var subForm = new SubForm(params);

                if (this.IsEditScreen && index === 3) {
                    subForm.ShowExpandButton = true;
                }

                if (this.GetType() === ScreenTypes[ScreenTypes.EditScreen] && this._renderMode !== RenderModes.Design) {
                    if (index === 3) {
                        if (subForm.Controls().length > 0) {
                            this._subForms.push(subForm);
                        }
                    } else {
                        this._subForms.push(subForm);
                    }
                } else {
                    this._subForms.push(subForm);
                }
            });
        }
    }

    SetIsQueryBuilderScreen(data: boolean): void {
        this._isQueryBuilderScreen = data;
    }

    SetDefaultUserSelection() {
        const selectUser = this.GetControl(CONTROL_TYPES.SelectUser) as SelectUser;

        if ((!selectUser || !selectUser.IsRememberMe) && UserManager.Instance.CurrentUser) {
            this.Trigger('UsersSelectionChanged', {SelectedUsers: [UserManager.Instance.CurrentUser.Id]});
        }
    }

    SetData(dataModel: ScreenDataModel) {
        if (!dataModel) {
            return;
        }

        const languagesReceived = _.size(this._model.Languages) > 0;

        this._actionSubjectRecord = dataModel.ActionSubjectRecord || this._actionSubjectRecord;

        let languagesUpdatedPromise = null;
        if (languagesReceived) {
            const activeLanguages = this._model.Languages.map(l => l.Id);
            languagesUpdatedPromise = TranslationManager.Instance.UpdateLanguages(activeLanguages)
        } else {
            languagesUpdatedPromise = TranslationManager.Instance.LoadLanguages();
        }

        languagesUpdatedPromise
            .then(() => {
                this.ApplyData(dataModel, (control, controlValue) => control.SetValue(controlValue));
            });
    }

    SetCurrentGridRowData(gridRow: GridRow) {
        this._gridRow = gridRow;
    }

    GetCurrentGridRow() {
        return this._gridRow;
    }

    SetGridRowListData(gridRowList: Array<GridRow>) {
        this._gridRowList = gridRowList;
    }

    GetCurrentGridRowList() {
        return this._gridRowList;
    }

    SetDefaultData(dataModel: ScreenDataModel, excludingControlsTypes = null) {
        const mergedDataModel = Object.assign({}, this._dataModel, dataModel);
        this.ApplyData(mergedDataModel, (control, controlValue) => control.SetDefaultValue(controlValue), excludingControlsTypes);
    }

    private ApplyData(dataModel: ScreenDataModel, setValueCallback: (control: IControl, controlValue: IControlValue) => void, excludingControlsTypes = null) {
        if (dataModel) {
            if (dataModel.ControlsData) {
                this._recordId = dataModel.RecordId;
                this._dataModel = dataModel;
                let controls = this._controls;
                if (excludingControlsTypes) {
                    controls = _.filter(this._controls, control => !_.contains(excludingControlsTypes, control.GetType()));
                }
                _.each(controls,
                    control => {
                        let baseControl = control as BaseControl;
                        let controlData = this.GetControlDataByControlId(control.GetControlId(), this._dataModel.ControlsData);

                        if (!controlData) {
                            var field = baseControl.FieldModel;
                            if (field) {
                                controlData = this.GetControlDataByField(field.Id, this._dataModel.ControlsData);
                                if (!controlData) {
                                    controlData = new ControlDataModel();
                                    controlData.ControlId = control.GetControlId();
                                    controlData.Rights = this._dataModel.Rights;
                                }
                            } else {
                                controlData = new ControlDataModel();
                                controlData.Rights = this._dataModel.Rights;
                                controlData.ControlId = control.GetControlId();
                            }
                        }

                        if (!controlData.Rights) {
                            controlData.Rights = this._dataModel.Rights;
                        }

                        if (control instanceof Tag) {
                            let controlTags = this.GetTagControlData(control.GetControlId());
                            if (controlTags) {
                                controlData.Value = JSON.stringify(controlTags);
                            }
                        }

                        if (control instanceof QueryBuilder) {
                            let queryBuilderData = this.GetQueryBuilderControlData(control.GetControlId());

                            var queryBuilderValue: IControlValue = {
                                Datas: queryBuilderData,
                                SubjectEntityId: this._model.EntityId,
                                SubjectRecordId: this._recordId,
                                RecordSpecsModel: dataModel.RecordSpecs || new RecordSpecsModel()
                            };
                            control.SetValue(queryBuilderValue);
                            return;
                        }

                        var controlValue: IControlValue = {
                            Data: controlData,
                            SubjectEntityId: this._model.EntityId,
                            SubjectRecordId: this._recordId,
                            RecordSpecsModel: dataModel.RecordSpecs || new RecordSpecsModel()
                        };

                        setValueCallback(control, controlValue);
                    });
            }
        }

        _.each(this._controls, (control)=>{
            control.PostInit();
        });
    }

    GetControlDataByControlId(controlId: number, controlsData: Array<ControlDataModel>): ControlDataModel {
        var controlData = _.find(controlsData, (item: ControlDataModel) => {
            return item.ControlId === controlId
        });
        return controlData;
    }

    GetControlDataByField(fieldId: number, controlsData: Array<ControlDataModel>): ControlDataModel {
        var controlData = _.find(controlsData, (item: ControlDataModel) => {
            return item.FieldId === fieldId;
        });
        return controlData;
    }

    private GetQueryBuilderControlData(controlId: number): Array<ControlDataModel> {
        let data = this._model.Data;
        if (data) {
            if (data.ScreenTags) {
                let result = _.where(data.QueryBuilderData, (item: ControlDataModel) => item.ControlId === controlId);
                return result;
            }
        }
        return null;
    }

    GetFieldByFieldName(fieldName: string): AttachedFieldModel {
        let result;

        _.forEach(this.GetAllControls(), control => {
            let field = control.GetFieldModel();
            if (field && field.Name === fieldName) {
                result = field;
            }
        });

        return result;
    }

    GetControlByFieldName(fieldName: string, controlTypeName?: string): BaseControl {
        let controls = controlTypeName ? this.GetControlsByType(controlTypeName) : this.GetAllControls();
        const nameControl = _.find(controls, control => {
            const field = control.GetFieldModel();
            return field && field.Name === fieldName;
        });

        return nameControl as BaseControl;
    }

    GetControlByFieldNameOnScreenEntity(fieldName: string, controlTypeName?: string): BaseControl {
        let controls = controlTypeName ? this.GetControlsByType(controlTypeName) : this.GetAllControls();
        const screenEntityId = this.GetEntityId();

        const nameControl = _.find(controls, control => {
            const field = control.GetFieldModel();
            return field && field.Name === fieldName && field.EntityId === screenEntityId;
        });

        return nameControl as BaseControl;
    }

    GetDependsOnControls(fieldId: number, controlTypeName?: string): Array<IControl> {
        let controls = controlTypeName ? this.GetControlsByType(controlTypeName) : this.GetAllControls();

        let filtered = _.filter(controls, (control) => {
            const fieldModel = control.GetFieldModel();
            return fieldModel.DependsOnId && fieldModel.DependsOnId === fieldId;
        });

        return filtered;
    }

    RemoveNonEmptyDependsOn(dependsOnControls: Array<IControl>): Array<IControl> {
        return _.filter(dependsOnControls, (control) => {
            const fieldModel = control.GetFieldModel();
            const controlValue = control.GetValue();

            return fieldModel.IsVirtual || fieldModel.FieldTypeName !== FIELD_TYPES.Text
                || !controlValue || _.isEmpty(controlValue);
        });
    }

    public GetAllControls(): Array<IControl> {
        var controls: Array<IControl> = [];

        if (this._actionBar) {
            var actionBarControls = this._actionBar.Controls();
            controls = controls.concat(actionBarControls);
            _.each(actionBarControls, (control: IControl) => {
                controls = controls.concat(control.GetAllSubControls());
            });
        }

        if (this._bottomBar) {
            var bottomBarControls = this._bottomBar.Controls();
            controls = controls.concat(bottomBarControls);
            _.each(bottomBarControls, (control: IControl) => {
                controls = controls.concat(control.GetAllSubControls());
            });
        }

        _.each(this._subForms, (subForm) => {
            var subFormControls = subForm.Controls();
            controls = controls.concat(subFormControls);
            var subControls = [];
            _.each(subFormControls, (control: IControl) => {
                subControls = subControls.concat(control.GetAllSubControls());
            });
            controls = controls.concat(subControls);
        });

        return controls;
    }

    RenderByTargetId(target: string): void {
        this.TargetId = target;
        this._target = document.getElementById(target);
        ko.cleanNode(this._target);
        ko.applyBindings(this, this._target);

        PubSub.publish(PUB_SUB_EVENTS.SCREEN_OPENED, this);
    }

    RenderByTarget(target: HTMLElement): void {
        this._target = target;
        ko.cleanNode(target);
        ko.applyBindings(this, target);

        PubSub.publish(PUB_SUB_EVENTS.SCREEN_OPENED, this);
    }

    GetTarget(): HTMLElement {
        return this._target;
    }

    SetScreenStyle(isModal?: boolean, isParentControlExist?: boolean): void {
        const pageContainer: JQuery<HTMLElement> = $(document.body).find(".page-container");
        const pageHeader: JQuery<HTMLElement> = $(document.body).find(".page-header.navbar");

        const openInPopUpByCtrl = !isModal && this.IsInModal();
        if (openInPopUpByCtrl) {
            isModal = openInPopUpByCtrl;
        }

        if (this.ScreenBackgroundColor) {
            const setColorForContainer = () => {
                if (isParentControlExist) {
                    let properties: CustomStyleProperties = {
                        '--book-background-color': this.ScreenBackgroundColor,
                        '--root-background-color': this.ScreenBackgroundColor
                    };
                    this.SetStyleProperty($(this._el).parent(), properties);
                } else if (!isModal) {
                    let properties: CustomStyleProperties = {
                        '--root-background-color': this.ScreenBackgroundColor
                    };
                    this.SetStyleProperty(pageContainer, properties);
                }
            };
            setColorForContainer();
        }

        if (this.ScreenHeaderBackgroundColor) {
            if (!isModal && !isParentControlExist) {
                let properties: CustomStyleProperties = {
                    'background-color': this.ScreenHeaderBackgroundColor,
                    '--page-header-hover-color': this.DarkenHexColor(this.ScreenHeaderBackgroundColor, 50)
                };
                this.SetStyleProperty(pageHeader, properties);
            }
            if (isModal && !isParentControlExist) {
                const jBoxContainer: JQuery<HTMLElement> = $(this._el).closest(".jBox-container");
                let properties: CustomStyleProperties = {
                    '--root-background-color': this.ScreenBackgroundColor
                };
                this.SetStyleProperty(jBoxContainer, properties);
            }
        }
    }

    SetStyleProperty(element: JQuery<HTMLElement> | null, properties: CustomStyleProperties): void {
        if (element && element.length) {
            for (const [property, value] of Object.entries(properties)) {
                element.get(0).style.setProperty(property, value);
            }
        }
    }

    DarkenHexColor(hex: string, amount: number): string {
        hex = hex.replace(/^#/, '');

        let r: number = parseInt(hex.substring(0, 2), 16);
        let g: number = parseInt(hex.substring(2, 4), 16);
        let b: number = parseInt(hex.substring(4, 6), 16);

        if (hex === '000000') { //Increasing the value of each channel
            r = Math.max(0, r + amount);
            g = Math.max(0, g + amount);
            b = Math.max(0, b + amount);
        } else { //Decrease the value of each channel
            r = Math.max(0, r - amount);
            g = Math.max(0, g - amount);
            b = Math.max(0, b - amount);
        }

        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    FindElementByType(array: ISettingsModal[], type: string): ISettingsModal | undefined {
        return _.find(array, { Type: type });
    }

    AfterRender(el) {
        this._el = el[0];
    }

    GetTypeId() {
        return this._model.ScreenTypeId;
    }

    GetType() {
        return this._model.ScreenTypeName;
    }

    get TemplateName(): string {
        return this._model.TemplateName;
    }

    SetRecordId(recordId: number): void {
    };

    SetRecordIdAndRender(recordId: number, target: string): void {
    };

    SetIsReady(isReady: boolean) {
        this._isReady(true);
    }

    GetSource() {
        return this._source;
    }

    SetSource(screen: IScreen) {
        this._source = screen;
    }

    GetTemplateName(): string {
        throw new Error('GetTemplateName not implemented');
    }

    ShowInModal(extraClass?: string) {
        const currentGridRow = this.GetCurrentGridRow();
        const currentGridRowList = this.GetCurrentGridRowList();

        if (currentGridRow && currentGridRowList) {
            const recordsIds = [...new Set(currentGridRowList.map(row => row.RecordId))];

            const distincLinkedRecords = [];

            recordsIds.forEach(id => distincLinkedRecords.push(currentGridRowList.find(row => row.RecordId == id)));

            this._screenSwitcher(new ScreenSwitcher(currentGridRow, distincLinkedRecords, this));
        }

        this._inModal = true;

        let isUseGlobal = !this._modalOptions.width,
            extraClassValue = !!extraClass ? extraClass : '',
            options = this._modalOptions || {};

        options.blockScroll = true;
        options.addClass = options.addClass ? options.addClass : `showScrollModal jBox-padding-15px ${extraClassValue}`;

        this._modal = new Modal(options, isUseGlobal);
        this._modal.On('CLOSE', this, () => {
            this.CloseModalComponents();
            this.Trigger(EVENTS.MODAL_CLOSE);
            if (this.IsConsultScreen) {
                this.IsPathRunnerRefreshed = true;
            }
            PubSub.publish(PUB_SUB_EVENTS.SCREEN_CLOSED, this);
        });


        ko.cleanNode(this._modal.Wrapper);
        ko.applyBindings(this, this._modal.Wrapper);
        this._modal.Show();

        PubSub.publish(PUB_SUB_EVENTS.SCREEN_OPENED, this);
        PubSub.publish(PUB_SUB_EVENTS.CHANGE_SCREEN, this);
    }

    IsInModal(): boolean {
        return this._inModal;
    }

    IsNested(): boolean {
        return this._isNested;
    }

    get NestedLevel(): number {
        return this._nestedLevel;
    }

    IsEntitySysUsersOrCd() {
        const entityName = this.GetEntityName();
        return entityName && (entityName === SYSTEM_TABLE_NAMES.SYS_USERS || entityName.startsWith('CD_'));
    }

    Close(skipClosedEvent?: boolean) {
        if (this._modal) {
            this._modal.Close();
        }

        this.Dispose();

        if (!skipClosedEvent) {
            PubSub.publish(PUB_SUB_EVENTS.SCREEN_CLOSED, this);
        }
    }

    GetId(): number {
        return this._model.Id;
    }

    GetTypeName(): string {
        return this._model.ScreenTypeName;
    }

    GetTableTypeName(): string {
        return this._model.TableTypeName;
    }

    GetTableTypeNameTranslation(): string {
        return this._model.TableTypeNameTranslation;
    }

    GetControlByType(controlType: string): IControl {
        return _.first(this.GetControlsByType(controlType));
    }

    GetControlsByType(controlType: string): IControl[] {
        return _.filter(this.GetAllControls(), (control: IControl) => control.GetType() === controlType);
    }

    RestoreState(screenState: IScreenState) {
        if (screenState) {
            _.each(this._controls, (control) => {
                if (control.GetControlId() === screenState.ActiveControlId) {
                    control.SetIsActive(true);
                    if (control instanceof TabPage) {
                        var parent = control.GetParentControl();
                        if (parent && parent instanceof Tab) {
                            var tabControl = <Tab>parent;
                            tabControl.SetActiveTabPage(control);
                        }
                    }
                } else {
                    control.SetIsActive(false);
                }
            });
        }
    }

    GetState(): IScreenState {
        return this._state;
    }

    SetState(screenState: IScreenState) {
        this._state = screenState;
        _.each(this._controls, (control) => {
            if (control.GetControlTypeName() !== CONTROL_TYPES.TabPage) {
                control.SetIsActive(null);
            }

            if (control.GetControlId() === this._state.ActiveControlId || control.GetControlId() === this._state.ActiveSubControlId) {
                control.SetIsActive(true);
            }
        });
    }

    Dispose() {
        this.GetAllControls().forEach(control => control.Dispose());
    }

    Refresh() {
    }

    async ShowListScreen(): Promise<void> {
        const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
        menuManager.Instance.GoToScreenByType(this.GetEntityId(), ScreenTypes.ListScreen, this._inModal);
    }

    async ShowSpecialScreen(screenType: ScreenTypes): Promise<void> {
        const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
        menuManager.Instance.GoToScreenByType(this.GetEntityId(), screenType, this._inModal);
    }

    async ShowDashboardScreen() : Promise<void> {
        const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
        menuManager.Instance.GoToScreenByType(this.GetEntityId(), ScreenTypes.Dashboard, this._inModal);
    }

    async ShowConsultScreen(): Promise<void> {
        const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
        menuManager.Instance.GoToScreenByType(this.GetEntityId(), ScreenTypes.ConsultScreen, this._inModal);
    }

    async ShowPreviousScreen() {
        if (this._inModal) {
            this.Close();
        }
        const menuManager = (await import ("MenuManager/MenuManager")).MenuManager;
        menuManager.Instance.BackToPreviousScreen(this._inModal);
    }

    GetAllAttachedFields(excludeControlTypes: Array<string>, onlyScreenSubject: boolean): Array<IControlAttachedField> {
        var fields: Array<IControlAttachedField> = [];
        _.each(this.GetAllControls(),
            (control) => {
                if (excludeControlTypes.indexOf(control.GetType()) === -1) {
                    _.each(control.Fields, field => {

                        if(control.GetType() === CONTROL_TYPES.LinkList){
                            let linkListField = new AttachedFieldModel();
                            linkListField.EntityName = field.EntityName;
                            linkListField.Name = field.PrimaryKeyName;
                            linkListField.FieldTypeName = FIELD_TYPES.PKey;
                            field = linkListField;
                        }

                        if (onlyScreenSubject) {
                            if (field.EntityId === this.GetEntityId()) {
                                fields.push({ Field: field, ControlType: control.GetControlTypeName()});
                            }
                        } else {
                            fields.push({ Field: field, ControlType: control.GetControlTypeName() });
                        }
                    });
                }
            });
        return fields;
    }

    private GetTagControlData(controlId: number): TagsScreenDataModel {
        let data = this._model.Data;
        if (data) {
            if (data.ScreenTags) {
                let result = _.find(data.ScreenTags, (tagsModel: TagsScreenDataModel) => tagsModel.ControlId === controlId);
                return result;
            }
        }
        return null;
    }

    IsTypeAvailable(typeId: number, exampleTypeId: number, entityId): P.Promise<any> {
        BlockUI.Block();
        var deferredResult = P.defer<any>();

        EntityTypesStore.IsTypeAvailable({TypeId: typeId, ExampleTypeId: exampleTypeId, EntityId: entityId })
            .always(() => {
                BlockUI.Unblock();
            })
            .then(isTypeAvailableModel => {
                if (!isTypeAvailableModel.IsTypeAvailable) {
                    new Notifier().Warning(isTypeAvailableModel.WarningMessage);
                    return;
                }

                deferredResult.resolve(null);
            })
            .fail(error => {
                new Notifier().Warning(error.message);
            });

        return deferredResult.promise();
    }

    UpdateVariable(controlField: IControlAttachedField, value: string) {
        let variableName = `$[${controlField.Field.EntityName}].[${controlField.Field.Name}]`;

        if(controlField.ControlType === CONTROL_TYPES.LinkList){
            variableName = `$[LinkList].[Main].[${controlField.Field.EntityName}].[${controlField.Field.Name}]`;
        }

        let screenVariable: IScreenVariable = { FullName: variableName, Field: controlField.Field, Value: value };
        this.Trigger(EVENTS.DATA_CHANGED, { ScreenVariable: screenVariable });
    }

    UpdateDependsOnValues(recordId: number, dependsOnId: number, isPreselect?: boolean) {
        let dependsOnFields = this.GetDependsOnControls(dependsOnId, CONTROL_TYPES.Text);
        let fieldIds: Array<number> = [];

        if (isPreselect) {
            dependsOnFields = this.RemoveNonEmptyDependsOn(dependsOnFields);
        }

        let controlsByDependsOn = {};

        _.forEach(dependsOnFields, (control) => {
            const key = control.GetFieldModel().FilledById;
            if (controlsByDependsOn[key]) {
                controlsByDependsOn[key].push(control);
            } else {
                controlsByDependsOn[key] = [];
                controlsByDependsOn[key].push(control);
                fieldIds.push(key);
            }
        });

        this._controlsToUpdate[dependsOnId] = controlsByDependsOn;

        if (fieldIds.length) {
            if (recordId) {
                RecordStore.GetDependsOnValues({FieldIds: fieldIds, RecordId: recordId})
                    .then((response: IGetDependsOnResponse) => {
                        const controls = response.ResultObject;
                        _.forEach(controls, (item) => {
                            const controlsToUpdate = this._controlsToUpdate[dependsOnId] && this._controlsToUpdate[dependsOnId][item.Id];
                            _.forEach(controlsToUpdate, (controlToUpdate: IControl) => {
                                const controlData = new ControlDataModel();
                                controlData.Value = item.Value;

                                if (controlToUpdate.GetType() === CONTROL_TYPES.Text) {
                                    controlData.DisplayValue = item.Value;
                                }

                                controlToUpdate.SetValue({
                                    SubjectEntityId: this._model.EntityId,
                                    SubjectRecordId: this._recordId,
                                    RecordSpecsModel: new RecordSpecsModel(),
                                    Data: controlData
                                })
                            });
                        });
                    })
                    .fail((error) => {
                        new Notifier().Failed('Error getting data');
                    });

            } else {
                _.forEach(fieldIds, (fieldId) => {
                    const controlsToUpdate = this._controlsToUpdate[fieldId];
                    _.forEach(controlsToUpdate, (control: IControl) => {
                        const controlData = new ControlDataModel();
                        controlData.Value = '';
                        control.SetValue({
                            SubjectEntityId: this._model.EntityId,
                            SubjectRecordId: this._recordId,
                            RecordSpecsModel: new RecordSpecsModel(),
                            Data: controlData
                        })
                    });
                });
            }
        }
    }
}