import { ListScreen } from './../ListScreen/ListScreen';
import * as ko from 'knockout';
import * as _ from 'underscore';
import * as $ from 'jquery';

import clone from 'clone';

import {GenericDeserialize, Serialize} from 'libs/cerialize';
import * as GridStack from 'gridstack';
import {P} from 'Core/Common/Promise';
import {BaseScreen} from 'Core/Screens/BaseScreen'
import {Search} from 'Core/Controls/Search/Search'
import {ButtonFavorite} from 'Core/Controls/ButtonFavorite/ButtonFavorite'
import {Grid} from 'Core/Controls/Grid/Grid'
import {IControlParam, IForm} from 'Core/Screens/IScreen'
import {
    CONTROL_TYPES,
    FIELD_TYPE_TO_CONTROL,
    FIELD_TYPES,
    RenderModes,
    CARD_SCREEN_PROPERTY_TYPE
} from 'Core/Constant'
import {DesignScreenModel} from 'Core/Models/Screens/ScreenModel'
import {ControlModel} from 'Core/Controls/BaseControl/Models/ControlModel'
import {TranslationModel} from 'Core/Controls/BaseControl/Models/TranslationModel';
import {IControl} from 'Core/Controls/IControl'
import {Tab} from 'Core/Controls/Tab/Tab'
import {TabPage} from 'Core/Controls/TabPage/TabPage'
import {DragulaExtention} from 'Core/KnockoutExtentions/DragulaExtention'
import {BaseControl} from 'Core/Controls/BaseControl/BaseControl'
import {LinkList} from 'Core/Controls/LinkList/LinkList'
import {ButtonTemplate} from 'Core/Controls/ButtonTemplate/ButtonTemplate'
import {FieldModel} from 'QueryBuilder/Models/FieldModel'
import {ControlFactory} from 'Core/Controls/ControlFactory'
import {UpdateScreenStore} from 'Core/Screens/DesignScreen/Stores/UpdateScreenStore'
import {
    ControlModel as UpdateControlModel,
    MainFormUpdateModel,
    UpdateScreenModel
} from 'Core/Screens/DesignScreen/Models/UpdateScreenModel';
import {
    ConfirmationDialog,
    EVENTS as CONFIRMATION_DIALOG_EVENTS,
    Types as ConfirmationTypes
} from 'Core/Components/Dialogs/ConfirmationDialog/ConfirmationDialog';
import {DashboardBlock, FormProperties} from 'Core/Screens/DesignScreen/Models/FormProperties';
import {ControlEditorFactory} from 'Core/Screens/DesignScreen/ControlEditor/Factory/ControlEditorFactory';
import {AttachedFieldModel} from 'Core/Controls/BaseControl/Models/AttachedFieldModel'
import {SubForm} from 'Core/Screens/Forms/SubForm/SubForm'
import {BaseForm} from 'Core/Screens/Forms/Base/BaseForm'
import {ActionBar} from 'Core/Screens/Forms/ActionBar/ActionBar'
import {BottomBar} from 'Core/Screens/Forms/BottomBar/BottomBar'
import {ControlGroup} from 'Core/Controls/FormDesigner/ToolBar/ControlToolBar/ControlGroup';
import {ControlEditorModel} from 'Core/Screens/DesignScreen/ControlEditor/Models/ControlEditorModel'
import {EntityModel} from 'QueryBuilder/Models/EntityModel'
import {Notifier} from 'Core/Common/Notifier'
import {IconModel} from 'Core/Controls/BaseControl/Models/IconModel';
import {Icon} from 'Core/Icon/Icon';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {ConcreteEntityModel} from 'QueryBuilder/Models/ConcreteEntityModel';
import {ControlGroupModel} from "Core/Controls/FormDesigner/ToolBar/ControlToolBar/Models/ControlGroupModel";
import {LabelPositions} from "Core/Screens/DesignScreen/ControlEditor/Enums/LabelPositions";
import {ScreenTypeToDropControlValidation} from "Core/Screens/DesignScreen/AfterDropValidation/ScreenTypeToAfterDropValidation";
import {AfterDropValidation} from "Core/Screens/DesignScreen/AfterDropValidation/AfterDropValidation";
import {Basket} from 'Core/Controls/Basket/Basket';
import {GenericButton} from 'Core/Controls/GenericButton/GenericButton';
import {LABELS, NOTIFICATIONS, CONFIRMATIONS} from "Core/Components/Translation/Locales";
import {defaultGridStackOptions} from "Core/Screens/Dashboard/DashboardUtils";

import BaseDesignScreenTemplate from 'Core/Screens/Templates/DesignScreen/BaseDesignScreen.html'
import DashboardDesignScreenTemplate from 'Core/Screens/Templates/DesignScreen/DashboardDesignScreen.html'
import ProcessCardDesignScreenTemplate from 'Core/Screens/Templates/DesignScreen/ProcessCardDesignScreen.html'

import {ComplexControl} from 'Core/Controls/ComplexControl/ComplexControl';
import {TranslationManager} from "Core/Components/Translation/TranslationManager";
import {LanguageModel} from "Core/Controls/BaseControl/Models/LanguageModel";
import {GlobalManager, GLOBALS} from "Core/GlobalManager/GlobalManager";
import {Planner} from "Core/Controls/Planner/Planner";
import {PlannerPage} from "Core/Controls/PlannerPage/PlannerPage";
import {PlannerLevel} from "../../Controls/PlannerLevel/PlannerLevel";
import {SPIMCopy} from "../../Controls/SpimCopy/SpimCopy";
import {EntityTypes} from "../../Controls/Grid/BaseGrid/Enums/EntityTypes";
import {ButtonCopy} from '../../Controls/ButtonCopy/ButtonCopy';
import {KanbanBoard} from '../../Controls/Kanban/KanbanBoard';
import {SubFormModel} from '../../Models/Screens/SubFormModel';
import {RecipeEditor} from "../../Controls/RecipeEditor/RecipeEditor";
import {Timer} from "Core/Controls/Timer/Timer";
import {log} from "fullcalendar/src/util";
import {ISettingsModal} from "../../Controls/FormDesigner/SettingsModal/SettingsModal";
import { Step } from '../../Controls/Step/Step';
import { StepPage } from '../../Controls/StepPage/StepPage';
import { ButtonPlan } from '../../Controls/ButtonPlan/ButtonPlan';

ko.templates['Core/Screens/Templates/DesignScreen/BaseDesignScreen'] = BaseDesignScreenTemplate;
ko.templates['Core/Screens/Templates/DesignScreen/DashboardDesignScreen'] = DashboardDesignScreenTemplate;
ko.templates['Core/Screens/Templates/DesignScreen/ProcessCardDesignScreen'] = ProcessCardDesignScreenTemplate;

const DEFAULT_LABEL_POSITION = 'Left';
const LINK_LIST_REQUIRED_ENTITY = 'SYS_USERS';
const CONRTOL_WITH_BLOBFIELDATTACHED = ['Image', 'Document'];

enum RelationTypes {
    OneToOneSubject,
    OneToOne,
    OneToMain,
    OneToMany
}

enum SortGroups {
    Header,
    OptionalHeader,
    Custom,
    OptionalFooter,
    Footer,
    Undefined
}

function getSubFormGridOptions(subForm: SubForm) {
    return {
        id: subForm.GetFormGuid(),
        content: `
            <div class="mainSubForm form-wrapper__main-sub-form grid-stack-item__main-sub-form">
                <div class="dashboardSubForm-box" id="${subForm.GetFormGuid()}"></div>
            </div>
        `
    };
}

export class DesignScreen extends BaseScreen {
    private _designModel: DesignScreenModel;
    private _subFormsWith: KnockoutObservable<any>;
    private _configuringLayout: KnockoutObservable<boolean>;
    private _updateModel: UpdateScreenModel;
	private _hasChanges: KnockoutObservable<boolean>;
    private _afterDropValidation: AfterDropValidation;
    private _formProperties: FormProperties = new FormProperties();
    private _gridStack: GridStack;
    SubjectEntity: ConcreteEntityModel;

    private _hasCustomColor: KnockoutObservable<boolean>;
    private _hasCustomWeight: KnockoutObservable<boolean>;
    private _screenWidth: KnockoutObservable<any>;
    private _screenBgColor: KnockoutObservable<any>;
    private _screenColors: KnockoutObservable<any>;
    private _fontColor: KnockoutObservable<any>;
    private _customStyle: KnockoutObservable<any>;

    private _fontFamily: KnockoutObservable<string>;
    private _fontWeight: KnockoutObservable<string>;
    private _fontStyle: KnockoutObservable<string>;

    constructor(screenModel: DesignScreenModel, subjectEntity: ConcreteEntityModel) {
        super(screenModel.ExistingScreenViewModel, RenderModes.Design);

        this.SubjectEntity = subjectEntity;
        this._designModel = screenModel;
        this._subFormsWith = ko.observable({});
        this._configuringLayout = ko.observable(false);
        this.InitCalcSubFormSize();
        this._updateModel = new UpdateScreenModel();
        this._hasChanges = ko.observable(false);
        this._afterDropValidation = ScreenTypeToDropControlValidation.GetValidationDelegate(this.GetType());
        this._hasCustomColor = ko.observable(null);
        this._hasCustomWeight = ko.observable(null);
        this._screenWidth = ko.observable(null);
        this._screenBgColor = ko.observable(null);
        this._screenColors = ko.observable(null);
        this._fontColor = ko.observable(null);
        this._customStyle = ko.observable(null);

        this._fontFamily = ko.observable(null);
        this._fontWeight = ko.observable(null);
        this._fontStyle = ko.observable(null);
        this._updateModel.IsEnablePathRunner = screenModel.ExistingScreenViewModel.IsEnablePathRunner;

        try {
            this._formProperties = GenericDeserialize(JSON.parse(screenModel.FormProperties), FormProperties);
        } catch (error) {
        }

        let body = $('body');
        if (!body.hasClass('Dashboard') && this.IsDashboard){
            body.addClass('Dashboard');
        }

        this.Init();
        this.InitEvents();
    }

    set HasCustomColor(val: boolean) {
        this._hasCustomColor(val);
    }
    set HasCustomWeight(val: boolean) {
        this._hasCustomWeight(val);
    }
    set ScreenWidth(val: string) {
        this._screenWidth({width: val});
    }
    set ScreenBgColor(val: string) {
        this._screenBgColor({backgroundColor: val})
    }
    set Color(val: string) {
        this._fontColor({color: val});
        this._hasCustomColor(!!val);
    }

    set FontFamily(val:string) {
        this._fontFamily(val)
        this._customStyle(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
    }
    set FontWeight(val:string) {
        this._fontWeight(val)
        this._customStyle(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
        this._hasCustomWeight(!!this._fontWeight());
    }
    set FontStyle(val:string) {
        this._fontStyle(val)
        this._customStyle(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);
    }

    private InitEvents() {
        this.On('CONTROL_MODIFY', this, (eventArgs: any) => {
            this._hasChanges(true);
            eventArgs.data.Control.ModelHasMutated();
        });
    }

    IsSubjectScreen() {
        const screenType = this.GetType();
        return screenType === ScreenTypes[ScreenTypes.ConsultScreen]
            || screenType === ScreenTypes[ScreenTypes.EditScreen];
    }

    get ScreenId(): number {
        return this._model.Id;
    }

    get ControlGroups(): Array<ControlGroupModel> {
        return this._designModel.ControlToolbar.Groups;
    }


    TogglePathRunner(){
        this._updateModel.IsEnablePathRunner = !this._updateModel.IsEnablePathRunner;
        this._hasChanges(true);
    }

    get IsEnablePathRunner(){
        return  this._updateModel.IsEnablePathRunner;
    }

    StartLayoutConfiguration() {
        this._configuringLayout(true);

        if (this.IsDashboard) {
            this._gridStack.setStatic(false);

            this._subForms.forEach(subForm => subForm.StartLayoutConfiguration());
        }
    }

    ApplyLayout() {
        this._configuringLayout(false);

        if (this.IsDashboard) {
            this._gridStack.setStatic(true);

            this._subForms.forEach(subForm => subForm.ApplyLayout());
        }
    }

    private GetDefaultControlModel(controlType: string) {
        let controlModel = null;
        _.each(this.ControlGroups, (controlGroup) => {
            const model = _.find(controlGroup.Controls, (control) => {
                return control.TypeName === controlType
            });

            if (model) {
                controlModel = model;
            }
        });
        return controlModel;
    }

    get Controls(): Array<IControl> {
        return this._controls;
    }

    get HasChanges(): boolean {
        return this._hasChanges();
    }

    set HasChanges(value: boolean) {
        this._hasChanges(value);
    }

    get ScreenType(): string {
        return this._model.ScreenTypeName;
    }

    private DestroyDragula() {
        const toolBarToDesignerDrake = DragulaExtention.FindGroup('ToolBarToDesigner');
        if (toolBarToDesignerDrake) {
            DragulaExtention.DestroyGroup(toolBarToDesignerDrake);
        }
    }

    private Init() {
        this.DestroyDragula();
        this.SubForms.forEach(subForm => this.BindSubFormEvents(subForm));

        const toolBarToDesignerDrake = DragulaExtention.AddGroupWithOptions('ToolBarToDesigner', {
            accepts: (el, target, source, sibling) => {
                const elModel = ko.dataFor(el);
                const targetModel = ko.dataFor(target);
                const sourceModel = ko.dataFor(source);

                if (elModel instanceof FieldModel) {
                    if (elModel.FieldTypeName === FIELD_TYPES.Property) {
                        return false;
                    }

                    if (elModel.FieldTypeName === FIELD_TYPES.Spim) {

                        if (!(targetModel instanceof Grid)) {
                            return false;
                        }

                        const isSubTableGrid = targetModel.FieldModel && targetModel.FieldModel.EntityTypeName === EntityTypes[EntityTypes.Sub];
                        if (!isSubTableGrid) {
                            return false;
                        }
                    }
                }

                if (elModel instanceof LinkList) {
                    //allow move link list only in Main4 block
                    let screenModel = this._designModel.ExistingScreenViewModel;
                    let allowedLinkListSubFormIndex = 3;
                    if (this._subForms.indexOf(targetModel) !== allowedLinkListSubFormIndex) {
                        return false;
                    }

                    //forbid linkList dropping on the Edit Screen of the SYS_USERS
                    if (screenModel.EntityName === LINK_LIST_REQUIRED_ENTITY) {
                        if (screenModel.ScreenTypeName === ScreenTypes[ScreenTypes.EditScreen]) {
                            return false;
                        }
                    }

                    //forbid second linkList dropping
                    if (targetModel.Controls()) {
                        return !targetModel.Controls().some(control => control instanceof LinkList);
                    }
                }

                //forbid second recipeEditor dropping
                if (elModel instanceof RecipeEditor) {
                    return this.IsEnableDragRecipeEditor(elModel);
                }

                //prevent dragging non timer controls on kanban
                if (!(elModel instanceof Timer) && targetModel instanceof KanbanBoard) {
                    return false;
                }

                //check if it is possible to drag timer control
                if (elModel instanceof Timer && !this.IsEnableDragTimer(targetModel)) {
                    return false;
                }

                if (targetModel instanceof SubForm) {
                    const form = (targetModel as SubForm);

                    if (form.ConfiguringLayout) {
                        return false;
                    }
                }

                //prevent move controls or fields into tab header
                if (targetModel instanceof Tab && !(elModel instanceof TabPage)) {
                    return false;
                }

                //prevent move any fields into TemplateButton container
                if (targetModel instanceof Grid && (elModel instanceof AttachedFieldModel || elModel instanceof FieldModel)) {
                    if (target.hasAttribute('subControls')) {
                        return false;
                    }
                }

                //prevent move not allowed controls into Grid
                if (targetModel instanceof Grid && elModel instanceof BaseControl) {
                    const allowedSubControlTypes = [ButtonTemplate, Basket, GenericButton, SPIMCopy, ButtonCopy, ButtonPlan];
                    const controlTypeAllowed = !!allowedSubControlTypes.find(ct => elModel instanceof ct);

                    if (!controlTypeAllowed) {
                        return false;
                    }

                    if (elModel instanceof ButtonCopy) {
                        const allowedScreens = [ScreenTypes[ScreenTypes.ConsultScreen], ScreenTypes[ScreenTypes.EditScreen], ScreenTypes[ScreenTypes.ListScreen]];
                        return !!allowedScreens.find(screenType => this.GetType() === screenType);
                    }
                }

                if (!(targetModel instanceof Grid) && elModel instanceof SPIMCopy) {
                    return false;
                }

                //prevent move field into Grid more than once
                if (targetModel instanceof Grid && elModel instanceof FieldModel) {
                    const fieldAlreadyDropped = _.any(targetModel.Fields, field => field.Id === elModel.FieldId);

                    if (fieldAlreadyDropped) {
                        return false;
                    }
                }

                //prevent drop Basket to any places except of Grid
                if (!(targetModel instanceof Grid) && elModel instanceof Basket) {
                    return false;
                }

                //disable move TabPage control from Tab to Form
                if (elModel instanceof TabPage && !(targetModel instanceof Tab)) {
                    return false;
                }

                //prevent moving fields from linkList into subForm
                if (elModel instanceof AttachedFieldModel && sourceModel instanceof LinkList && !(targetModel instanceof LinkList)) {
                    return false;
                }

                //prevent moving fields from Grid into subForm
                if (elModel instanceof AttachedFieldModel && sourceModel instanceof Grid && !(targetModel instanceof Grid)) {
                    return false;
                }

                //forbidden drop duplicate Field into EditScreen
                if ((this.GetType() === ScreenTypes[ScreenTypes.ListScreen] || this.GetType() === ScreenTypes[ScreenTypes.EditScreen]) && !(targetModel instanceof Grid)
                    && !(targetModel instanceof LinkList && sourceModel instanceof EntityModel))
                {
                    if (elModel instanceof FieldModel) {
                        var field = <FieldModel>elModel;
                        const controls = this.GetAllControls();
                        let result = true;

                        _.each(controls, (control) => {

                            if(control.GetType() === CONTROL_TYPES.Maps){
                                return;
                            }

                            const baseControl = control as BaseControl;
                            if (control.GetModel().Fields.length === 1) {
                                if (baseControl.GetFieldId() === field.FieldId) {
                                    $(control.GetWrapper()).addClass('has-error');
                                    result = false;
                                }
                            }
                        });

                        if (!result) {
                            return false;
                        }
                    }
                }

                //prevent drop Tab into himself or nested TabPage or nested grid
                if (elModel instanceof Tab && (targetModel instanceof TabPage || targetModel instanceof Grid)) {
                    return false;
                }

                if (targetModel instanceof EntityModel) {
                    return false;
                }

                //prevent drop control into himself
                if (ko.dataFor(el) === ko.dataFor(target)) {
                    return false;
                }

                if (elModel instanceof FieldModel && sourceModel instanceof EntityModel && this.GetType() === ScreenTypes[ScreenTypes.EditScreen] && !(targetModel instanceof Grid)) {
                    return this.IsEnableDragField(elModel, sourceModel, targetModel);
                }

                if (elModel instanceof Search) {
                    return this.IsEnableDragSearchTerm(elModel);
                }

                if (elModel instanceof ButtonFavorite) {
                    return this.IsEnableButtonFavorite(elModel);
                }

                if (elModel instanceof FieldModel && sourceModel instanceof EntityModel && this.GetType() === ScreenTypes[ScreenTypes.QueryScreen] && targetModel instanceof Grid) {
                    return false;
                }

                if (this.GetType() === ScreenTypes[ScreenTypes.StepsScreen]) {
                    //disable move StepPage control from Step to Form
                    if (elModel instanceof StepPage && !(targetModel instanceof Step)) {
                        return false;
                    }
                    
                    if (sourceModel instanceof ControlGroup) {
                        if (elModel instanceof Step) {
                            //forbid second Step dropping
                            return this.HasOnlyOneInstanceOf(this.GetAllControls(), Step);
                        }
                    }
                    
                }

                return true;
            },
            copy: (el, source) => {
                const sourceModel = ko.dataFor(source);
                if (sourceModel instanceof ControlGroup || sourceModel instanceof EntityModel) {
                    return true;
                }
                return false;
            },
            moves: (el, source, handle, sibling) => {
                //prevent moving system fields from toolbar
                if (this.GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
                    if (ko.dataFor(el) instanceof FieldModel && ko.dataFor(source) instanceof EntityModel
                        && (ko.dataFor(el) as FieldModel).IsSystem && (ko.dataFor(el) as FieldModel).FieldTypeName !== FIELD_TYPES.Reference) {
                        return false;
                    }
                }

                //prevent moving fields from toolbar
                //if (ko.dataFor(el) instanceof FieldModel && ko.dataFor(source) instanceof EntityModel) {
                //return this.IsEnableDragField(ko.dataFor(el), ko.dataFor(source));
                //};

                //prevent moving property field
                if (ko.dataFor(el) instanceof AttachedFieldModel) {
                    const fieldModel = ko.dataFor(el) as AttachedFieldModel;

                    //prevent moving required entity for LinkList control
                    if (ko.dataFor(source) instanceof LinkList) {
                        if (fieldModel.EntityName === LINK_LIST_REQUIRED_ENTITY) {
                            return false;
                        }
                    }

                    if (fieldModel.FieldTypeName === FIELD_TYPES.Property) {
                        return false;
                    }
                }

                //prevent moving static control
                if (ko.dataFor(el) instanceof BaseControl) {
                    const control = <BaseControl>ko.dataFor(el);
                    if (control.IsStatic) {
                        return false;
                    }
                }

                return true;
            }
        });

        toolBarToDesignerDrake.drake.on('drag', (el) => {
            _.each(toolBarToDesignerDrake.drake.containers, (container) => {
                let isShowDropContainer = true;
                const context = ko.dataFor(container);

                const elementContext = ko.dataFor(el);

                if (context instanceof EntityModel) {
                    isShowDropContainer = false;
                }

                if (context instanceof ControlGroup) {
                    isShowDropContainer = false;
                }

                if (elementContext instanceof LinkList) {
                    let allowedLinkListSubFormIndex = 3;
                    if (this._subForms.indexOf(context) === allowedLinkListSubFormIndex) {
                        if (context.Controls() && !context.Controls().some(control => control instanceof LinkList)) {
                            $(container).addClass('start-drag');
                        }
                    }
                } else {
                    if (isShowDropContainer) {
                        $(container).addClass('start-drag');
                    }
                }
            });
        });

        toolBarToDesignerDrake.drake.on('dragend', () => {
            _.each(toolBarToDesignerDrake.drake.containers, (container) => {
                $(container).removeClass('start-drag');
                $(container).find('.has-error').removeClass('has-error');
            });
        });

        toolBarToDesignerDrake.drake.on('over', (el, container, source) => {
            const context = ko.dataFor(container);
            if (context instanceof SubForm && !context.IsExpanded) {
                context.Expand();
            }
        });
    }
    
    private HasOnlyOneInstanceOf<T>(array: any[], instanceType: new (...args: any[]) => T): boolean {
        const filteredArray = _.filter(array, (item) => item instanceof instanceType);

        // We check whether there is only one such element
        return filteredArray.length === 0;
    }

    private BindSubFormEvents(subForm: SubForm) {
        subForm.On('AFTER_DROP_CONTROL', this, eventArgs => {
            this.AfterDropControl(
                eventArgs.data.item,
                eventArgs.data.sourceIndex,
                eventArgs.data.sourceItems,
                eventArgs.data.sourceContext,
                eventArgs.data.targetIndex,
                eventArgs.data.targetItems,
                eventArgs.data.targetContext
            );
        });

        subForm.On('CONTROL_EDITOR_REQUESTED', this, eventArgs => {
            this.ShowControlEditor(eventArgs.data.control);
        });

		subForm.On('REMOVE_SUB_FORM', this, eventArgs => {
			if (this.IsDashboard) {
				this.ShowRemoveSubFormConfirmationDialog(eventArgs.data.subForm);
			} else {
				this.RemoveSubForm(eventArgs.data.subForm);
			}
		});
    }


    get DesignModel(): DesignScreenModel {
        return this._designModel;
    }

    get FormProperties() {
        return this._formProperties;
    }

    GetTemplateName(): string {
        if (this.IsDashboard) {
            return 'Core/Screens/Templates/DesignScreen/DashboardDesignScreen';
        }

        if (this.IsProcessCardScreen) {
            return 'Core/Screens/Templates/DesignScreen/ProcessCardDesignScreen';
        }

        return 'Core/Screens/Templates/DesignScreen/BaseDesignScreen';
    }

    AfterRender(el: Array<HTMLElement>) {
        this._el = el[3];

        if (this.IsDashboard) {
            setTimeout(() => {
                this._gridStack = GridStack.addGrid(
                    document.querySelector('#dashboard-design-container'),
                    {
                        ...defaultGridStackOptions,
                        children: this.SubForms.map(subForm => ({
                            ...subForm.GetModel().Properties.DashboardBlock,
                            ...getSubFormGridOptions(subForm)
                        }))
                    }
                );

                this._gridStack.on('added removed change', () => {
                    this._hasChanges(true);
                });

                this.SubForms.forEach(subForm => {
                    subForm.RenderDynamically(subForm.GetFormGuid(), this);
                });
            });
        }

        let width = this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_WIDTH)?.Value;
        let bgColor = this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.SCREEN_BACKGROUND_COLOR)?.Value;

        this._screenWidth({width: `${width?.Value}${width?.Unit?.Value}`});
        this._screenBgColor({backgroundColor: bgColor});

        let color = this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_COLOR)?.Value
        this._fontColor({color: color});

        this._fontFamily(this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_FAMILY)?.Value.Value);
        this._fontWeight(this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_WEIGHT)?.Value.Value);
        this._fontStyle(this.FindElementByType(this._formProperties.Options, CARD_SCREEN_PROPERTY_TYPE.FONT_STYLE)?.Value.Value);

        this._customStyle(`${this._fontFamily()} ${this._fontWeight()} ${this._fontStyle()}`);

        this._hasCustomColor(!!color);
        this._hasCustomWeight(!!this._fontWeight());
    }

    FindElementByType(array: ISettingsModal[], type: string): ISettingsModal | undefined {
        return _.find(array, { Type: type });
    }

    AddSubForm() {
        const subFormModel = new SubFormModel();
        subFormModel.Name = `${this._model.Name} Block`;

        const subForm = new SubForm({Screen: this, Model: subFormModel, RenderMode: this._renderMode});
        subForm.StartLayoutConfiguration();

        this._subForms.push(subForm);
        this.BindSubFormEvents(subForm);

        this._gridStack.addWidget(getSubFormGridOptions(subForm));
        subForm.RenderDynamically(subForm.GetFormGuid(), this);
    }

    GetScreenIcon(): Icon {
        return new Icon({
            IsImage: Boolean(this._model.EntityIcon.Image),
            IsIcon: !Boolean(this._model.EntityIcon.Image),
            Image: this._model.EntityIcon.Image,
            FontName: this._model.EntityIcon.FontName,
            Name: this._model.EntityIcon.Name
        } as IconModel);
    }

    IsEnableButtonFavorite(elModel): boolean {
        let controls = this.GetAllControls();
        let button = _.find(controls, control => control instanceof ButtonFavorite);

        return !!button ? elModel.GetRenderMode() !== RenderModes.ToolBar : true;
    }

    IsEnableDragSearchTerm(elModel): boolean {
        let controls = this.GetAllControls();
        let seachTermControl = _.find(controls, control => control instanceof Search);

        return !!seachTermControl ? elModel.GetRenderMode() !== RenderModes.ToolBar : true;
    }

    IsEnableDragRecipeEditor(elModel): boolean {
        let controls = this.GetAllControls();
        let recipeEditorControl = _.find(controls, control => control instanceof RecipeEditor);
        let isEnabled = true;

        if (!!recipeEditorControl) {
            if (elModel.GetGuid() !== recipeEditorControl.GetGuid()) {
                isEnabled = false;
            }
        }

        return isEnabled;
    }

    IsEnableDragTimer(targetModel): boolean {
        const isDragOnKanban = targetModel instanceof KanbanBoard;
        if ((this.GetType() === ScreenTypes[ScreenTypes.SpecialScreen] || this.GetType() === ScreenTypes[ScreenTypes.Dashboard]) && !isDragOnKanban)
        {
            return false;
        }

        const isAlreadyAddedInKanban = isDragOnKanban
            && _.any(targetModel.GetSubControls(), control => control instanceof Timer);

        return !isAlreadyAddedInKanban;
    }

    IsEnableDragField(field: FieldModel, entity: EntityModel, targetModel): boolean {
        if (this.GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
            if (targetModel instanceof LinkList)
            {
                return field.FieldTypeName === FIELD_TYPES.Text && (entity.EntityId === this.GetEntityId() ||
                    (_.contains(entity.RelationTypes, RelationTypes.OneToMany) && _.contains(entity.RelationTypes, RelationTypes.OneToMain)));
            }

            if (field.IsPrimaryKey) {
                return false;
            }

            if (field.FieldTypeName === FIELD_TYPES.Document) {
                return true;
            }

            if (entity.EntityId === this.GetEntityId()) {
                return true;
            }

            return false;
        }
        return true;
    }

    RemoveField(fieldObj, controlObj) {
        if (fieldObj instanceof AttachedFieldModel && controlObj instanceof BaseControl) {
            const field = fieldObj as AttachedFieldModel;
            const control = controlObj as BaseControl;
            const model = control.GetModel();
            model.Fields.splice(control.GetModel().Fields.indexOf(field), 1);
            control.ModelHasMutated();
            this._hasChanges(true);
        }
    }

    RemoveControl(parent, control: BaseControl) {
        if (parent instanceof BaseForm) {
            const subForm = parent as SubForm;
            subForm.RemoveControl(subForm, control);
        } else if (parent instanceof BaseControl) {
            const parentControl = parent as BaseControl;
            parentControl.RemoveControl(control);
        } else {
            this.OnControlRemoved(control);
        }
    }

    OnControlRemoved(control: BaseControl) {
        if (!control.IsNew) {
            this._updateModel.DeletedControlIds.push(control.GetControlId());
        }

        this._hasChanges(true);
    }

    AddTranslationsToControl(control: IControl) {
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);

        if (control.GetModel().NameTranslations.length === 0 && this._designModel.Languages.length > 0) {
            _.each(this._designModel.Languages, language => {
                let translation = new TranslationModel();
                translation.Language = language;
                translation.Selected = desktopLanguage === language.ShortName;
                translation.Translation = null;

                control.GetModel().NameTranslations.push(translation);
            });
        }

        if (control.GetModel().DescriptionTranslations.length === 0 && this._designModel.Languages.length > 0) {
            _.each(this._designModel.Languages, language => {
                let translationDescr = new TranslationModel();
                translationDescr.Language = language;
                translationDescr.Selected = desktopLanguage === language.ShortName;
                translationDescr.Translation = null;

                control.GetModel().DescriptionTranslations.push(translationDescr);
            });
        }
    }

    RemoveSubForm(subForm: SubForm) {
        const subFormIndex = this._subForms.indexOf(subForm);

        if (subFormIndex > -1) {
            this._subForms.splice(subFormIndex, 1);
        }

        if (this.IsDashboard) {
            this._gridStack.removeWidget(this._el.querySelector(`[gs-id="${subForm.GetFormGuid()}"]`));
        }

        if (!subForm.IsNew) {
            this._updateModel.DeletedFormIds.push(subForm.GetFormId());
        }

        this._hasChanges(true);
    }

	private ShowRemoveSubFormConfirmationDialog(subForm: SubForm) {
	    const confirmationDialog = new ConfirmationDialog({
		    Text: CONFIRMATIONS.SUBFORM_AND_ITS_CONTROLS_WILL_BE_DELETED,
		    Type: ConfirmationTypes.Question
	    });

	    confirmationDialog.On(CONFIRMATION_DIALOG_EVENTS.CONFIRM_SELECTED, this, () => {
			this.RemoveSubForm(subForm);
	    });
	    confirmationDialog.Show();
    }

    ShowControlEditor(control: IControl) {
        this.AddTranslationsToControl(control);
        const controlEditor = ControlEditorFactory.GetEditor(control);

        controlEditor.On("CONTROL_SAVED", this, (eventArgs: any) => {
            const updateModel = eventArgs.data.UpdateControlModel as ControlEditorModel;
            const controlModel = control.GetModel();
            controlModel.LabelPositionValue = updateModel.LabelPos;
            controlModel.LabelPosition = updateModel.LabelPosName;
            controlModel.EntityId = updateModel.EntityId;
            controlModel.Id = updateModel.K_Control;
            controlModel.RecordLifeStatusId = updateModel.RecordLifeStatusId;
            controlModel.DestinationTypeId = updateModel.DestinationTypeId;
            controlModel.Description = updateModel.Description;
            controlModel.Properties = JSON.parse(updateModel.Properties);
            controlModel.NameTranslations = updateModel.NameTranslations;
            controlModel.DescriptionTranslations = updateModel.DescriptionTranslations;
            controlModel.Icon = updateModel.Icon;
            controlModel.UseFieldName = updateModel.UseFieldName;
            controlModel.Name = updateModel.Name;
            controlModel.TitleFieldId = updateModel.TitleFieldId;
            controlModel.KanbanSubjectId = updateModel.KanbanSubjectId,
            controlModel.TodoEntityId = updateModel.TodoEntityId;
            controlModel.TodoTypeId = updateModel.TodoTypeId;
            controlModel.TodoFieldId = updateModel.TodoFieldId;
            controlModel.ScreenId = updateModel.ScreenId;

            if (!controlModel.UseFieldName) {
                const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);
                _.each(updateModel.NameTranslations, (translation) => {
                    if (desktopLanguage == translation.Language.ShortName) {
                        if (translation.Translation) {
                            controlModel.Label = translation.Translation;
                        } else {
                            controlModel.Label = updateModel.Name;
                        }
                    }
                });
            } else {
                controlModel.Label = updateModel.Name;
                controlModel.Name = updateModel.Name
            }

            const attachedField = new AttachedFieldModel();
            attachedField.Id = updateModel.FieldId;
            attachedField.ValFieldId = updateModel.ValFieldId;
            attachedField.ValTableId = updateModel.ValTableId;
            attachedField.EntityId = updateModel.EntityId;
            attachedField.FieldTypeName = updateModel.TypeName;
            attachedField.EntityId = updateModel.EntityId;
            attachedField.EntityName = updateModel.EntityName;
            attachedField.Name = updateModel.FieldName;
            attachedField.FieldNameTranslation = updateModel.FieldNameTranslation;

            attachedField.RelatedField = this.CheckEntityTypeByName(updateModel.EntityName);

            if (!(control instanceof Grid) && !(control instanceof ComplexControl) && attachedField.Id !== 0 && attachedField.Id !== undefined && attachedField.Id !== null) {
                controlModel.Fields = [attachedField];
            }

            if ((control instanceof ComplexControl || control instanceof PlannerLevel)) {
                if (controlModel.TypeName === CONTROL_TYPES.TimeWriting) {
                    if (controlModel.Fields.length > 0) {
                        if (controlModel.Fields[0].EntityId === updateModel.AttachedFields[0].EntityId) {
                            controlModel.Fields = updateModel.AttachedFields.concat(controlModel.Fields.filter(field => !updateModel.AttachedFields.find(item => item.Id === field.Id)))
                        } else {
                            controlModel.Fields = updateModel.AttachedFields;
                        }
                    } else {
                        controlModel.Fields = updateModel.AttachedFields;
                    }
                } else {
                    controlModel.Fields = updateModel.AttachedFields;
                }

                controlModel.SubTableId = updateModel.SubTableId;
            }

            this._hasChanges(true);
            control.ModelHasMutated();

            if (control instanceof TabPage){
                control.IsTabSecurityProperties();
            }

            control.UpdateLabel();
        });

        controlEditor.Show();
    }

    CreateTab(control: IControl) {
        if (control instanceof Tab) {
            const controlModel = new ControlModel();
            controlModel.Name = TabPage.GetDefaultName();
            controlModel.TypeName = CONTROL_TYPES.TabPage;
            const controlParam: IControlParam = {
                Model: controlModel,
                Form: control.GetForm(),
                ParentControl: control,
                RenderMode: RenderModes.Design,
                CreateControl: control.CreateControl
            };
            const tabPage = new TabPage(controlParam);
            control.AddSubControl(tabPage);
            this._hasChanges(true);
        }
    }

    CreateStepPage(control: IControl) {
        if (control instanceof Step) {
            const controlModel = new ControlModel();
            controlModel.Name = StepPage.GetDefaultName();
            controlModel.TypeName = CONTROL_TYPES.StepPage;
            const controlParam: IControlParam = {
                Model: controlModel,
                Form: control.GetForm(),
                ParentControl: control,
                RenderMode: RenderModes.Design,
                CreateControl: control.CreateControl
            };
            const tabPage = new StepPage(controlParam);
            control.AddSubControl(tabPage);
            this._hasChanges(true);
        }
    }

    CreatePlannerPage(control: IControl) {
        if (control instanceof Planner) {
            let controlModel = new ControlModel();
            controlModel.Name = PlannerPage.GetDefaultName();
            controlModel.TypeName = CONTROL_TYPES.PlannerPage;
            let controlParam: IControlParam = {
                Model: controlModel,
                Form: control.GetForm(),
                ParentControl: control,
                RenderMode: RenderModes.Design,
                CreateControl: control.CreateControl
            };
            let plannerPage = new PlannerPage(controlParam);
            control.AddSubControl(plannerPage);
            this._hasChanges(true);
        }
    }

    CreateRootLevel(control: IControl) {
        if (control instanceof PlannerPage) {
            let controlModel = new ControlModel();
            controlModel.Name = PlannerLevel.GetDefaultName();
            controlModel.TypeName = CONTROL_TYPES.PlannerLevel;
            let controlParam: IControlParam = {
                Model: controlModel,
                Form: control.GetForm(),
                ParentControl: control,
                RenderMode: RenderModes.Design,
                CreateControl: control.CreateControl
            };
            let plannerLevel = new PlannerLevel(controlParam);
            control.AddSubControl(plannerLevel);
            this._hasChanges(true);
        }
    }

    CreatePlannerLevel(control: IControl) {
        if (control instanceof PlannerLevel) {
            let controlModel = new ControlModel();
            controlModel.Name = PlannerLevel.GetDefaultName();
            controlModel.TypeName = CONTROL_TYPES.PlannerLevel;
            let controlParam: IControlParam = {
                Model: controlModel,
                Form: control.GetForm(),
                ParentControl: control,
                RenderMode: RenderModes.Design,
                CreateControl: control.CreateControl
            };
            let plannerLevel = new PlannerLevel(controlParam);
            control.AddSubControl(plannerLevel);
            this._hasChanges(true);
        }
    }

    private InitTranslations(translationModel: TranslationModel[]): TranslationModel[] {
        const languages = TranslationManager.Instance.Languages;
        let translations: TranslationModel[] = [];
        const desktopLanguage = GlobalManager.Instance.GetGlobal(GLOBALS.DESKTOP_LANGUAGE);

        _.forEach(languages, (item) => {
            const defaultTranslation = new TranslationModel();
            const defaultLanguage = new LanguageModel();
            if (item.Id) {
                defaultLanguage.K_Language = item.Id;
                defaultLanguage.FlagBase64 = item.Flag;
                defaultLanguage.ShortName = item.ShortName;
                defaultLanguage.Name = item.Name;
                defaultTranslation.Language = defaultLanguage;
                const selectedTranslation = _.find(translationModel, translation => translation.Language.K_Language === item.Id);
                defaultTranslation.Translation = selectedTranslation == null || selectedTranslation.Translation == null
                    ? "" : this.CapitalizeFirstLetter(selectedTranslation.Translation);
                defaultTranslation.Selected = desktopLanguage === defaultLanguage.ShortName;
                translations.push(defaultTranslation);
            }
        });
        return translations;
    }

    InitializeTabs(control: IControl) {
        if (control instanceof Tab) {

            _.each(this.SubjectEntity.RelatedSubEntitiesModel,
                relatedSubEntities => {
                    _.each(relatedSubEntities.Entities,
                        subEntity => {
                            const controlModel = new ControlModel();
                            controlModel.Name = this.CapitalizeFirstLetter(subEntity.EntityName);
                            controlModel.NameTranslations = this.InitTranslations(subEntity.Translations);
                            controlModel.TypeName = CONTROL_TYPES.TabPage;
                            const controlParam: IControlParam = {
                                Model: controlModel,
                                Form: control.GetForm(),
                                ParentControl: control,
                                RenderMode: RenderModes.Design
                            };
                            const tabPage = new TabPage(controlParam);
                            const gridControl = this.GenerateGridControl(subEntity, control.GetForm(), tabPage);
                            tabPage.AddSubControl(gridControl);
                            control.AddSubControl(tabPage);
                        });
                });

            _.each(this.SubjectEntity.RelatedEntities.Entities,
                entity => {
                    const controlModel = new ControlModel();
                    controlModel.Name = this.CapitalizeFirstLetter(entity.EntityName);
                    controlModel.NameTranslations = this.InitTranslations(entity.Translations);
                    controlModel.TypeName = CONTROL_TYPES.TabPage;
                    const controlParam: IControlParam = {
                        Model: controlModel,
                        Form: control.GetForm(),
                        ParentControl: control,
                        RenderMode: RenderModes.Design
                    };
                    const tabPage = new TabPage(controlParam);
                    const gridControl = this.GenerateGridControl(entity, control.GetForm(), tabPage);
                    tabPage.AddSubControl(gridControl);
                    control.AddSubControl(tabPage);
                });
            this._hasChanges(true);
        }
    }

    private CheckEntityTypeByName(entityName: string): boolean {
        if (!entityName) return false;
        if (entityName.indexOf('SYS_') !== -1 || entityName.indexOf('CD_') !== -1) {
            return entityName.split('').filter(item => item === '_').length > 1;
        } else {
            return entityName.indexOf('_') !== -1;
        }
    }

    AfterDropFieldIntoGrid(context, item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (targetContext instanceof Grid && item instanceof FieldModel) {
            const field = item as FieldModel;
            const entity = sourceContext as EntityModel;
            const attachedFieldModel = new AttachedFieldModel();
            attachedFieldModel.Id = field.FieldId;
            attachedFieldModel.Name = field.FieldName;
            attachedFieldModel.FieldNameTranslation = field.FieldNameTranslation;
            let entityName = entity.EntityName;
            let entityNameTranslation = entity.EntityNameTranslation;
            if (entity.EntityFullName) {
                entityName = entity.EntityFullName;
                entityNameTranslation = entity.EntityFullNameTranslation;
            }
            attachedFieldModel.EntityName = entityName;
            attachedFieldModel.EntityNameTranslation = entityNameTranslation;
            attachedFieldModel.EntityTypeName = entity.TypeName;
            attachedFieldModel.EntityId = entity.EntityId;
            attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.EntityName);
            attachedFieldModel.SubTableField = entity.TypeName === "Sub";
            const alreadyAddedField = _.find(targetItems, (item: any) => {
                return item.Id === attachedFieldModel.Id
            });
            if (alreadyAddedField) {
                const notifier = new Notifier();
                notifier.Warning(NOTIFICATIONS.FIELD_ALREADY_ADDED);
                return;
            }
            targetItems.splice(targetIndex, 0, attachedFieldModel);
        }

        if (targetContext instanceof Grid && item instanceof AttachedFieldModel) {
            targetItems.splice(targetIndex, 0, sourceItems.splice(sourceIndex, 1)[0]);
        }
        this._hasChanges(true);
    }

    DropControlFromToolBarProcess(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (item instanceof BaseControl) {
            let parentControl = null;
            let subForm = null;
            if (targetContext instanceof BaseForm) {
                subForm = targetContext;
            }

            if (targetContext instanceof BaseControl) {
                parentControl = targetContext as BaseControl;
                subForm = parentControl.GetForm();
            }

            if (!subForm) {
                throw new Error("SubForm is not defined");
            }
            if (item instanceof BaseControl) {
                item.SetForm(subForm);
                const newControl = item.ToDesignedControl() as BaseControl;
                newControl.Model.Id = item.Model.Id;
                newControl.Model.LabelPosition = item.Model.LabelPosition || DEFAULT_LABEL_POSITION;
                newControl.Model.Name = item.Model.Name || item.GetDefaultName();
                newControl.Model.FieldNameTranslation = item.Model.FieldNameTranslation;

                newControl.SetForm(subForm);
                newControl.SetParentControl(parentControl);
                item.SetForm(null);

                if (item instanceof LinkList) {
                    let allowedFormIndex = 3;
                    if (this._subForms.indexOf(targetContext) !== allowedFormIndex) {
                        return;
                    }

                    const usersEntity = _.find(this.SubjectEntity.RelatedEntities.Entities, (entity) => {
                        return entity.EntityName === LINK_LIST_REQUIRED_ENTITY
                    });
                    if (usersEntity) {

                        const usersEntityIconModel = new IconModel();
                        usersEntityIconModel.FontName = usersEntity.FontName;
                        usersEntityIconModel.Image = usersEntity.GraphicalIcon;
                        usersEntityIconModel.Name = usersEntity.IconName;
                        usersEntityIconModel.IsImage = Boolean(usersEntityIconModel.Image);
                        usersEntityIconModel.IsIcon = !usersEntityIconModel.IsImage;

                        const usersModel = new AttachedFieldModel();
                        usersModel.EntityId = usersEntity.EntityId;
                        usersModel.EntityName = usersEntity.EntityName;
                        usersModel.EntityNameTranslation = usersEntity.EntityNameTranslation;
                        usersModel.EntityIcon = usersEntityIconModel;

                        const nameField = _.find(usersEntity.Fields, (field) => {
                            return field.FieldName && field.FieldName.toUpperCase() === 'NAME'
                        });
                        if (nameField) {
                            usersModel.Id = nameField.FieldId;
                            usersModel.Name = nameField.FieldName;
                            usersModel.FieldNameTranslation = nameField.FieldNameTranslation;
                        }
                        newControl.Model.Fields.push(usersModel);
                    }
                }

                targetItems.splice(targetIndex, 0, newControl);
                if (newControl.GetRenderMode() === RenderModes.ToolBar) {
                    newControl.SetRenderMode(RenderModes.Design);
                }

                if (parentControl && parentControl instanceof TabPage && parentControl.GetSubControls().length == 1) {
                    parentControl.Name = this.CapitalizeFirstLetter(newControl.Name);
                    parentControl.NameTranslations = this.InitTranslations(newControl.NameTranslations);
                    parentControl.ModelHasMutated();
                }
            }
        }
    }

    DropFieldFromToolBarProcess(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        let control = null;
        let subForm = null;
        let parentControl = null;
        if (targetContext instanceof SubForm || targetContext instanceof ActionBar || targetContext instanceof BottomBar) {
            subForm = targetContext;
        }

        if (targetContext instanceof BaseControl) {
            parentControl = targetContext as BaseControl;
            subForm = parentControl.GetForm();
        }

        if (item instanceof FieldModel) {
            const controlType = FIELD_TYPE_TO_CONTROL[item.FieldTypeName];
            let controlModel = new ControlModel();
            const defaultControlModel = this.GetDefaultControlModel(controlType);
            if (defaultControlModel) {
                controlModel = clone(defaultControlModel);
            }

            const entity = sourceContext as EntityModel;
            controlModel.TypeName = controlType;
            controlModel.Name = item.FieldName;
            const attachedFieldModel = new AttachedFieldModel();
            attachedFieldModel.Id = item.FieldId;
            attachedFieldModel.Name = item.FieldName;
            attachedFieldModel.FieldNameTranslation = item.FieldNameTranslation;

            attachedFieldModel.FieldIcon = new IconModel();
            attachedFieldModel.EntityId = entity.EntityId;
            attachedFieldModel.EntityTypeName = entity.TypeName;
            attachedFieldModel.EntityName = entity.EntityName;
            attachedFieldModel.EntityNameTranslation = entity.EntityNameTranslation;
            attachedFieldModel.FieldTypeName = item.FieldTypeName;
            attachedFieldModel.ValTableId = item.ValTableId;
            attachedFieldModel.ValFieldId = item.ValFieldId;
            attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.EntityName);
            attachedFieldModel.SubTableField = entity.TypeName === "Sub";
            if (item.FieldIcon) {
                attachedFieldModel.FieldIcon.Name = item.FieldIcon.Name;
                attachedFieldModel.FieldIcon.FontName = item.FieldIcon.IconFontName;
                attachedFieldModel.FieldIcon.Image = item.FieldIcon.Image;
                attachedFieldModel.FieldIcon.IsIcon = item.FieldIcon.IsIcon;
                attachedFieldModel.FieldIcon.IsImage = item.FieldIcon.IsImage;
            }

            if (controlModel.LabelPosition === undefined) {
                controlModel.LabelPosition = DEFAULT_LABEL_POSITION;
            }

            controlModel.EntityId = entity.EntityId;
            controlModel.EntityTypeName = entity.TypeName;
            controlModel.Fields = [attachedFieldModel];
            controlModel.LabelPositionValue = LabelPositions[controlModel.LabelPosition];
            controlModel.IsBlobFieldAttached = CONRTOL_WITH_BLOBFIELDATTACHED.indexOf(attachedFieldModel.FieldTypeName) > -1;
            controlModel.Label = item.FieldNameTranslation || item.FieldName;
            controlModel.IsFieldAttached = true;
            const controlParams: IControlParam = {
                Form: subForm,
                Model: controlModel,
                RenderMode: RenderModes.Design,
                ParentControl: parentControl
            };

            control = ControlFactory.CreateControl(controlParams) as BaseControl;
            targetItems.splice(targetIndex, 0, control);
            new Notifier().Success(NOTIFICATIONS.NEW_CONTROL_CREATED);

            if (parentControl && parentControl.GetSubControls().length == 1 && parentControl.GetType() !== CONTROL_TYPES.Group) {
                parentControl.Name = this.CapitalizeFirstLetter(control.Name);
                parentControl.NameTranslations = this.InitTranslations(item.Translations);
                parentControl.ModelHasMutated();
            }
        }
    }

    MovingControlProcess(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (item instanceof BaseControl) {
            const control = item as BaseControl;

            sourceItems.splice(sourceIndex, 1);
            targetItems.splice(targetIndex, 0, control);

            if (targetContext instanceof BaseForm) {
                control.SetForm(targetContext);
            }
            if (targetContext instanceof BaseControl) {
                const parentControl = targetContext as BaseControl;
                control.SetParentControl(targetContext);
                control.SetForm(parentControl.GetForm());
            } else {
                control.SetParentControl(null);
            }
        }
    }

    AfterDropEntityIntoLinkList(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (item instanceof AttachedFieldModel) {
            if (targetIndex === 0) {
                if (targetItems()[0].EntityName === LINK_LIST_REQUIRED_ENTITY) {
                    targetItems.splice(sourceIndex, 0, targetItems.splice(sourceIndex, 1)[0]);
                }
                return;
            }

            targetItems.splice(targetIndex, 0, targetItems.splice(sourceIndex, 1)[0]);
            this._hasChanges(true);
            return;
        }

        if (item instanceof FieldModel) {
            const entity = sourceContext as EntityModel;

            const alreadyAttachedField = _.find(targetItems(), (field: any) => {
                return field.EntityId === entity.EntityId
            });
            if (alreadyAttachedField) {
                const notifier = new Notifier();
                notifier.Warning(NOTIFICATIONS.FIELD_FROM_ENTITY_NAME_IS_ALREADY_PRESENT.replace('{entityName}', entity.EntityName));
                return;
            }

            this.AttachFieldToLinkList(item, entity, targetIndex, targetItems, targetContext);
            return;
        }

        if (parseInt(item) === RelationTypes.OneToMain) {
            const entity = sourceContext as EntityModel;
            const nameField = _.find(entity.Fields, (item) => item.FieldName === 'NAME');

            if (nameField) {
                const alreadyAttachedField = _.find(targetItems(), (field: any) => {
                    return field.EntityId === entity.EntityId
                });
                if (alreadyAttachedField) {
                    const notifier = new Notifier();
                    notifier.Warning(NOTIFICATIONS.ENTITY_ALREADY_ADDED);
                    return;
                }

                this.AttachFieldToLinkList(nameField, entity, targetIndex, targetItems, targetContext);
            }
        }
    }

    private AttachFieldToLinkList(field: FieldModel, entity: EntityModel, targetIndex, targetItems, targetContext) {
        const parentControl = targetContext as BaseControl;
        const attachedField = new AttachedFieldModel();

        attachedField.Id = field.FieldId;
        attachedField.Name = field.FieldName;
        attachedField.FieldNameTranslation = field.FieldNameTranslation;

        const icon = new IconModel();
        const isImage = entity.GraphicalIcon !== null;
        icon.Image = entity.GraphicalIcon;
        icon.FontName = entity.FontName;
        icon.Name = entity.IconName;
        icon.IsIcon = !isImage;
        icon.IsImage = isImage;

        attachedField.EntityIcon = icon;
        attachedField.EntityId = entity.EntityId;
        attachedField.EntityName = entity.EntityName;
        attachedField.EntityNameTranslation = entity.EntityNameTranslation;

        const primaryKeyField = _.find(entity.Fields, (item) => item.IsPrimaryKey);
        attachedField.PrimaryKeyName = primaryKeyField?.FieldName;

        if (targetIndex === 0 && targetItems()[0] && targetItems()[0].EntityName === LINK_LIST_REQUIRED_ENTITY) {
            targetItems.push(attachedField);
        } else {
            targetItems.splice(targetIndex, 0, attachedField);
        }

        parentControl.ModelHasMutated();
        this._hasChanges(true);
    }

    GenerateGridControl(entity: EntityModel, subForm: IForm, parentControl: IControl) {
        const controlModel = new ControlModel();
        controlModel.Id = 0;
        controlModel.TypeName = CONTROL_TYPES.Grid;
        controlModel.Name = entity.EntityName;
        controlModel.Label = entity.EntityNameTranslation;
        controlModel.EntityTypeName = entity.TypeName;
        controlModel.NameTranslations = entity.Translations;
        controlModel.IsFieldAttached = true;
        const topDefaultFields = ['F_TYPE', 'F_KIND', 'NAME'];
        _.each(topDefaultFields, (field) => {
            const defaultField = _.find(entity.Fields, (item) => {
                return item.FieldName === field
            });
            if (defaultField) {
                const attachedFieldModel = new AttachedFieldModel();
                attachedFieldModel.Id = defaultField.FieldId;
                attachedFieldModel.Name = defaultField.FieldName;
                attachedFieldModel.FieldNameTranslation = defaultField.FieldNameTranslation;
                attachedFieldModel.FieldTypeName = defaultField.FieldTypeName;
                attachedFieldModel.EntityName = entity.EntityFullName || entity.EntityName;
                attachedFieldModel.EntityNameTranslation = entity.EntityFullNameTranslation || entity.EntityNameTranslation;
                attachedFieldModel.EntityId = entity.EntityId;
                attachedFieldModel.EntityTypeName = entity.TypeName;
                attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.EntityName);
                attachedFieldModel.SubTableField = entity.TypeName === "Sub";
                controlModel.Fields.push(attachedFieldModel);
            }
        });

        const entityFields = _.chain(entity.Fields).filter(item => item.FieldTypeName !== FIELD_TYPES.Property)
            .sortBy('Sort').value();
        _.each(entityFields, (field) => {
            if (this.GetSortGroup(field.Sort) === SortGroups.Custom) {
                let existField = _.find(controlModel.Fields, (item) => {
                    return item.Id === field.FieldId
                });
                if (!existField) {
                    const attachedFieldModel = new AttachedFieldModel();
                    attachedFieldModel.Id = field.FieldId;
                    attachedFieldModel.Name = field.FieldName;
                    attachedFieldModel.IsVirtual = field.IsVirtual;
                    attachedFieldModel.IsSystem = field.IsSystem;
                    attachedFieldModel.FieldNameTranslation = field.FieldNameTranslation;
                    attachedFieldModel.EntityName = entity.EntityFullName || entity.EntityName;
                    attachedFieldModel.EntityNameTranslation = entity.EntityFullNameTranslation || entity.EntityNameTranslation;
                    attachedFieldModel.FieldTypeName = field.FieldTypeName;
                    attachedFieldModel.EntityId = entity.EntityId;
                    attachedFieldModel.EntityTypeName = entity.TypeName;
                    attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.EntityName);
                    attachedFieldModel.SubTableField = entity.TypeName === "Sub";
                    controlModel.Fields.push(attachedFieldModel);
                }
            }
        });


        if (entity.LinkEntity) {
            const linkEntityFields = _.chain(entity.LinkEntity.Fields).filter(item => item.FieldTypeName !== FIELD_TYPES.Property)
                .sortBy('Sort').value();
            _.each(linkEntityFields,
                (field) => {
                    if (this.GetSortGroup(field.Sort) === SortGroups.Custom) {
                        const attachedFieldModel = new AttachedFieldModel();
                        attachedFieldModel.Id = field.FieldId;
                        attachedFieldModel.Name = field.FieldName;
                        attachedFieldModel.FieldNameTranslation = field.FieldNameTranslation;
                        attachedFieldModel.FieldTypeName = field.FieldTypeName;
                        attachedFieldModel.EntityName = entity.LinkEntity.EntityFullName || entity.LinkEntity.EntityName;
                        attachedFieldModel.EntityNameTranslation = entity.LinkEntity.EntityFullNameTranslation || entity.LinkEntity.EntityNameTranslation;
                        attachedFieldModel.EntityId = entity.LinkEntity.EntityId;
                        attachedFieldModel.EntityTypeName = entity.LinkEntity.TypeName;
                        attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.LinkEntity.EntityName);
                        attachedFieldModel.SubTableField = entity.TypeName === "Sub";
                        controlModel.Fields.push(attachedFieldModel);
                    }
                });
        }

        const bottomDefaultFields = ['CREATEDATE', 'CREATEDBY'];
        _.each(bottomDefaultFields, (field) => {
            const defaultField = _.find(entity.Fields, (item) => {
                return item.FieldName === field
            });
            if (defaultField) {
                const attachedFieldModel = new AttachedFieldModel();
                attachedFieldModel.Id = defaultField.FieldId;
                attachedFieldModel.Name = defaultField.FieldName;
                attachedFieldModel.FieldNameTranslation = defaultField.FieldNameTranslation;
                attachedFieldModel.FieldTypeName = defaultField.FieldTypeName;
                attachedFieldModel.EntityName = entity.EntityFullName || entity.EntityName;
                attachedFieldModel.EntityNameTranslation = entity.EntityFullNameTranslation || entity.EntityNameTranslation;
                attachedFieldModel.EntityId = entity.EntityId;
                attachedFieldModel.EntityTypeName = entity.TypeName;
                attachedFieldModel.RelatedField = this.CheckEntityTypeByName(entity.EntityName);
                attachedFieldModel.SubTableField = entity.TypeName === "Sub";
                controlModel.Fields.push(attachedFieldModel);
            }
        });

        _.each(controlModel.Fields,
            (field, index) => {
                field.Sort = index * 10;
            });

        const controlParams: IControlParam = {
            Form: subForm,
            Model: controlModel,
            RenderMode: RenderModes.Design,
            ParentControl: parentControl
        };

        const control = ControlFactory.CreateControl(controlParams) as BaseControl;
        return control;
    }

    ConvertEntityToGrid(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {

        let subForm = null;
        let parentControl = null;
        if (targetContext instanceof BaseForm) {
            subForm = targetContext;
        }

        if (targetContext instanceof BaseControl) {
            parentControl = targetContext as BaseControl;
            subForm = parentControl.GetForm();
        }

        const entity = sourceContext as EntityModel;
        const gridControl = this.GenerateGridControl(entity, subForm, parentControl);
        targetItems.splice(targetIndex, 0, gridControl);

        if (parentControl && parentControl.GetSubControls().length == 1) {
            parentControl.Name = this.CapitalizeFirstLetter(entity.EntityName);
            parentControl.NameTranslations = this.InitTranslations(entity.Translations);
            parentControl.ModelHasMutated();
        }
    }

    AfterDropTapPageControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        if (item instanceof BaseControl) {
            const control = item as BaseControl;
            sourceItems.splice(sourceIndex, 1);
            targetItems.splice(targetIndex, 0, control);
        }
        this._hasChanges(true);
    }


    AfterDropControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        const isExistingControl = sourceContext instanceof BaseForm || sourceContext instanceof BaseControl;
        if (isExistingControl) {
            this.DropExistingControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
        }

        this.DropNewControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);

        this._hasChanges(true);
    }

    Save(): P.Promise<any> {
        const deferredResult = P.defer<any>();

        if (this.Validate()) {
            if (this.IsDashboard) {
                const gridStackSaveResult = this._gridStack.save(false);
                const gridStackWidgets = Array.isArray(gridStackSaveResult) ? gridStackSaveResult : [];

                this._subForms.forEach(subForm => {
                    subForm.GetModel().Properties.DashboardBlock = new DashboardBlock(
                        gridStackWidgets.find(widget => widget.id === subForm.GetFormGuid())
                    );

                    this._updateModel.SubForms.push(subForm.GetModel());
                })
            }

            const controls = _.filter(this.GetAllControls(), control => !control.IsStatic || control.GetModel().Predefined);

            this._updateModel.Controls = [];
            this._updateModel.ScreenId = this.GetId();

            _.each(controls, (item) => {
                const baseControl = item as BaseControl;
                const controlModel = new UpdateControlModel();

                controlModel.Id = baseControl.GetControlId();
                controlModel.Guid = baseControl.GetGuid();
                controlModel.Name = baseControl.GetModel().Name;
                controlModel.NameTranslations = baseControl.GetModel().NameTranslations;
                controlModel.DescriptionTranslations = baseControl.GetModel().DescriptionTranslations;
                controlModel.TypeName = baseControl.GetType();
                controlModel.RecordLifeStatusId = baseControl.GetModel().RecordLifeStatusId;
                controlModel.DestinationTypeId = baseControl.GetModel().DestinationTypeId;
                controlModel.FormId = baseControl.GetForm().GetFormId();
                controlModel.FormGuid = baseControl.GetForm().GetFormGuid();
                controlModel.LabelPosition = LabelPositions[baseControl.LabelPosition];
                controlModel.Properties = JSON.stringify(baseControl.Properties);
                if(!baseControl.Properties){
                    let getGeneralProperties = baseControl.GeneralProperties;
                    controlModel.Properties = getGeneralProperties ? getGeneralProperties.Serialize() : null;
                }
                controlModel.Description = baseControl.Description;
                controlModel.IconId = baseControl.Icon ? baseControl.Icon.Id : null;
                controlModel.UseFieldName = baseControl.GetModel().UseFieldName;
                controlModel.TitleFieldId = baseControl.GetModel().TitleFieldId;
                controlModel.KanbanSubjectId = baseControl.GetModel().KanbanSubjectId;
                controlModel.TodoEntityId = baseControl.GetModel().TodoEntityId;
                controlModel.TodoTypeId = baseControl.GetModel().TodoTypeId;
                controlModel.TodoFieldId = baseControl.GetModel().TodoFieldId;
                controlModel.ScreenId = baseControl.GetModel().ScreenId;

                _.each(baseControl.GetModel().Fields,
                    field => {
                        controlModel.AddField(field.Id, field.Sort, field.LinkRequiredObservable());
                    });

                const parentControl = baseControl.GetParentControl();
                if (parentControl) {
                    controlModel.ParentControlId = parentControl.GetControlId();
                    controlModel.ParentControlGuid = parentControl.GetGuid();
                }
                controlModel.Sort = baseControl.GetSort();
                this._updateModel.Controls.push(controlModel);
            });

            this._updateModel.TableFormLinks = _.map(this._designModel.ScreenTypes,
                (screenType) => new MainFormUpdateModel({
                    EntityId: this._designModel.ExistingScreenViewModel.EntityId,
                    IsMain: screenType.IsMain,
                    FormId: screenType.FormId
                }));

            this._updateModel.FormProperties = JSON.stringify(Serialize(this._formProperties));

            UpdateScreenStore.UpdateScreen(this._updateModel)
                .then(updateScreenModel => {
                    PubSub.publish('RELOAD_MENU_AREAS', {});
                    this.SetSubFormIds(updateScreenModel);
                    this.SetControlIds(updateScreenModel);

                    this._hasChanges(false);
                    deferredResult.resolve(null);
                    this.AfterSaveRemoveNotInitializedControlClass();
                })
                .fail(err => {
                    let resultMessage = `Error occured during the update: ${err.message}`;
                    deferredResult.reject({message: resultMessage});
                });
        } else {
            deferredResult.reject({message: 'Please, initialize controls'});
        }

        return deferredResult.promise();
    }

    SetSubFormIds(model: UpdateScreenModel) {
        _.each(this._subForms, subForm => {
            const updatedSubForm = _.find(model.SubForms, item => item.Guid === subForm.GetFormGuid());

            if (updatedSubForm) {
                subForm.GetModel().Id = updatedSubForm.Id;
            }
        });
    }

    SetControlIds(model: UpdateScreenModel) {
        const controls = _.filter(this.GetAllControls(), control => {
            return control.IsStatic === false
        });
        _.each(controls, control => {
            const updatedControlModel = _.find(model.Controls, item => {
                return item.Guid === control.GetGuid()
            });
            if (updatedControlModel) {
                control.GetModel().Id = updatedControlModel.Id;
            }
        });
    }

    Validate() {
        let isValid = true;
        _.each(this.GetAllControls(), (item) => {
            const controlModel = item.GetModel();
            if (item instanceof StepPage) {
                if (!item.GetModel().ScreenId) {
                    isValid = false;
                    this.NotInitializedControl(item);
                } else {
                    this.SetInitializedControl(item);
                    this.RemoveParentControlClass(item);
                }
            } else if (item instanceof PlannerLevel) {
                if (item.GetModel().Fields.length < 2) {
                    isValid = false;
                    this.NotInitializedControl(item);
                } else {
                    this.SetInitializedControl(item);
                    this.RemoveParentControlClass(item);
                }
            } else if (item instanceof KanbanBoard) {
                if (!(controlModel.TitleFieldId && controlModel.TodoEntityId && controlModel.TodoTypeId && controlModel.TodoFieldId)) {
                    isValid = false;
                    this.NotInitializedControl(item);
                } else {
                    this.SetInitializedControl(item);
                    this.RemoveParentControlClass(item);
                }
            } else if (item instanceof ComplexControl) {
                if (!item.IsDesignValid()) {
                    isValid = false;
                    this.NotInitializedControl(item);
                } else {
                    this.SetInitializedControl(item);
                    this.RemoveParentControlClass(item);
                }
            } else {
                if (controlModel.IsFieldAttached || controlModel.IsBlobFieldAttached || controlModel.IsLookupFieldAttached || controlModel.IsMultiSelectFieldAttached) {
                    if (controlModel.Fields.length === 0) {
                        isValid = false;
                        this.NotInitializedControl(item);
                    } else {
                        this.SetInitializedControl(item);
                        this.RemoveParentControlClass(item);
                    }
                }
            }
            if (!item.IsPropertiesValidToSave()) {
                isValid = false;
                this.NotInitializedControl(item);
            }
        });
        return isValid;
    }

    RemoveParentControlClass(item: IControl){
        const parentControl = item.GetParentControl();
        if(parentControl && (parentControl.GetType() === CONTROL_TYPES.TabPage || parentControl.GetType() === CONTROL_TYPES.StepPage)){
            let leastOneNotInitialized = _.filter(parentControl.GetSubControls(), (control)=> !control.GetModel().InitializedControl)
            if ($(parentControl.GetWrapper()).hasClass('notInitializedControl')){
                if (leastOneNotInitialized.length === 0){
                    $(parentControl.GetWrapper()).removeClass('notInitializedControl');
                }
            }

        }
    }

    AfterSaveRemoveNotInitializedControlClass(){
        $('.notInitializedControl').removeClass('notInitializedControl');
    }

    SetInitializedControl(item: IControl){
        item.GetModel().SetInitializedControl(true);
    }

    NotInitializedControl(item: IControl){
        const parentControl = item.GetParentControl();

        $(item.GetWrapper()).effect('bounce');
        item.GetModel().SetInitializedControl(false);

        if (item.GetType() === CONTROL_TYPES.StepPage){
            if (!$(item.GetWrapper()).hasClass('notInitializedControl')){
                $(item.GetWrapper()).addClass('notInitializedControl');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(item.GetWrapper()).offset().top
                }, 1000);
            }
        }else if (parentControl && item.GetParentControl().GetType() === CONTROL_TYPES.TabPage){
            if (!$(parentControl.GetWrapper()).hasClass('notInitializedControl')){
                $(parentControl.GetWrapper()).addClass('notInitializedControl');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(parentControl.GetWrapper()).offset().top
                }, 1000);
            } else {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $(parentControl.GetWrapper()).offset().top
                }, 1000);
            }
        }
    }

    get SubFormsWith() {
        return this._subFormsWith;
    }

    InitCalcSubFormSize() {
        const self = this;
        let style = {};
        const mqb = window.matchMedia("(max-width: 1600px)");
        const mqm = window.matchMedia("(max-width: 1270px)");
        const consultSubform = () => {
            if (self.GetType() === ScreenTypes[ScreenTypes.EditScreen]) {
                if (self._subForms.length === 4) {
                    if (mqb.matches) {
                        style = {width: '48%'}
                    }

                    if (mqm.matches) {
                        style = {width: '96%'}
                    }

                    if (!mqb.matches) {
                        style = {width: '30%'}
                    }
                }
            }
            if (self._subForms.length === 3) {
                if (mqb.matches) {
                    style = {width: '48%'}
                }

                if (mqm.matches) {
                    style = {width: '96%'}
                }

                if (!mqb.matches) {
                    style = {width: '32.3%'}
                }
            }
            self._subFormsWith(style);
        };

        window.onresize = () => {
            consultSubform();
        }

        consultSubform();
    }

    GetSortGroup(sort: number) {
        if (_.include(_.range(1000, 1999), sort)) {
            return SortGroups.Header;
        }
        if (_.include(_.range(2000, 2999), sort)) {
            return SortGroups.OptionalHeader;
        }
        if (_.include(_.range(3000, 7999), sort)) {
            return SortGroups.Custom;
        }
        if (_.include(_.range(8000, 8999), sort)) {
            return SortGroups.OptionalFooter;
        }
        if (_.include(_.range(9000, 9999), sort)) {
            return SortGroups.Footer;
        }
        if (_.include([0], sort)) {
            return SortGroups.Undefined;
        }

        return null;
    }

    private DropNewControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        //Check sequence
        const positionIsValid = this._afterDropValidation.IsPositionValid(item,
            sourceIndex,
            sourceItems,
            sourceContext,
            targetIndex,
            targetItems,
            targetContext);

        if (!positionIsValid) {
            return;
        }

        //Drop control from toolBar
        if (sourceContext instanceof ControlGroup) {
            this.DropControlFromToolBarProcess(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
        }

        if (sourceContext instanceof EntityModel) {
            const itemNumber = parseInt(item);

            const screenType = this.GetType();

            //Drop 1:N
            if (
                itemNumber === RelationTypes.OneToMany &&
                _.includes(
                    [
                        ScreenTypes[ScreenTypes.EditScreen],
                        ScreenTypes[ScreenTypes.ConsultScreen]
                    ],
                    screenType
                )
            ) {
                this.ConvertEntityToGrid(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
            }

            //Drop 1:1 Subject
            if (
                itemNumber === RelationTypes.OneToOneSubject &&
                _.includes(
                    [
                        ScreenTypes[ScreenTypes.ListScreen],
                        ScreenTypes[ScreenTypes.Portlet],
                        ScreenTypes[ScreenTypes.SpecialScreen],
                        ScreenTypes[ScreenTypes.Dashboard]
                    ],
                    screenType
                )
            ) {
                this.ConvertEntityToGrid(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
            }

            //Drop 1:1
            if (
                itemNumber === RelationTypes.OneToOne &&
                _.includes(
                    [
                        ScreenTypes[ScreenTypes.EditScreen],
                        ScreenTypes[ScreenTypes.ConsultScreen],
                        ScreenTypes[ScreenTypes.ListScreen]
                    ],
                    screenType
                )
            ) {
                this.ConvertEntityToGrid(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
            }

            //Drop field from ToolBar
            this.DropFieldFromToolBarProcess(
                item,
                sourceIndex,
                sourceItems,
                sourceContext,
                targetIndex,
                targetItems,
                targetContext
            );
        }
    }

    private DropExistingControl(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext) {
        const positionIsValid = this._afterDropValidation.IsPositionValid(item,
            sourceIndex,
            sourceItems,
            sourceContext,
            targetIndex,
            targetItems,
            targetContext);

        if (!positionIsValid) {
            const movingControl = targetItems.splice(sourceIndex, 1)[0];
            targetItems.splice(sourceIndex, 0, movingControl);
            return;
        }

        //Moving existing control
        this.MovingControlProcess(item, sourceIndex, sourceItems, sourceContext, targetIndex, targetItems, targetContext);
    }

    CapitalizeFirstLetter(stringValue: string) {
        return stringValue.charAt(0).toUpperCase() + stringValue.slice(1).toLowerCase();
    }
}