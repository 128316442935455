import {Request, IRequestOptions} from 'Core/Common/Request';
import {P} from 'Core/Common/Promise';
import { Deserialize, GenericDeserialize } from '../../../../libs/cerialize';

export interface CopyRecordsDto {
	SubjectEntityId: Number;
	SubjectRecordId: Number;

	EntityIdToCopy: Number;

	RecordIdsToCopy: Array<number>;

	CopyRelations: boolean,
	IsLinkToSource: boolean;
	CopyToTypeId: number;
	AliasSuffix: string;
}

export class ButtonCopyApi {

	static  CopyRecords(params: CopyRecordsDto): P.Promise<Array<number>>{
		let deferredResult = P.defer<Array<number>>();

		let requestParams: IRequestOptions = {
			proxy: {
				url: '/api/ScreenFormApi/CopyRecords',
				dataType: 'json',
				type: 'POST'
			}, params: params
		};

		Request.Send(requestParams)
			.then(data => {
				deferredResult.resolve(Deserialize(data, Array<number>));
			})
			.fail(error => deferredResult.reject(error));

		return deferredResult.promise();
	}
}