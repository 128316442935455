import enumerable from 'Core/Common/Decorators/EnumerableDecorator';

import { TranslationManager } from "Core/Components/Translation/TranslationManager";
import {Label} from "../../Controls/Label/Label";

var manager = TranslationManager.Instance;

export class NOTIFICATIONS {

	//#region Common
	@enumerable static get PERMISSION_IS_DENIED() { return manager.GetLocalizedString( 1270 ) }					//Permission is denied
	@enumerable static get RECORD_SAVED() { return manager.GetLocalizedString( 20 ) }							    //Record saved
	@enumerable static get RECORD_REMOVED() { return manager.GetLocalizedString( 21 ) }							//Record removed
	@enumerable static get NOT_ALLOWED_ACTION() { return manager.GetLocalizedString(708) }						//Not allowed action
    @enumerable static get RECORD_DISABLED() { return manager.GetLocalizedString(900) }							//Record disabled
    @enumerable static get RECORD_CREATED() { return manager.GetLocalizedString( 22 ) }							//Record has been created
	@enumerable static get RECORD_UPDATED() { return manager.GetLocalizedString( 23 ) }							//Record updated
	@enumerable static get RECORD_NOT_FOUND() { return manager.GetLocalizedString( 960 ) }						//Record not found
	@enumerable static get REFERENCE_TABLE_NOT_SET() { return manager.GetLocalizedString( 961 ) }					//Reference table not set
	@enumerable static get INVALID_REFERENCE_TABLE() { return manager.GetLocalizedString( 964 ) }					//Invalid reference table
	@enumerable static get REFERENCE_WAS_SET() { return manager.GetLocalizedString(989) }							//Reference was set
	@enumerable static get REFERENCE_WAS_UNSET() { return manager.GetLocalizedString(990) }						//Reference was unset
	@enumerable static get TABLE_NOT_FOUND() { return manager.GetLocalizedString( 962 ) }							//Table not found
	@enumerable static get ENTITY_NAME_WAS_NOT_FOUND() { return manager.GetLocalizedString( 843 ) }				//Entity {entityName} was not found
	@enumerable static get NO_PENDING_CHANGES() { return manager.GetLocalizedString( 37 ) }						//No pending changes
	@enumerable static get DATA_SAVED() { return manager.GetLocalizedString( 40 ) }								//Data saved
	@enumerable static get DATA_IS_NOT_SAVED() { return manager.GetLocalizedString( 41 ) }						//Error saving data
	@enumerable static get ERROR_DELETING_RECORD() { return manager.GetLocalizedString( 49 ) }					//Error deleting record
	@enumerable static get ERROR_GETTING_CONTROL_OPTIONS() { return manager.GetLocalizedString( 1242 ) }		//Error getting {controlName} control options
	@enumerable static get SOMETHING_WENT_WRONG() { return manager.GetLocalizedString( 61 ) }						//Something went wrong
	@enumerable static get SUB_TYPE_NOT_FOUND() { return manager.GetLocalizedString(82) }						//Sub types not found
	@enumerable static get TYPES_NOT_FOUND() { return manager.GetLocalizedString(1136) }						//Types not found
	@enumerable static get INCORECT_PASSWORD_LENGTH() { return manager.GetLocalizedString( 98 ) }					//Incorrect length of password. Please enter at least {MinimumLength} symbols
	@enumerable static get IS_REQUIRED() { return manager.GetLocalizedString( 909 ) }								//is required
	@enumerable static get MINIMUM_LENGTH_OF_THIS_FIELD() { return manager.GetLocalizedString( 1002 ) }			//Minimum length of this field is
	@enumerable static get MAXIMUM_LENGTH_OF_THIS_FIELD() { return manager.GetLocalizedString( 1003 ) }			//Maximum length of this field is
	@enumerable static get INCORRECT_URL_PLEASE_TYPE_FULL_URL_LIKE() { return manager.GetLocalizedString( 1004 ) }//Incorrect URL. Please type full URL like http://spheeres.com
	@enumerable static get OBJECT_HAS_BEEN_COPIED() {return manager.GetLocalizedString(1058)}						//{object} has been copied
	@enumerable static get OBJECT_HAS_BEEN_REMOVED() {return manager.GetLocalizedString(1059)}						//{object} has been removed
	@enumerable static get EDITSCREEN_CANNOT_BE_USED_AS_A_HOME_SCREEN() {return manager.GetLocalizedString(1061)}	//EditScreen cannot be used as a home screen
	@enumerable static get CANNOT_OPEN_THE_HOME_SCREEN_SUBJECTENTITY_ENTTY_SCREEN() {return manager.GetLocalizedString(1062)}	//Cannot open the Home Screen: {subjectEntity} entity screen
	@enumerable static get HOME_SCREEN_SUBJECT_ENTITY() {return manager.GetLocalizedString(1064)}					//Home Screen: {subjectEntity}
	@enumerable static get ALLOWED_FILE_EXTENSIONS_ARE_EXTENSIONS() { return manager.GetLocalizedString(996) }	//Allowed file extensions are {FileExtensions}
	@enumerable static get CANNOT_UPLOAD_FILES() { return manager.GetLocalizedString(1079) }					//Cannot upload files: {Files}
	@enumerable static get YOU_ARE_NOT_ALLOWED_TO_PERFORM_THIS_ACTION() { return manager.GetLocalizedString(1100) }					//You are not allowed to perform this action
	@enumerable static get ERROR_GETTING_LIFESTATUSES() { return manager.GetLocalizedString(379) }				//Error getting lifestatuses for entity {EntityName}
	@enumerable static get LINK_HAS_BEEN_COPIED() { return manager.GetLocalizedString(1441)};						//Link has been copied
	@enumerable static get COULD_NOT_PROCEED_PLEASE_CREATE_THE_LINK_BETWEEN_ENTITIES() { return manager.GetLocalizedString(1517)};	//Could not proceed, please, create the link between {entity1} and {entity2}

	@enumerable static get INVALID_USER() { return manager.GetLocalizedString( 1220 ) }
	@enumerable static get INVALID_DATABASE() { return manager.GetLocalizedString( 1221 ) }
	@enumerable static get ACCESS_DENIED() { return manager.GetLocalizedString( 1222 ) }
	@enumerable static get LICENSE_WILL_BE_EXPIRED() { return manager.GetLocalizedString( 1422 ) }				//License will be expired on {date}. Please, contact your licensor.
	//#endregion

	//#region LoginPage
	@enumerable static get FORM_INVALID() { return manager.GetLocalizedString( 1394, 'Please, fill in all required fields' ) }						// Please, fill in all required fields
	@enumerable static get RESET_PASSWORD_SENT() { return manager.GetLocalizedString(1396, 'Reset password link has been sent to your e-mail') }		//Reset password link has been sent to your e-mail
	@enumerable static get ERROR_USER_ALLOWANCE() { return manager.GetLocalizedString(1397, 'Error getting user allowance') }							//Error getting user allowance
	@enumerable static get ERROR_USER_DATA() { return manager.GetLocalizedString(1400, 'Error getting user data') }									//Error getting user data

	@enumerable static get ENTER_CURRENT_PASSWORD() { return manager.GetLocalizedString( 1417, 'Please, enter current password' ) }					// Please, enter current password
	@enumerable static get ENTER_NEW_PASSWORD() { return manager.GetLocalizedString(1418, 'Please, enter new password') }								//Please, enter new password
	@enumerable static get ENTER_AT_LEAST_MORE_CHARACTERS() { return manager.GetLocalizedString(1419, 'Please, enter at least {minLength} characters') }		//Please, enter at least {minLength} characters
	@enumerable static get CONFIRM_NEW_PASSWORD() { return manager.GetLocalizedString(1420, 'Please, confirm new password') }							//Please, confirm new password
	@enumerable static get PASSWORDS_ARE_NOT_SAME() { return manager.GetLocalizedString(1421, 'Passwords are not same') }								//Passwords are not same
	//#endregion

	//#region UserMenu
	@enumerable static get CONTACT_SCREEN_NOT_FOUND() { return manager.GetLocalizedString( 10 ) }					// Contact screen not found
	//#endregion

	//#region Kanban Board
	@enumerable static get STATUS_UPDATED() { return manager.GetLocalizedString( 19 ) }							// Status updated
	@enumerable static get NO_LINKED_RECORDS() { return manager.GetLocalizedString( 570 ) }						// No linked records -- need to check to another place
	//#endregion

	//#region Follow up
	@enumerable static get NEXT_STATUS_NOT_AVAILABLE() { return manager.GetLocalizedString( 531 ) } 				//Next status is not available. Please contact superuser
	//#endregion

	//#region TimeWriting
	@enumerable static get CHANGES_APPLIED() { return manager.GetLocalizedString( 24 ) }							//Changes applied
	@enumerable static get TIME_OVERFLOW() { return manager.GetLocalizedString( 44 ) }							//Time overflow
	@enumerable static get ROW_EDITOR_NOT_FOUND() { return manager.GetLocalizedString( 45 ) }						//Row editor not found for reservation
    @enumerable static get FREEZE_TIME_NOT_SPECIFIED() { return manager.GetLocalizedString(901) }						//Freeze time not specified
    @enumerable static get FILL_REQUIRED_DATA() { return manager.GetLocalizedString(902) }						//Fill required data
    @enumerable static get DURATION_IS_TOO_LONG() { return manager.GetLocalizedString(903) }						//Duration is too long
    @enumerable static get TO_DATE_IS_LESS_THAN_FROM() { return manager.GetLocalizedString(988) }						//To date is less than from date
	@enumerable static get ONLY_FOLLOWING_TYPES_ARE_ALLOWED() { return manager.GetLocalizedString(993) }				//Only following types are allowed: {LowestLevelTypes}
	//#endregion

    //#region Agenda
	@enumerable static get EVENT_UPDATED() { return manager.GetLocalizedString( 25 ) }							//Event updated
	@enumerable static get EDIT__ONLY_APPOINTMENT_EVENTS() { return manager.GetLocalizedString( 116 ) }			//You can edit only Appointment Events
	@enumerable static get SELECT_ONLY_WITHIN_DAY() { return manager.GetLocalizedString( 156 ) }					//You can select only within day
	@enumerable static get BOOKED_TIME_RECORD_WAS_CREATED_SUCCESSFULLY() { return manager.GetLocalizedString( 898 ) }					//Booked time record was created successfully
	@enumerable static get ATTENDEES_CANNOT_BE_ADDED_DUE_TO_MISSING_LINK_LIST() { return manager.GetLocalizedString( 1196 ) }			//Attendees cannot be added due to missing LinkList
	//#endregion

	//#region Password
	@enumerable static get PASSWORD_REVOKED() { return manager.GetLocalizedString( 26 ) }							//Password has been revoked
	@enumerable static get PASSWORD_RESET() { return manager.GetLocalizedString( 27 ) }							//Password has been reset
	//#endregion

	//#region Mail
	@enumerable static get EMAIL_WAS_SENT() { return manager.GetLocalizedString( 28 ) }							//Email was sent
	@enumerable static get PLEASE_ADD_ACCOUNT() { return manager.GetLocalizedString( 29 ) }						//Please, add at least one correct account
	@enumerable static get PLEASE_SELECT_ANY_EMAIL() { return manager.GetLocalizedString( 30 ) }					//Please select any mail
	@enumerable static get MARKED_AS_READ() { return manager.GetLocalizedString( 31 ) }							//Successfully marked as read
	@enumerable static get MARKED_AS_UNREAD() { return manager.GetLocalizedString( 32 ) }							//Successfully marked as unread
	@enumerable static get PLEASE_ADD_RECIPIENT() { return manager.GetLocalizedString( 33 ) }						//Please, specify at least one recipient
	@enumerable static get OUT_TYPE_NOT_FOUND() { return manager.GetLocalizedString( 87 ) }						//Out type not found
	@enumerable static get IN_TYPE_NOT_FOUND() { return manager.GetLocalizedString( 88 ) }						//In type not found
	//#endregion

	//#region SelectUser
	@enumerable static get SELECTION_APPLIED() { return manager.GetLocalizedString( 34 ) }						//Selection applied
	//#endregion

	//#region Link list
	@enumerable static get RECORD_ALREADY_EXISTS() { return manager.GetLocalizedString( 35 ) }					//Record already exists
	@enumerable static get RECORD_WAS_AUTOLINKED() { return manager.GetLocalizedString( 36 ) }					//Record was autolinked
	@enumerable static get USER_SHOULD_BE_LINKED() { return manager.GetLocalizedString(86) }						//At least one user should be linked
	@enumerable static get LINKS_TO_ENTITIES_MUST_BE_CREATED() { return manager.GetLocalizedString(1302) }		//Link(s) to Entities must be created
	//#endregion

	//#region Screenshot
	@enumerable static get CREATING_SCREENSHOT() { return manager.GetLocalizedString( 38 ) }						//Creating screenshot
	@enumerable static get SCREENSHOT_CREATED() { return manager.GetLocalizedString( 39 ) }						//Screenshot was successfully created
	@enumerable static get MAIL_ADDRESS_FOR_GLOBAL_NOT_FOUND() { return manager.GetLocalizedString( 78 ) }		//Mail address for ${GlobalVariable} can not be empty
	//#endregion

	//#region Migration tool
	@enumerable static get PROVIDE_SQL_SCRIPT() { return manager.GetLocalizedString( 42 ) }						//Provide SQL script
	@enumerable static get SELECT_DATABASE() { return manager.GetLocalizedString( 43 ) }							//Select at least one database

	@enumerable static get PLEASE_ADD_YOUR_EMAIL() { return manager.GetLocalizedString( 46 ) }					//Please, add your mail
	@enumerable static get PLEASE_ADD_EMAIL() { return manager.GetLocalizedString( 47 ) }							//Please, add email
	//#endregion

	//#region SecurityEditor
	@enumerable static get TYPE_IS_NOT_SUPPORTED() { return manager.GetLocalizedString( 48 ) }					//This type is not supported
	//#endregion

	//#region Basket
	@enumerable static get RECORD_ADDED_TO_BASKET() { return manager.GetLocalizedString( 50 ) }					//Record added to basket
	@enumerable static get PLEASE_CREATE_OR_SELECT_BASKET_RECORD() { return manager.GetLocalizedString( 51 ) }	//Please create or select basket record
	@enumerable static get PLEASE_SELECT_BASKET_RECORD() { return manager.GetLocalizedString( 52 ) }				 //Please select basket record
	@enumerable static get RECORD_REMOVED_FROM_BASKET() { return manager.GetLocalizedString( 53 ) }				//Record removed from basket
	//#endregion

	//#region ButtonFavorite
	@enumerable static get PLEASE_SELECT_ANY_RECORD() { return manager.GetLocalizedString( 54 ) }					//Please select any record
	//#endregion

	//#region CanvasDesginer
	@enumerable static get CANVAS_UPDATED() { return manager.GetLocalizedString( 55 ) }							//Canvas successfully updated
	@enumerable static get PLEASE_SAVE_CANVAS_BEFORE_EXECUTE() { return manager.GetLocalizedString( 56 ) }		//Please save canvas data before execute
	@enumerable static get PLEASE_SELECT_SOURCE_AND_DESTINATION() { return manager.GetLocalizedString( 57 ) }	//Please select both source and destination before executing
	@enumerable static get DATAWIZARD_EXECUTED() { return manager.GetLocalizedString( 58 ) }					//DataWizard executed
	@enumerable static get ROLLBACK_APPLIED() { return manager.GetLocalizedString( 1245 ) }						//Rollback applied successfully
	
	//#endregion

	//#region Drop
	@enumerable static get ONLY_ONE_FILE_WAS_SAVED() { return manager.GetLocalizedString( 59 ) }				//Only one file from set was saved
	@enumerable static get DOCUMENT_UPLOADED() { return manager.GetLocalizedString( 60 ) }						//Document successfully uploaded
	@enumerable static get OVERAL_FILE_SIZE_EXCEEDED() { return manager.GetLocalizedString( 91 ) }				//Overall files size limit exceeded
	@enumerable static get ALLOWED_FILE_SIZE_EXCEEDED() { return manager.GetLocalizedString( 92 ) }				//File size limit per file has been exceeded
    @enumerable static get EMPTY_SIZE_FILES_RESTRICTION() { return manager.GetLocalizedString( 745 ) }			//Empty files could not be uploaded
    @enumerable static get ALLOWED_FILES_SIZE_EXCEEDED() { return manager.GetLocalizedString( 1049 ) }			//It is allowed to upload only {size} MB per upload session
    @enumerable static get ALLOWED_FILES_AMOUNT_EXCEEDED() { return manager.GetLocalizedString( 1050 ) }		//It is allowed to upload only {amount} files
	//#endregion

    //#region FormDesigner
	@enumerable static get SCREEN_UPDATED() { return manager.GetLocalizedString( 62 ) }							//Screen successfully updated
	@enumerable static get SCREEN_REFRESHED() { return manager.GetLocalizedString( 63 ) }							//Screen refreshed
	@enumerable static get PLEASE_PROVIDE_NAME() { return manager.GetLocalizedString( 64 ) }						//Please provide a name
	@enumerable static get SAVE_ONLY_ONE_FILE() { return manager.GetLocalizedString( 513 ) }						//In case of multiple files loading only one file from set will be saved
	//#endregion

	//#region Grid
	@enumerable static get ERROR_GET_GRID_DATA() { return manager.GetLocalizedString( 66 ) }						//Error getting grid data
	@enumerable static get QUERY_BUILDER_NOT_FOUND() { return manager.GetLocalizedString( 67 ) }					//QueryBuilderControl was not found
	@enumerable static get GRID_SUBJECT_NOT_FOUND() { return manager.GetLocalizedString( 68 ) }					    //Grid subject not found
	@enumerable static get RECORDS_LINKED() { return manager.GetLocalizedString( 69 ) }							    //Records linked
	@enumerable static get NO_SELECTED_RECORDS() { return manager.GetLocalizedString( 70 ) }						//No selected records
	@enumerable static get NO_EDITABLE_COLUMNS() { return manager.GetLocalizedString( 81 ) }						//No editable columns
	@enumerable static get ERROR_DELETE_GRID_DATA() { return manager.GetLocalizedString( 363 ) }					//Error deleting grid data {ErrorMessage}
	@enumerable static get GRID_CELL_HYPERLINK_TOOLTIP() { return manager.GetLocalizedString( 540 ) }				//Click to open record, hold CTRL button to open in popup
	@enumerable static get NOT_APPLICABLE_FOR_LIST_SCREEN() {return manager.GetLocalizedString( 705 )}			    // Not applicable for List screen
	@enumerable static get SORT_BY_COLUMN_TO_ENABLE_SORTING() {return manager.GetLocalizedString( 706 )}			// Sort by this column to enable sorting
    @enumerable static get SOME_RECORDS_ARE_LOCKED() { return manager.GetLocalizedString(1146) }			        // Some records on the page are locked
    @enumerable static get NOTHING_TO_COPY() { return manager.GetLocalizedString(1170) }			                // Nothing to copy
    @enumerable static get LINK_RECORDS_NOT_FOUND() { return manager.GetLocalizedString(1171) }			            // Link records not found
    @enumerable static get USE_LINK_ADDLINK_OR_ADDQUERY_BUTTONS_TO_COPY() { return manager.GetLocalizedString(1172) }			        // Use Link, Add&Link, or Add Query buttons to copy
	@enumerable static get INVALID_RECORD_ID() { return manager.GetLocalizedString(1200) }							//Invalid record ID
	//#endregion

    //#region Generic button
	@enumerable static get PARAM_IS_EMPTY() {return manager.GetLocalizedString( 812 )}			    			// Cannot run the function - {ParamName} is empty
	@enumerable static get SELECT_RECORDS_TO_PROCESS() {return manager.GetLocalizedString( 951 )}			    	// Select records to process
	//#endregion

	//#region Add query button
	@enumerable static get NO_QUERIES_FOUND() { return manager.GetLocalizedString( 1077 ) }							// No queries found
	//#endregion

	//#region QueryBuilder
	@enumerable static get PLEASE_SELECT_ANY_FIELD() { return manager.GetLocalizedString( 71 ) }					//Please select any field
	//#endregion

	//#region Lookup editor
	@enumerable static get CD_TABLE_NOT_FOUND() { return manager.GetLocalizedString( 72 ) }						//CD_Table id not found
	@enumerable static get PLEASE_SELECT_ENTITY() { return manager.GetLocalizedString( 73 ) }						//Please, select entity
	@enumerable static get SAVE_OR_DISCARD_CHANGES() { return manager.GetLocalizedString( 75 ) }					//Save or discard changes before next step, please
	@enumerable static get ALL_RECORDS_SAVED() { return manager.GetLocalizedString( 76 ) }						//All records saved
	@enumerable static get NO_PRIMARY_KEY() { return manager.GetLocalizedString( 77 ) }							//There is no primary key field
	@enumerable static get SELECT_ANY_OPTION() { return manager.GetLocalizedString( 102 ) }						//Select any option
	//#endregion

	//#region DataRole
	@enumerable static get DATA_ROLE_IS_NOT_SUPPORTED() { return manager.GetLocalizedString( 79 ) }				//Current data role is not supported
	//#endregion

	//#region Field security
	@enumerable static get PLEASE_UPDATE_VIEW() { return manager.GetLocalizedString( 80 ) }						//Please, update views after saving
	//#endregion

	//#region Camera
	@enumerable static get ERROR_CAMERA_INITIALIZATION() { return manager.GetLocalizedString( 83 ) }				//Error camera initialization: {ErrorMessage}
	@enumerable static get GET_USER_MEDIA_IS_NOT_SUPPORTED() { return manager.GetLocalizedString( 84 ) }			//getUserMedia not supported
	@enumerable static get PLEASE_TAKE_PHOTO() { return manager.GetLocalizedString( 85 ) }						//Please, take the photo!
	//#endregion

	//#region Environment manager
	@enumerable static get CONFIGURATION_SAVED() { return manager.GetLocalizedString( 89 ) }						//Database configuration saved
	@enumerable static get DATABASE_DELETED() { return manager.GetLocalizedString( 90 ) }							//Database deleted
	@enumerable static get DATABASE_NOT_FOUND() { return manager.GetLocalizedString( 355 ) }						//Databases not found
	@enumerable static get CONFIRMATION_FOR_COPY_ENVIROMENT() { return manager.GetLocalizedString( 1309 ) }		//Are you sure that you want to copy the current environment? The operation will take a lot of time and could not be reverted.
	@enumerable static get ENVIROMENT_WAS_CREATED() { return manager.GetLocalizedString( 1310 ) }					//New application {Environment} was created
	//#endregion

	//#region PortletSpace
	@enumerable static get PORTLET_SPACE_UPDATED() { return manager.GetLocalizedString( 510 ) }					//Portlet space updated
	@enumerable static get MORE() { return manager.GetLocalizedString( 548 ) }									//More dropdown
	@enumerable static get DELETE() { return manager.GetLocalizedString( 549 ) }									//Delete button
	@enumerable static get PORTLET_SPACE_INFO() { return manager.GetLocalizedString( 550 ) }						//Portlet space info
	@enumerable static get PORTLET_SPACE_NAME() { return manager.GetLocalizedString( 551 ) }						//Portlet space name
	@enumerable static get USE_AS_MAIN() { return manager.GetLocalizedString( 552 ) }								//Use as main
	@enumerable static get CREATE_NEW() { return manager.GetLocalizedString( 555 ) }								//Create new button
	@enumerable static get PLEASE_ENTER_PORTLET_SPACE_NAME() { return manager.GetLocalizedString( 557 ) }			//Please, enter a portlet space name
	@enumerable static get PORTLET_SPACE_WILL_BE_DELETED() { return manager.GetLocalizedString( 559 ) }			//Portlet space will be deleted
	//#endregion

	//#region FileUpload
	@enumerable static get FILE_SIZE_IS_TOO_BIG() { return manager.GetLocalizedString( 93 ) }					//File is too big. Max filesize {MaxFileSize}MiB
	//#endregion

	//#region License
	@enumerable static get LOGO_UPDATED() { return manager.GetLocalizedString( 115 ) }							//Logo updated successful
	//#endregion

	//#region Profile card
	@enumerable static get CURRENT_PASSWORD_IS_REQUIRED() { return manager.GetLocalizedString( 94 ) }				//Field CurrentPassword is required
	@enumerable static get NEW_PASSWORD_IS_REQUIRED() { return manager.GetLocalizedString( 95 ) }					//Field NewPassword is required
	@enumerable static get RE_TYPE_PASSWORD_NOT_MATCH() { return manager.GetLocalizedString( 96 ) }				//Re-type password do not match
	@enumerable static get PASSWORD_CHANGED() { return manager.GetLocalizedString( 97, 'Password changed' ) }							//Password changed
	//#endregion

	//#region Theme builder
	@enumerable static get THEME_SAVED() { return manager.GetLocalizedString( 99 ) }								//Theme saved
	//#endregion

	//#region Consultscreen
	@enumerable static get SECURITY_RESET_DONE() { return manager.GetLocalizedString( 100 ) }						//Security reset done
	@enumerable static get CLICK_TO_SEE_ALL_RECORDS_IN_THE_LIST() { return manager.GetLocalizedString(1117) }		//Click to see all records in the list
	@enumerable static get THE_NUMBER_OF_RECORDS_EXCEEDS_THE_CONFIGURED_LIMIT() { return manager.GetLocalizedString(1123)}		//The number of records to show exceeds the configured limit
	//#endregion

	//#region Control editor
	@enumerable static get PLEASE_SET_ALL_PROPERTIES() { return manager.GetLocalizedString( 101 ) }				//Please set all properties
	//#endregion

	//#region FieldCollection
	@enumerable static get VIEWS_UPDATED() { return manager.GetLocalizedString( 103 ) }							//Views updated
	@enumerable static get SAVED() { return manager.GetLocalizedString( 104 ) }									//Saved
	//#endregion

	//#region UserVarManager
	@enumerable static get WRONG_ARGS() { return manager.GetLocalizedString( 105 ) }								//Wrong args while adding to recent, record was not added
	//#endregion

	//#region TypeScreen
	@enumerable static get TYPE_IS_NOT_AVAILABLE() { return manager.GetLocalizedString( 107 ) }					//{TypeName} is not available. Please contact your SuperUser
	//#endregion

	//#region Search screen
	@enumerable static get RECORD_IS_SEARCH_ONLY() { return manager.GetLocalizedString( 108 ) }					//Record is search only
	@enumerable static get NO_SEARCHABLE_FIELDS() { return manager.GetLocalizedString( 109 ) }					//Searchable fields not found. Please, select at least one on Database Designer
	@enumerable static get NO_DATA_FOUND_WITH_APPLIED_FILTER() { return manager.GetLocalizedString(1105) }		//No data found with applied filter.Default search was used.
	//#endregion

    //#region ListScreen
	@enumerable static get EDIT_SCREEN_NOT_EXISTS() { return manager.GetLocalizedString( 110 ) }					//Edit screen for this type doesn't exist
	//#endregion

	//#region FormDesigner
	@enumerable static get ENTITY_ALREADY_ADDED() { return manager.GetLocalizedString( 111 ) }					//Entity already added
	@enumerable static get NEW_CONTROL_CREATED() { return manager.GetLocalizedString( 112 ) }						//New control has been created
	@enumerable static get FIELD_ALREADY_ADDED() { return manager.GetLocalizedString( 114 ) }						//Current field has already been added
	@enumerable static get ONLY_CUSTOM_FIELDS_ALLOWED() { return manager.GetLocalizedString( 1347 ) }				//Only custom fields of $Time allowed
	@enumerable static get FIELD_FROM_ENTITY_NAME_IS_ALREADY_PRESENT() { return manager.GetLocalizedString( 1348 ) }		//Field from {entityName} is already present
	//#endregion

	//#region DatabaseDesigner
	@enumerable static get LINK_TABLE_COULD_NOT_BE_REVERSED() { return manager.GetLocalizedString( 452 ) }		//Link table for SYS table could not be reversed
	@enumerable static get NO_CHANGES_TO_UPDATE_IN_DATABASE() { return manager.GetLocalizedString( 467 ) }		//There are no changes to update in the database
	@enumerable static get REPLACE_EXISTING_VIEW() { return manager.GetLocalizedString( 618 ) }					//The existing view will be replaced with a new one. Process?
	@enumerable static get EMPTY_CHECKLIST_FIELD() { return manager.GetLocalizedString( 925 ) }					//Checklist element name cannot be empty
	@enumerable static get SELECT_A_LOOKUP() { return manager.GetLocalizedString( 1355 ) }						//Select a lookup
	//#endregion

	//#region Lifestatus designer
	@enumerable static get LIFESTATUS_FLOW_LOADED() { return manager.GetLocalizedString( 453 ) }					//Type lifestatus flow succesfully loaded
	@enumerable static get ERROR_BUILDING_LIFE_CYCLE() { return manager.GetLocalizedString( 454 ) }				//Error building life cycle
	@enumerable static get YOUR_FLAG_IMAGE_WAS_UPLOADED_SUCCESSFULLY() { return manager.GetLocalizedString( 502 ) } //Your flag image was uploaded successfully!
	@enumerable static get INCORRECT_PASSWORD() { return manager.GetLocalizedString( 518 ) };                      // Wrong password
	@enumerable static get PLEASE_CREATE_ALL_THREE_TRANSITIONS_FOR_A_TEST_NODE() { return manager.GetLocalizedString( 1265 ) };                      // Please, create all three transitions for a Test node {TestNodeName}
	@enumerable static get THE_NEW_DEFAULT_RETIRED_STATUS() { return manager.GetLocalizedString( 1436 ) };         // The {name} is new default retired status
	@enumerable static get THERE_IS_NOT_DEFAULT_RETIRED_STATUS() { return manager.GetLocalizedString( 1438 ) };    // There is no default retired status
	@enumerable static get SELECT_STATUS_FOR_SUB_FLOW() { return manager.GetLocalizedString( 1445 ) };    		// Please, select Status for SubFlow
	//#endregion

	//#region Personal Settings
	@enumerable static get GLOBALS_UPDATED() { return manager.GetLocalizedString( 508 ) }							//Type lifestatus flow succesfully loaded
	@enumerable static get INVALID_CULTURE() { return manager.GetLocalizedString( 904 ) }							//Invalid culture: {currentCulture}. Setting default culture: {localCulture}
	@enumerable static get INVALID_DESKTOP_LANGUAGE() { return manager.GetLocalizedString( 1248 ) }				//Invalid desktop language: {currentDesktopLanguage}. Setting default desktop language: {defaultDesktopLanguage}
	//#endregion

	//#region Progressbar
	@enumerable static get TITLE_CONVERTED_TYPE_OF_RECORD() { return manager.GetLocalizedString( 542 ) }			//Type of record being converted. Please, save record firstly
    //#endregion

	//#region BulkEmail
	@enumerable static get TABLE_IS_NOT_SELECTED() { return manager.GetLocalizedString( 352 ) }			//Template table is not selected for control
	@enumerable static get MAILING_CONFIGURED() { return manager.GetLocalizedString( 354 ) }					//Mailing configured
	@enumerable static get BULK_EDIT_RECORD_NUMBER_WILL_BE_EDITED() { return manager.GetLocalizedString( 1224 ) }	//Bulk Edit: {records} records will be edited
	@enumerable static get EDIT_ALL_RECORD_NUMBER_WILL_BE_EDITED() { return manager.GetLocalizedString( 1235 ) }	//Edit All: {records} records will be edited
	//#endregion

	//#region Outlook Add-In
	@enumerable static get FORM_FOR_EMAIL_NOT_CONFIGURED() { return manager.GetLocalizedString( 699 ) }					//Forms for emails are not configured on this system. Contact your superuser
	@enumerable static get CANNOT_LINK_RECORD() { return manager.GetLocalizedString( 1072 ) }					//Spheeres Link: cannot link record
	//#endregion

	//#region Finacial Dashboard
	@enumerable static get QUERY_EXPRESSION_WAS_NOT_CREATED() { return manager.GetLocalizedString(758)}; //Query expression was not created
	@enumerable static get DOCUMENTS_HAS_BEEN_SENT_TO_FINANCIAL_PROCESSOR() { return manager.GetLocalizedString(759)}; //Documents has been sent to Financial Processor
	//#endregion

	//#region Clean Database Modal
	@enumerable static get NO_CLEANING_OPTION_SELECTED() { return manager.GetLocalizedString(764)};				//Please, select what you would like to clean
	//#endregion

	//#region Scheduler
	@enumerable static get INVALID_SCHEDULER_SUBJECT() { return manager.GetLocalizedString(884) };				//Invalid scheduler subject specified
	//#endregion

	//#region ProductConfigurator
	@enumerable static get ERROR_GETTING_CONFIGURED_PRODUCTS() { return manager.GetLocalizedString(974) };      //Error getting configured products
	@enumerable static get ERROR_GETTING_ROOT_PRODUCTS(){ return manager.GetLocalizedString((975))};        //Error getting root products
	@enumerable static get ERROR_GETTING_NESTED_PRODUCTS(){ return manager.GetLocalizedString((976))};		//Error getting nested products
	@enumerable static get ERROR_GETTING_PRODUCT_INFO(){ return manager.GetLocalizedString((977))};		//Error getting product info
	@enumerable static get ERROR_GETTING_CONFIGURED_PRODUCT(){ return manager.GetLocalizedString((978))};		//Error getting configured product
	@enumerable static get ERROR_GETTING_GROUPS_PRODUCTS(){ return manager.GetLocalizedString((979))};		//Error getting group's products
	@enumerable static get ERROR_CREATING_CONFIGURATION(){ return manager.GetLocalizedString((980))};		//Error creating configuration
	@enumerable static get ERROR_UPDATING_CONFIGURATION(){ return manager.GetLocalizedString((981))};		//Error updating configuration
	@enumerable static get CLICK_TO_SEE_ALL_COLORS() { return manager.GetLocalizedString((1119))};		//Click to see all colors
	@enumerable static get CLICK_TO_SEE_ALL_IMAGES() { return manager.GetLocalizedString((1120))};		//Click to see all images
	@enumerable static get YOU_DONT_HAVE_ANY_OF_REQUIRED_BUSINESS_ROLES() {return manager.GetLocalizedString((1197))};		//You don't have any of required business roles
	@enumerable static get PLEASE_FILL_ALL_DATA() {return manager.GetLocalizedString((1442))};		//Please, fill all data
	@enumerable static get PROPERTY_VALUE_SHOULD_HAVE_THE_UNIQUE_NAME() {return manager.GetLocalizedString((1498))};		//Property value should have the unique Name
	//#endregion

	//#region Document control
	@enumerable static get CHECKIN_IS_NOT_ALLOWED() { return manager.GetLocalizedString(1051) } 					//Cannot CheckIn: Assemblies must be checked in via SolidWorks
	//#endregion

	//#region Planner
	@enumerable static get UNLINK_QUERY_RECORD() { return manager.GetLocalizedString(1071) } 	//This will unlink the existing record. Continue?
	@enumerable static get COPY_PLANNER_DATA() { return manager.GetLocalizedString(1154) } 		//Selected rows/columns already contain planning data. Overwrite?
	@enumerable static get SELECT_PERIOD() { return manager.GetLocalizedString(1157) } 			//Select {periodName}s to copy to
	@enumerable static get SELECT_PLANNER_ROWS() { return manager.GetLocalizedString(1158) } 	//Select rows to copy to
	//#endregion

	//#region Timer
	@enumerable static get ERROR_READING_TIMER_STATE() { return manager.GetLocalizedString(1131) }		//Error reading timer state
	@enumerable static get ERROR_GETTING_ACTIVE_TIMERS() { return manager.GetLocalizedString(1132) }		//Error getting active timers
	@enumerable static get ERROR_STARTING_TIMER() { return manager.GetLocalizedString(1133) }		//Error starting timer
	@enumerable static get ERROR_STOPPING_TIMER() { return manager.GetLocalizedString(1134) }		//Error stopping timer
	@enumerable static get ERROR_SETTING_OVERTIME() { return manager.GetLocalizedString(1135) }		//Error setting overtime
	@enumerable static get GOOGLE_KEY_IS_NOT_VALID() { return manager.GetLocalizedString(1431) }		//Please, check Google maps API key global. The current API key is not valid
	//#endregion

	//#region Planner
	@enumerable static get NO_ANY_VIEW_OPTIONS() { return manager.GetLocalizedString(1432) }			//Please, select at least one option in properties - Month, Week, Day
	//#endregion	


	//#region Dropdown control
	@enumerable static get ERROR_GETTING_DROPDOWN_DATA() { return manager.GetLocalizedString(1433) }			//Error getting dropdown data for {fieldName}
	//#endregion	

	//#region Report issue
	@enumerable static get ISSUE_HAS_BEEN_SENT_TO_SUPPORT_TEAM() { return manager.GetLocalizedString(1463) }				//Issue has been sent to support team
	@enumerable static get SCREENSHOT_CAN_BE_MADE_ONLY_USING_HTTPS() { return manager.GetLocalizedString(1464) }			//Screenshot can be made only using https
	//#endregion

	//#region Memo
	@enumerable static get WRONG_FORMAT_IN_MEMO_TIMESTAMP_FORMAT_GLOBAL() { return manager.GetLocalizedString( 1503 ) }		//Wrong format {formatValue} in Memo Timestamp Format global, e.g. {defaultFormatValue}
	//#endregion

}

export class LABELS {
	//#region Common
	@enumerable static get NO_IMAGE() { return manager.GetLocalizedString( 1271 ) }								//No image
	@enumerable static get ID() { return manager.GetLocalizedString( 119 ) }										//Id
	@enumerable static get NAME() { return manager.GetLocalizedString( 120 ) }									//Name
	@enumerable static get TYPE() { return manager.GetLocalizedString( 121 ) }									//Type
	@enumerable static get MESSAGE() { return manager.GetLocalizedString( 469 ) }									//Message
	@enumerable static get SUCCESSFUL() { return manager.GetLocalizedString( 470 ) }								//Successful
	@enumerable static get WARNINGS() { return manager.GetLocalizedString( 471 ) }								//Warnings
	@enumerable static get ERRORS() { return manager.GetLocalizedString( 472 ) }									//Errors
	@enumerable static get RESULTS() { return manager.GetLocalizedString(749)}									//Results
	@enumerable static get PROCESS() { return manager.GetLocalizedString( 754 ) }									//Process
	@enumerable static get SEARCH_TERM() { return manager.GetLocalizedString( 122 ) }								//Search Term
	@enumerable static get ROOT() { return manager.GetLocalizedString( 126 ) }									//Root
	@enumerable static get SEARCH() { return manager.GetLocalizedString(123) }									//Search
	@enumerable static get SEARCH_BY_NAME() { return manager.GetLocalizedString(393) }							//Search by name
	@enumerable static get SETTINGS() { return manager.GetLocalizedString(1065) }									//Settings
    @enumerable static get NEW() { return manager.GetLocalizedString( 140 ) }										//New
	@enumerable static get SEARCH_WITH_DOTS() { return manager.GetLocalizedString( 144 ) }						//Search...
	@enumerable static get SEARCH_GRID() { return manager.GetLocalizedString( 1106 ) }							//Search text
	@enumerable static get ADD() { return manager.GetLocalizedString( 124 ) }										//Add
	@enumerable static get CANCEL() { return manager.GetLocalizedString( 125, 'Cancel' ) }			//Cancel
	@enumerable static get DELETE() { return manager.GetLocalizedString( 145 ) }									//Delete
	@enumerable static get SAVE_CHANGES() { return manager.GetLocalizedString( 146 ) }							//Save changes
	@enumerable static get SAVE_AND_CLOSE() { return manager.GetLocalizedString( 1504 ) }							//Save & Close
	@enumerable static get ALL() { return manager.GetLocalizedString( 127 ) }										//All
	@enumerable static get SHOW() { return manager.GetLocalizedString( 147 ) }									//Show
	@enumerable static get SAVE() { return manager.GetLocalizedString( 148 ) }									//Save
	@enumerable static get EDIT() { return manager.GetLocalizedString( 150 ) }									//Edit
	@enumerable static get LIST() { return manager.GetLocalizedString( 151 ) }									//List
	@enumerable static get RETURN() { return manager.GetLocalizedString( 152 ) }									//Return
	@enumerable static get CREATE() { return manager.GetLocalizedString( 164 ) }									//Create
	@enumerable static get RESET() { return manager.GetLocalizedString( 165 ) }									//Reset
	@enumerable static get ENTITIES() { return manager.GetLocalizedString( 172 ) }								//Entities
	@enumerable static get REVERT() { return manager.GetLocalizedString( 218 ) }									//Revert
	@enumerable static get OK() { return manager.GetLocalizedString( 223, 'Ok' ) }					//Ok
	@enumerable static get ACCEPT() { return manager.GetLocalizedString( 236 ) }									//Accept
	@enumerable static get FROM() { return manager.GetLocalizedString( 228 ) }									//From
	@enumerable static get SUBJECT() { return manager.GetLocalizedString( 230 ) }									//Subject
	@enumerable static get DISCARD() { return manager.GetLocalizedString( 278 ) }									//Discard
	@enumerable static get DESCRIPTION() { return manager.GetLocalizedString( 231 ) }								//Description
	@enumerable static get DEFAULT() { return manager.GetLocalizedString( 304 ) }									//Default
	@enumerable static get VALUE() { return manager.GetLocalizedString( 569 ) }									//Value
	@enumerable static get YES() { return manager.GetLocalizedString( 358 ) }										//Yes
	@enumerable static get NO() { return manager.GetLocalizedString( 359 ) }										//No
	@enumerable static get NO_NAME() { return manager.GetLocalizedString( 1315 ) }								//No Name
	@enumerable static get GROUP() { return manager.GetLocalizedString( 203 ) }									//Group
	@enumerable static get RUN_QUERY() { return manager.GetLocalizedString( 492 ) }								//Run query
	@enumerable static get RUN() { return manager.GetLocalizedString( 594 ) }										//Run
	@enumerable static get TEST() { return manager.GetLocalizedString( 619 ) }									//Test
	@enumerable static get RE_CREATE() { return manager.GetLocalizedString( 620 ) }								//Re-create
	@enumerable static get NO_RECORD() { return manager.GetLocalizedString(680) }									//No record
	@enumerable static get RECORD_DISABLED() { return manager.GetLocalizedString(900) }							//Record disabled
    @enumerable static get DONE() { return manager.GetLocalizedString(855) }								   		//Done
	@enumerable static get PAGE() { return manager.GetLocalizedString(922) }								   		//Page
	@enumerable static get EMPTY() { return manager.GetLocalizedString(928) }								   		//Empty
	@enumerable static get EMPTY_VALUE() { return manager.GetLocalizedString(1081) }								//(empty)
	@enumerable static get DELTA() { return manager.GetLocalizedString( 931 ) }									//Delta
	@enumerable static get IS_REQUIRED() { return manager.GetLocalizedString( 909 ) }								//is required
	@enumerable static get VALUES() { return manager.GetLocalizedString( 936 ) }									//values
	@enumerable static get REVERSE() { return manager.GetLocalizedString( 937 ) }									//reverse
	@enumerable static get COPY() { return manager.GetLocalizedString(673) };										//Copy
	@enumerable static get NEXT() { return manager.GetLocalizedString(941) };										//Next
	@enumerable static get NEXT_NEXT() { return manager.GetLocalizedString(942) };									//Next Next
	@enumerable static get USE() { return manager.GetLocalizedString(943) };										//Use
	@enumerable static get DISTRIBUTE() { return manager.GetLocalizedString(944) };								//Distribute
	@enumerable static get DAY() { return manager.GetLocalizedString(948) };										//Day
	@enumerable static get MONTH() { return manager.GetLocalizedString(949) };									//Month
	@enumerable static get THIS_FIELD_IS_REQUIRED() { return manager.GetLocalizedString( 966 ) }					//This field is required
	@enumerable static get CHANGE() { return manager.GetLocalizedString( 712 ) }									//Change
	@enumerable static get FEATURE_TYPE_IS_NOT_SET() { return manager.GetLocalizedString( 982 ) }                   //Feature type is not set
	@enumerable static get UPLOAD() { return manager.GetLocalizedString( 984 ) }                   				//Upload
	@enumerable static get OKAY() { return manager.GetLocalizedString( 985 ) }                   					//Okay
	@enumerable static get UPLOAD_FILES() { return manager.GetLocalizedString( 986 ) }                   			//Upload files
	@enumerable static get TOTAL() { return manager.GetLocalizedString( 200 ) }								    //Total
	@enumerable static get COUNT() { return manager.GetLocalizedString( 1518 ) }								    //Count
	@enumerable static get SHOW_MORE() { return manager.GetLocalizedString( 1089 ) }								    //Show More
	@enumerable static get NEW_RECORD() { return manager.GetLocalizedString( 149 ) }								//New Record
	@enumerable static get SHOW_IN_MENU() { return manager.GetLocalizedString( 1140 ) }							//Show in menu
	@enumerable static get SHOW_IN_PORTLET() { return manager.GetLocalizedString( 1496 ) }							//Show in portlet
	@enumerable static get LOCKED_BY_SUPER_USER() { return manager.GetLocalizedString( 1176 ) }					//Locked by superuser
	@enumerable static get NO_RECORD_SELECTED() { return manager.GetLocalizedString( 1225 ) }						//No record selected.
	@enumerable static get RECORD_LINK() { return manager.GetLocalizedString( 1233 ) }								//Record link.
	@enumerable static get SPHEERES_LINK() { return manager.GetLocalizedString( 1234 ) }							//Spheeres link
	@enumerable static get ROLLBACK() { return manager.GetLocalizedString(1251) }										//Rollback
	@enumerable static get MEMOS() { return manager.GetLocalizedString(1316) }										//Memos
	@enumerable static get CLICK_TO_NAVIGATE_TO_SPECIAL_SCREEN() { return manager.GetLocalizedString(1319) }		//Click to navigate to Special screen
	@enumerable static get REPLY() { return manager.GetLocalizedString(1325) }									//Reply
	@enumerable static get CC() { return manager.GetLocalizedString(1326) }										//сс
	@enumerable static get BCC() { return manager.GetLocalizedString(1327) }										//bcc
	@enumerable static get EMAIL_SUBJECT() { return manager.GetLocalizedString(1328) }							//Email Subject
	@enumerable static get E_MAIL() { return manager.GetLocalizedString(1330) }									//e-mail
	@enumerable static get FORWARD() { return manager.GetLocalizedString(1332) }									//Forward
	@enumerable static get REPLY_TO_ALL() { return manager.GetLocalizedString(1331) }								//Reply to all
	@enumerable static get TO_() { return manager.GetLocalizedString(1335) }										//to
	@enumerable static get REPORT_HOURS() { return manager.GetLocalizedString(1410) }									//Report hours
	@enumerable static get SKIP() { return manager.GetLocalizedString(1411) }										//Skip
	// #endregion

	//#region Gallery Buttons
	@enumerable static get ZOOM_IN() { return manager.GetLocalizedString( 1091 ) }								    //Zoom In
	@enumerable static get ZOOM_OUT() { return manager.GetLocalizedString( 1092 ) }								    //Zoom Out
	@enumerable static get ACTUAL_SIZE() { return manager.GetLocalizedString( 1093 ) }								    //Actual size
	@enumerable static get ROTATE_FLIP() { return manager.GetLocalizedString( 1094 ) }								    //Rotate/flip to initial position
	@enumerable static get PREVIOUS() { return manager.GetLocalizedString( 1095 ) }								    //Previous
	@enumerable static get ROTATE_LEFT() { return manager.GetLocalizedString( 1096 ) }								    //Rotate left
	@enumerable static get ROTATE_RIGHT() { return manager.GetLocalizedString( 1097 ) }								    //Rotate right
	@enumerable static get FLIP_HORIZONTALLY() { return manager.GetLocalizedString( 1098 ) }								    //Flip horizontally
	@enumerable static get FLIP_VERTICALLY() { return manager.GetLocalizedString( 1099 ) }								    //Flip vertically
	@enumerable static get ANNOTATIONS() { return manager.GetLocalizedString( 1191 ) }								    //Annotations
	//#endregion

	//#region OnOf toggler
	@enumerable static get ON() { return manager.GetLocalizedString( 153 ) }										//On
	@enumerable static get OFF() { return manager.GetLocalizedString( 154 ) }										//Of
	//#endregion

	//#region UserMenu
	@enumerable static get MY_SETTINGS() { return manager.GetLocalizedString( 1 ) }								//My Settings
	@enumerable static get MY_CONTACT_PAGE() { return manager.GetLocalizedString( 2 ) }							//My Contact Page
	@enumerable static get MY_DAILY_STUFF() { return manager.GetLocalizedString( 3 ) }							//My Daily Stuff
	@enumerable static get DEDUPLICATION() { return manager.GetLocalizedString( 4 ) }								//Deduplication
	@enumerable static get DOWNLOAD_CLIENT() { return manager.GetLocalizedString( 5 ) }							//Download Сlient
	@enumerable static get DOWNLOAD_MAIL_PLUGIN_32() { return manager.GetLocalizedString( 678 ) }					//Download Mail Plugin(32-bit)
	@enumerable static get DOWNLOAD_MAIL_PLUGIN_64() { return manager.GetLocalizedString( 679 ) }					//Download Mail Plugin(64-bit)
	@enumerable static get LOCK_SCREEN() { return manager.GetLocalizedString( 6 ) }								//Lock Screen
	@enumerable static get LOG_OUT() { return manager.GetLocalizedString( 7 ) }									//Log Out
	@enumerable static get HELP() { return manager.GetLocalizedString( 8 ) }										//Help
	@enumerable static get REPORT_ISSUE() { return manager.GetLocalizedString( 1080 ) }							//Report issue
	@enumerable static get ABOUT() { return manager.GetLocalizedString(9) }										//About
	@enumerable static get LOCKED() { return manager.GetLocalizedString(1349) }									//Locked
	@enumerable static get NOT() { return manager.GetLocalizedString(1350) }										//Not

	@enumerable static get DOWNLOADS() { return manager.GetLocalizedString(697) }									//Downloads

	@enumerable static get REQUIRED_NET_FRAMEWORK() { return manager.GetLocalizedString(698) }						//*Required Microsoft .NET Framevork version: 4.5.2+
	//#endregion

	//#region Deduplication
	@enumerable static get DEDUPLICATE() { return manager.GetLocalizedString( 1428 ) }							//Deduplicate
	@enumerable static get DEDUPLICATE_ALL() { return manager.GetLocalizedString( 1429 ) }						//Deduplicate All
	@enumerable static get MATCH_ALL() { return manager.GetLocalizedString( 1427 ) }								//Match All
	@enumerable static get MINIMAL_MATCH_RATE() { return manager.GetLocalizedString( 1430 ) }						//Minimal match rate
	@enumerable static get DELETE_ALL() { return manager.GetLocalizedString( 1447 ) }								//Delete All
	@enumerable static get CLICK_TO_DELETE_ALL_MATCH_LINKS() { return manager.GetLocalizedString( 1448 ) }		//Click to delete all match links for the current entity
	//#endregion

	//#region Main menu
	@enumerable static get DESIGNERS() { return manager.GetLocalizedString( 11 ) }								//Designers
	@enumerable static get LOOKUP_EDITOR() { return manager.GetLocalizedString( 12 ) }							//Lookup editor
	@enumerable static get DATABASE_DESIGNER() { return manager.GetLocalizedString( 13 ) }						//Database Designer
	@enumerable static get FORM_DESIGNER() { return manager.GetLocalizedString( 14 ) }							//Form Designer
	@enumerable static get CANVAS_DESIGNER() { return manager.GetLocalizedString( 15 ) }							//Canvas Designer
	@enumerable static get NETWORK_DESIGNER() { return manager.GetLocalizedString( 1295 ) }							//Network Designer
	@enumerable static get FUNCTION_DESIGNER() { return manager.GetLocalizedString( 590 ) }							//Function Designer
	@enumerable static get FIELD_COLLECTION_DESIGNER() { return manager.GetLocalizedString( 16 ) }				//FieldCollection Designer
	@enumerable static get SYSTEM() { return manager.GetLocalizedString( 17 ) }									//SYSTEM
	@enumerable static get ENVIRONMENT_MANAGER() { return manager.GetLocalizedString( 18 ) }						//Environment Manager
	@enumerable static get TRANSLATION_EDITOR() { return manager.GetLocalizedString( 509 ) }						//Translation Editor
	//#endregion

	//#region Record sharing
	@enumerable static get PRIMARY_GROUP() { return manager.GetLocalizedString( 607 ) }							//Primary group
	@enumerable static get SELECT_GROUP() { return manager.GetLocalizedString( 608 ) }                          //select group
	@enumerable static get CLICK_TO_LOCK_GROUP() { return manager.GetLocalizedString( 1483 ) }					//Click to lock this group for this profile
	@enumerable static get GROUP_IS_LOCKED() { return manager.GetLocalizedString( 1484 ) }						//The group is locked to this profile
	@enumerable static get USED_IN_PROFILES() { return manager.GetLocalizedString( 1485 ) }						//Used in {profile list separate by come} profile(s)
	//#endregion

	//#region Search screen
	@enumerable static get RECENT() { return manager.GetLocalizedString( 117 ) }									//Recent
	@enumerable static get FAVORITES() { return manager.GetLocalizedString( 118 ) }								//Favorites
	@enumerable static get ONLY_LAST_90() { return manager.GetLocalizedString( 128 ) }							//Only last 90 days
	@enumerable static get ONLY_LAST_90_ARE_SHOWN() { return manager.GetLocalizedString( 576 ) }					//Only records modified last 90 days are shown. Click to show all records
	@enumerable static get ONLY_LAST_90_ARE_INACTIVE() { return manager.GetLocalizedString( 577 ) }				//All records are shown. Click to show records modified last 90 days
	@enumerable static get ENABLE_FILTER() { return manager.GetLocalizedString( 565 ) }							//Enable filter
	@enumerable static get LIFESTATUS() { return manager.GetLocalizedString( 129 ) }								//Life Status
	@enumerable static get MODE() { return manager.GetLocalizedString( 130 ) }									//Mode
	@enumerable static get FAST_SEARCH() { return manager.GetLocalizedString( 131 ) }								//Fast search
	@enumerable static get PHONETIC_SEARCH() { return manager.GetLocalizedString( 132 ) }							//Phonetic search
	@enumerable static get SEARCH_MODES() { return manager.GetLocalizedString( 133 ) }							//Search modes
	@enumerable static get SEARCH_FIELDS() { return manager.GetLocalizedString( 134 ) }							//Search fields
	@enumerable static get SEARCH_SCREEN_PAGINATION() { return manager.GetLocalizedString( 135 ) }				//Showing {StartRecordNumber} to {EndRecordNumber} of {TotalRecords} rows
	@enumerable static get TOTAL_SEARCH() { return manager.GetLocalizedString( 536 ) }							//Total search
	@enumerable static get TYPES() { return manager.GetLocalizedString( 566 ) }									//Total search
	@enumerable static get NO_RECORDS_FOUND() { return manager.GetLocalizedString( 957 ) }						//No records found
	@enumerable static get REMOVE_DUPLICATES() { return manager.GetLocalizedString(1052)};						//Remove duplicates
	@enumerable static get MERGE() { return manager.GetLocalizedString(1053)};									//Merge

	@enumerable static get OFF_FILTER_BY_RELATIONS() { return manager.GetLocalizedString( 768 ) }					//Filter off. Click to Filter by relations
	@enumerable static get FILTER_BY_RELATIONS() { return manager.GetLocalizedString( 769 ) }						//Filtered by relations. Click to filter by main relations
	@enumerable static get FILTER_BY_MAIN_RELATIONS() { return manager.GetLocalizedString( 770 ) }				//Filtered by main relations. Click to turn off the filter
	@enumerable static get NO_RELATION_TYPE() { return manager.GetLocalizedString( 774 ) }				        //No relation type
	@enumerable static get SELECT_TWO_RECORDS() { return manager.GetLocalizedString( 1016 ) }						//Select two records and click this button to merge them
	@enumerable static get FILTER_BY_LINKING_CONDITIONS() { return manager.GetLocalizedString( 1110 ) }						//Filtered by the condition. Click to turn off the filter
	@enumerable static get FILTER_BY_MAIN_RELATIONS2() { return manager.GetLocalizedString( 1111 ) }				//Filtered by main relations. Click to filter by the condition
	@enumerable static get ONLY_LINKED_DOCUMENTS_ARE_SHOWN() { return manager.GetLocalizedString( 1508 ) }		//Only linked documents are shown
	@enumerable static get CLICK_TO_START_BARCODE_SCANNING() { return manager.GetLocalizedString( 1249 ) }			//Click to start barcode scanning
	@enumerable static get CLICK_TO_START_BULK_BARCODE_SCANNING() { return manager.GetLocalizedString( 1250 ) }	//Click to start bulk barcode scanning
	@enumerable static get VALUE_CANNOT_BE_CONVERTED_TO_BARCODE() { return manager.GetLocalizedString( 1403 ) }	//Value cannot be converted to barcode
	@enumerable static get SPECIAL_CHARACTERS() { return manager.GetLocalizedString( 1303 ) }						//Special characters: \nUse "&" to search for value1 AND value2 \nUse "|" to search for value1 OR value2 \nUse "-" to search for value1 AND NOT value2 \nUse double quotes "" to search for the exact value \n\nUse "\" to search with special characters listed above \nExample: \& \- \| \"
	//#endregion

	//#region Fast Filters
	@enumerable static get FILTERING_OPTIONS_NOT_FOUND() { return manager.GetLocalizedString( 584 ) }				//Filtering options not found
	@enumerable static get MIN() { return manager.GetLocalizedString( 585 ) }										//Min
	@enumerable static get MAX() { return manager.GetLocalizedString( 586 ) }										//Max
	@enumerable static get CLEAR_DATE() { return manager.GetLocalizedString( 587 ) }								//Clear date
	@enumerable static get CHANGE_DATE() { return manager.GetLocalizedString( 588 ) }								//Change date
	@enumerable static get FILTER_RANGE_ERROR() { return manager.GetLocalizedString( 589 ) }						//Value must be within the range {MinValue} and {MaxValue}
	//#endregion

	//#region Grid
	@enumerable static get DEFAULT_VIEW() { return manager.GetLocalizedString( 136 ) }							//Default View
	@enumerable static get TABLEVIEW_NEW() { return manager.GetLocalizedString( 137 ) }							//TableView New
	@enumerable static get TABLEVIEW_EDIT() { return manager.GetLocalizedString( 138 ) }							//TableView Edit
	@enumerable static get LOCK_TABLEVIEW() { return manager.GetLocalizedString( 1356 ) }							//Lock TableView
	@enumerable static get UNLOCK_TABLEVIEW() { return manager.GetLocalizedString( 1357 ) }						//Unlock TableView
	@enumerable static get TABLEVIEW_COPY() { return manager.GetLocalizedString( 558 ) }							//TableView Copy
	@enumerable static get TABLEVIEW_DELETE() { return manager.GetLocalizedString( 139 ) }						//TableView Delete
	@enumerable static get ADD_AND_LINK() { return manager.GetLocalizedString( 141 ) }							//Add & Link {EntityName}
	@enumerable static get EXPORT_TO_CSV() { return manager.GetLocalizedString( 493 ) }							//Export to CSV
	@enumerable static get EXPORT_TO_EXCEL() { return manager.GetLocalizedString( 494 ) }							//Export to EXCEL
	@enumerable static get LINK() { return manager.GetLocalizedString( 142 ) }									//Link {entityName}
	@enumerable static get LINK_PARENT() { return manager.GetLocalizedString( 1137 ) }								//Link Parent
	@enumerable static get ADD_QUERY() { return manager.GetLocalizedString( 143 ) }								//Add Query
	@enumerable static get SHOW_ALL_COLUMNS() { return manager.GetLocalizedString( 582 ) }					//Show all columns
	@enumerable static get HIDE_COLUMNS_BY_PRIORITY() { return manager.GetLocalizedString(583) }			//Hide columns by priority
	@enumerable static get SHOW_RETIRED() { return manager.GetLocalizedString(661) }						//Show retired
	@enumerable static get HIDE_RETIRED() { return manager.GetLocalizedString(662) }						//Hide retired
	@enumerable static get EDIT_ALL() { return manager.GetLocalizedString(1000) }							//Edit all
	@enumerable static get SAVE_ALL() { return manager.GetLocalizedString(1001) }							//Save all
	@enumerable static get LINKED_RECORD_IS_RETIRED() { return manager.GetLocalizedString(1142) }			//Linked record is retired
	@enumerable static get LINKED_RECORD_REQUIRED() { return manager.GetLocalizedString(1446) }			//Linked record required
	@enumerable static get NO_ANY_CUSTOM_FIELDS() { return manager.GetLocalizedString(1143) }				//Link table does not have any Custom fields

	@enumerable static get SELECT_LINK() { return manager.GetLocalizedString(938) }						    //Select a link
	@enumerable static get COPY_LATEST() { return manager.GetLocalizedString(1169) }						//Copy latest
	@enumerable static get SCAN_AND_LINK() { return manager.GetLocalizedString(1246) }						//Scan & Link
	@enumerable static get EDIT_SORT_DESCRIPTION() { return manager.GetLocalizedString(1340) }				//Click to edit the description
	@enumerable static get SORT_REMOVE_DESCRIPTION() { return manager.GetLocalizedString(1342) }			//Click to remove the description
	@enumerable static get SORT_ADD_NEW_DESCRIPTION() { return manager.GetLocalizedString(1343) }			//Click to add a new description
	@enumerable static get CLICK_TO_PROGRESS() { return manager.GetLocalizedString(1513) }			//Click to progress

	//#endregion

    //#region Kanban board
	@enumerable static get KANBAN_SEARCH() { return manager.GetLocalizedString(748) }						// Kanban Search
	@enumerable static get KANBAN_TYPE_HIERARCHY_VIOLATION() { return manager.GetLocalizedString(1219) }	// Cannot create a new record: Type hierarchy violation
	//#endregion

	//#region Screens
	@enumerable static get PREVIOUS_RECORD() { return manager.GetLocalizedString( 538 ) }							//Next record
	@enumerable static get NEXT_RECORD() { return manager.GetLocalizedString( 539 ) }								//Previous record
	@enumerable static get FOLLOW_LIST() { return manager.GetLocalizedString( 564 ) }								//Follow List
	//#endregion	

	//#region Make personal
	@enumerable static get MAKE_PERSONAL() { return manager.GetLocalizedString( 155 ) }							//Make personal
	//#endregion

	//#region Product configurator
	@enumerable static get THERE_ARE_NO_PRODUCTS_FOUND() { return manager.GetLocalizedString( 959 ) }			//There are no products found
	@enumerable static get THERE_ARE_NO_PRODUCT_PARTS_FOUND() { return manager.GetLocalizedString( 963 ) }		//There are no product parts found
	@enumerable static get NO_GROUP_NAME() { return manager.GetLocalizedString( 965 ) }							//No group name
	@enumerable static get CLASS_NOT_SELECTED() { return manager.GetLocalizedString( 832 ) }					//Class not selected
	@enumerable static get CLASS_RECORD_SHOULD_BE_SELECTED() { return manager.GetLocalizedString(973) }			//Record of type "Class" should be selected
	@enumerable static get INCLUSIVE_PRODUCTS() { return manager.GetLocalizedString(1102) }						//Inclusive products
	@enumerable static get INCOMPATIBLE_PRODUCTS() { return manager.GetLocalizedString(1103) }					//Incompatible products
	@enumerable static get DECLINE() { return manager.GetLocalizedString(1104) }						        //Decline
	@enumerable static get NOT_SELECTED() { return manager.GetLocalizedString((1118))};							//Not Selected
	@enumerable static get SHOW_ALTERNATIVES() { return manager.GetLocalizedString((1145))};					//Show alternatives
	@enumerable static get ORDERED_PRODUCTS() { return manager.GetLocalizedString((1147))};						//Ordered products
	@enumerable static get SELECT_PRODUCT() { return manager.GetLocalizedString((1148)) };						//Select product
	@enumerable static get CHOOSE_CONFIGURATION() { return manager.GetLocalizedString((1149)) };				//Choose configuration
	@enumerable static get NEW_CONFIGURATION() { return manager.GetLocalizedString((1150))};					//New configuration
	@enumerable static get ALTERATION() { return manager.GetLocalizedString((1151))};							//Alteration
	@enumerable static get NEW_PRODUCT() { return manager.GetLocalizedString((1152)) };							//New product
	@enumerable static get PRICES() { return manager.GetLocalizedString((1162)) };								//Prices
	@enumerable static get INCOMPATIBLE_WITH() { return manager.GetLocalizedString((1167)) };					//Incompatible with:
	@enumerable static get ADD_NEW() { return manager.GetLocalizedString((1168)) };								//Add new
	@enumerable static get OTHER() { return manager.GetLocalizedString((1203)) };								//Other
	@enumerable static get PRODUCT_DOES_NOT_EXIST() { return manager.GetLocalizedString((1186))};				//Product does not exist
	@enumerable static get PRODUCTS_DO_NOT_EXIST() { return manager.GetLocalizedString((1187))};				//There are products that do not exist
	@enumerable static get CANT_RESOLVE_CONFLICTS_FOR_NESTED_DEFAULT_PRODUCTS() { return manager.GetLocalizedString((1199)) };	//Can't resolve conflicts between nested default products:
	@enumerable static get EXTRA_PRODUCTS() { return manager.GetLocalizedString((1239))};						//Extra Products
	@enumerable static get ADD_AS_EXTRA_PRODUCT() { return manager.GetLocalizedString((1505)) };				//Add as Extra Product
	@enumerable static get UNDEFINED() { return manager.GetLocalizedString((1479))};							//Undefined
	@enumerable static get PRODUCT_LIST_IS_EMPTY() { return manager.GetLocalizedString((1297))};				//Product list is empty
	@enumerable static get USE_DESCRIPTION() { return manager.GetLocalizedString((1311))};						//Use description
	@enumerable static get ALTERNATIVE_PRICE() { return manager.GetLocalizedString((1467))};													//Alternative Price
	@enumerable static get COULD_NOT_CALCULATE_ALTERNATIVE_PRICE() { return manager.GetLocalizedString((1468))};								//Could not calculate Alternative Price
	@enumerable static get THE_TRANSLATION_ALTERNATIVE_PRICE_WITH_CODE_WILL_BE_USED_ON_PCF() { return manager.GetLocalizedString((1469))};		//The translation "Alternative Price" with code {CODE} will be used on PCF
	@enumerable static get IMPOSSIBLE_TO_SELECT() { return manager.GetLocalizedString(1474) };			//Impossible to select. More than one record was found
	//#endregion

	//#region Canvas designer
	@enumerable static get EXECUTE() { return manager.GetLocalizedString( 157 ) }									//Execute
	@enumerable static get ENABLE_SOCKET_VALIDATION() { return manager.GetLocalizedString( 158 ) }				//Enable sockets validation
	//#endregion

	//Function designer
	@enumerable static get IN() { return manager.GetLocalizedString( 877 ) }								    	//In
	@enumerable static get OUT() { return manager.GetLocalizedString( 878 ) }								    	//Out
	@enumerable static get METHODS() { return manager.GetLocalizedString( 591 ) }								    //Methods


	//Environment manager
	@enumerable static get DATABASES() { return manager.GetLocalizedString(1181) }								//Databases
	@enumerable static get GUEST_PAGE() { return manager.GetLocalizedString(1182) }								//Guest Page
	@enumerable static get DATABASE_LIST() { return manager.GetLocalizedString( 159 ) }							//List of databases in CyberConfig
	@enumerable static get DATABASE_NAME() { return manager.GetLocalizedString( 160, 'Database Name' ) }							//Database name
	@enumerable static get SERFER_INFO() { return manager.GetLocalizedString( 161 ) }								//Server info
	@enumerable static get DATABASE_TYPE() { return manager.GetLocalizedString( 162 ) }							//Database type
	@enumerable static get CYBER_APP_USER() { return manager.GetLocalizedString( 163 ) }							//CyberApp User
	@enumerable static get DB_SERVER_NAME() { return manager.GetLocalizedString( 166 ) }							//DB servername
	@enumerable static get LOGIN_NAME() { return manager.GetLocalizedString( 167, 'Username' ) }		//Login name
	@enumerable static get PASSWORD() { return manager.GetLocalizedString( 168, 'Password' ) }		//Password
	@enumerable static get ENVIRONMENTS() { return manager.GetLocalizedString( 1307 ) }							//Environments
	@enumerable static get NEW_DATABASE_NAME() { return manager.GetLocalizedString( 1308 ) }						//New database name
	@enumerable static get SPECIAL_CHARACTERS_NOT_ALLOWED() { return manager.GetLocalizedString( 1312 ) }			//You can use only alphabetic characters, numbers and "_" without space
	@enumerable static get NEW_ENVIRONMENT_WILL_BE_COPIED() { return manager.GetLocalizedString( 1313 ) }			//A new environment will be copied from current one. Enter a name for a new environment

	//FormDesigner
	@enumerable static get PREVIEW() { return manager.GetLocalizedString( 169 ) }									//Preview
	@enumerable static get REFRESH() { return manager.GetLocalizedString( 170 ) }									//Refresh
	@enumerable static get MENU_START() { return manager.GetLocalizedString( 171 ) }								//Menu start

	@enumerable static get TEMPLATE() { return manager.GetLocalizedString( 173 ) }								//Template
	@enumerable static get CREATE_NEW_PLANNERTAB() { return manager.GetLocalizedString( 914 ) }				//Create new PlannerTab
	@enumerable static get ADD_SUB_LEVEL_PLANNER() { return manager.GetLocalizedString( 915 ) }				//Add sub level planner
	@enumerable static get DELETE_PLANNER_LEVEL() { return manager.GetLocalizedString( 916 ) }					//Remove planner level
	@enumerable static get CONTROLS() { return manager.GetLocalizedString( 923 ) }							//Controls
	@enumerable static get REMOVE_CONTROL() { return manager.GetLocalizedString( 924 ) }						//Remove control
	@enumerable static get EDIT_SUB_TABLE() { return manager.GetLocalizedString( 1494 ) }						//Edit the Sub-table view

	//Dashboard
	@enumerable static get LAYOUT() { return manager.GetLocalizedString( 1259 ) }								//Layout
	@enumerable static get ADD_SUBFORM() { return manager.GetLocalizedString( 1260 ) }							//Add subform
	@enumerable static get APPLY() { return manager.GetLocalizedString( 1261 ) }								//Apply

	//Control editor
	@enumerable static get LABEL_POSITION() { return manager.GetLocalizedString( 174 ) }							//Label position
    @enumerable static get CONTROL_DESCRIPTION() { return manager.GetLocalizedString( 175 ) }						//Control description
	@enumerable static get FIELD() { return manager.GetLocalizedString( 176 ) }									//Field
	@enumerable static get ENTITY() { return manager.GetLocalizedString( 177 ) }									//Entity
	@enumerable static get MASK() { return manager.GetLocalizedString( 178 ) }									//Mask
	@enumerable static get SUB_ENTITY() { return manager.GetLocalizedString( 179 ) }								//SubEntity
    @enumerable static get MEMO_DESCRIPTION() { return manager.GetLocalizedString( 646 ) }					//Put description here
    @enumerable static get USE_FIELD_NAME() { return manager.GetLocalizedString(875) }					    //Use field name
	@enumerable static get SUBJECT_ENTITY() { return manager.GetLocalizedString(1475) }					    //Subject entity
	@enumerable static get SUBJECT_FIELD() { return manager.GetLocalizedString(1477) }					    //Subject field
	@enumerable static get SCREEN_SUBJECT() { return manager.GetLocalizedString(1478) }					    //Screen subject
	@enumerable static get TODO_ENTITY() { return manager.GetLocalizedString(1216) }						// Todo entity
	@enumerable static get TODO_TYPE() { return manager.GetLocalizedString(1217) }							// Todo type
	@enumerable static get TODO_FIELD() { return manager.GetLocalizedString(1218) }						// Todo field

    //QueryBuilder
	@enumerable static get SELECT_TABLE_AND_FIELDS() { return manager.GetLocalizedString( 180 ) }				//Select Table & Fields, Sort and Group
	@enumerable static get FILTERS() { return manager.GetLocalizedString( 181 ) }								//Filters
	@enumerable static get COLUMN_ORDER_AND_SETTINGS() { return manager.GetLocalizedString( 182 ) }				//Column Order & Settings
	@enumerable static get QUERY_RESULT() { return manager.GetLocalizedString( 183 ) }							//Query Result
	@enumerable static get FIELDS() { return manager.GetLocalizedString( 184 ) }								//Fields
	@enumerable static get OPTIONS() { return manager.GetLocalizedString( 185 ) }								//Options
	@enumerable static get JOIN_TYPE() { return manager.GetLocalizedString( 186 ) }								//Join type
	@enumerable static get MAIN_OPTIONS() { return manager.GetLocalizedString( 187 ) }							//Main options
	@enumerable static get NORMAL() { return manager.GetLocalizedString( 188 ) }								//Normal
	@enumerable static get EXCLUSIVE() { return manager.GetLocalizedString( 189 ) }								//Exclusive
	@enumerable static get INCLUSIVE() { return manager.GetLocalizedString( 190 ) }								//Inclusive
	@enumerable static get MAIN_ONLY() { return manager.GetLocalizedString( 191 ) }								//Main only
	@enumerable static get EXCLUDE_MAIN() { return manager.GetLocalizedString( 192 ) }							//Exclude Main
	@enumerable static get SELECT_ALL() { return manager.GetLocalizedString( 193 ) }							//Select All
	@enumerable static get AND() { return manager.GetLocalizedString( 194 ) }									//And
	@enumerable static get OR() { return manager.GetLocalizedString( 195 ) }									//Or
	@enumerable static get ADD_RULE() { return manager.GetLocalizedString( 196 ) }								//Add rule
	@enumerable static get ADD_GROUP() { return manager.GetLocalizedString( 197 ) }								//Add group
	@enumerable static get FORMAT() { return manager.GetLocalizedString( 198 ) }								//Format
	@enumerable static get DISPLAY_PRIORITY() { return manager.GetLocalizedString( 199 ) }						//Display priority
	@enumerable static get SUBTOTAL() { return manager.GetLocalizedString( 939 ) }   							//Subtotal
	@enumerable static get AVERAGE() { return manager.GetLocalizedString( 201 ) }								//Average
	@enumerable static get WRAP_FROM_HERE() { return manager.GetLocalizedString( 202 ) }						//Wrap from here
	@enumerable static get CROSS_TABLE() { return manager.GetLocalizedString( 541 ) }							//Cross table
	@enumerable static get SCREEN() { return manager.GetLocalizedString( 666 ) }								//Screen
	@enumerable static get SELECT_CUSTOM_SCREEN() { return manager.GetLocalizedString( 667 ) }					//select the custom screen...
	@enumerable static get CUSTOM_SCREEN() { return manager.GetLocalizedString( 668 ) }							//Custom screen
	@enumerable static get INVERSE() { return manager.GetLocalizedString(714) }									//Custom screen							//Custom screen
	@enumerable static get LINK_DIRECTION() { return manager.GetLocalizedString(1074) }							//Link direction
	@enumerable static get LEFT_TO_RIGHT() { return manager.GetLocalizedString(1075) }							//Left to right
	@enumerable static get RIGHT_TO_LEFT() { return manager.GetLocalizedString(1076) }							//Right to left
	@enumerable static get TEMPLATE_SCREEN() { return manager.GetLocalizedString(1208) }						//Template screen
	@enumerable static get SELECT_TEMPLATE_SCREEN() { return manager.GetLocalizedString(1209) }					//select the template screen...
	@enumerable static get BOTH() { return manager.GetLocalizedString(1238) }							        //Both
	@enumerable static get ARROW() { return manager.GetLocalizedString(1272) }							        //Arrow
	@enumerable static get SELECT_FIRST() { return manager.GetLocalizedString(1509) }							        //Select first
	@enumerable static get RECORDS() { return manager.GetLocalizedString(1510) }							        //records

	//Link List
	@enumerable static get OWNER_IS_PLANNER() { return manager.GetLocalizedString(709) }						//Owner is a planner. Click to disable planning
	@enumerable static get OWNER_IS_NOT_PLANNER() { return manager.GetLocalizedString(710) }					//Owner is not a planner. Click to enable planning

	//Drop
	@enumerable static get DROP_DOCUMENT_HERE() { return manager.GetLocalizedString( 204 ) }						//Drop documents here
	@enumerable static get DROP_EXCEL_DOCUMENT_HERE() { return manager.GetLocalizedString( 1252 ) }				//Drop Excel documents here
	@enumerable static get SELECT_A_TYPE_FOR_ALL_RECORDS() { return manager.GetLocalizedString( 897 ) }			//Select a type for all records
	@enumerable static get HAVE_TO_OPEN_RECORD() { return manager.GetLocalizedString( 1078 ) }			//You have to open record for uploading document
	@enumerable static get ROWS_AFFECTED() { return manager.GetLocalizedString( 1236 ) }						//rows affected
	@enumerable static get MISSING_FIELDS() { return manager.GetLocalizedString( 1237 ) }						//Missing fields
	@enumerable static get CANNOT_UPLOAD_MULTIPLE_FILES() { return manager.GetLocalizedString( 1254 ) }			//Cannot upload multiple files for import
	@enumerable static get MAXIMUM_AMOUNT_OF_LINKED_DOCUMENTS_REACHED() { return manager.GetLocalizedString( 1266 ) } // Maximum amount of linked documents reached
	@enumerable static get MAXIMUM_LINKED_DOCUMENTS_REACHED_ONLY_FILES_WERE_UPLOADED() { return manager.GetLocalizedString( 1267 ) } // Maximum linked documents reached. Only {filesAmount} files were uploaded

	//Image
	@enumerable static get UPLOAD_IMAGE() { return manager.GetLocalizedString( 205 ) }							//Upload image
	@enumerable static get SNAP_PHOTO() { return manager.GetLocalizedString( 1269 ) }								//Snap Photo

	//Canvas Designer

	@enumerable static get DISABLE_ON_DELETE() { return manager.GetLocalizedString( 593 ) }						//Disable on delete
	@enumerable static get IGNORE_MISSING_SUB_KEY() { return manager.GetLocalizedString( 905 ) }					//Ignore missing subkeys

	//Document
	@enumerable static get DOWNLOAD() { return manager.GetLocalizedString( 632 ) }
	@enumerable static get CHECKOUT() { return manager.GetLocalizedString( 633 ) }
	@enumerable static get CHECKIN() { return manager.GetLocalizedString( 634 ) }
	@enumerable static get LOCK() { return manager.GetLocalizedString( 635 ) }
	@enumerable static get UNLOCK() { return manager.GetLocalizedString( 636 ) }
	@enumerable static get ENABLE_VERSION() { return manager.GetLocalizedString( 637 ) }
	@enumerable static get DISABLE_VERSION() { return manager.GetLocalizedString(638) }
	@enumerable static get NO_DATA_AVAILABLE() { return manager.GetLocalizedString(649) }
	@enumerable static get GENERATE_PDF() { return manager.GetLocalizedString(892) }
	@enumerable static get SAVE_PDF() { return manager.GetLocalizedString(1470) }									//Save PDF

	//Field security
	@enumerable static get USER() { return manager.GetLocalizedString( 206 ) }									//User
	@enumerable static get FIELD_COLLECTION() { return manager.GetLocalizedString( 207 ) }						//Field Collection
	@enumerable static get DATA_PROTECTION_OFFICER() { return manager.GetLocalizedString( 208 ) }					//Data Protection Officer
	@enumerable static get VAULT_OWNER() { return manager.GetLocalizedString( 209 ) }								//Vault Owner
	@enumerable static get SAFE_OVNER() { return manager.GetLocalizedString( 210 ) }								//Safe Owner
	@enumerable static get DISCLOSURE_OFFICER() { return manager.GetLocalizedString( 211 ) }						//Disclosure Officer
	@enumerable static get PUBLICATION_OFFICER() { return manager.GetLocalizedString( 212 ) }						//Publication Officer
	@enumerable static get DEPUTY() { return manager.GetLocalizedString( 213 ) }									//Deputy
	@enumerable static get COLLECTION_NAME() { return manager.GetLocalizedString( 912 ) }							//Collection name

	//Field collection designer
	@enumerable static get UPDATE_VIEWS() { return manager.GetLocalizedString( 216 ) }							//Update views
	@enumerable static get DATA_ROLE() { return manager.GetLocalizedString( 217 ) }								//DataRole
	@enumerable static get TYPE_OF_TYPE() { return manager.GetLocalizedString( 220 ) }							//TypeOfType

	//Formats
	@enumerable static get DUTCH_POSTAL_CODE_MUST_BE_FULFILLED() { return manager.GetLocalizedString(930) }	// Dutch postal code must be fulfilled completely

	//Select user
	@enumerable static get NEW_SELECTION() { return manager.GetLocalizedString( 221 ) }							//New selection
	@enumerable static get REMEMBER_ME() { return manager.GetLocalizedString( 222 ) }							//Remember me
	@enumerable static get SELECT_USER() { return manager.GetLocalizedString(627) }
	@enumerable static get NONE() { return manager.GetLocalizedString(660) }									//None

	//Tag
	@enumerable static get NO_AVAILABLE_TAGS() { return manager.GetLocalizedString( 224 ) }						//No available tags
	@enumerable static get SELECT_TAG() { return manager.GetLocalizedString( 225 ) }								//Select a tag
	@enumerable static get BOOK() { return manager.GetLocalizedString( 473 ) }									//Book
	@enumerable static get NO_TAGS_SELECTED() { return manager.GetLocalizedString( 474 ) }						//No tags selected
	@enumerable static get NO_TAGS_AVAILABLE_FOR_ENTITY() { return manager.GetLocalizedString( 475 ) }			//No tags available for this entity

	//TemplateDesigner
	@enumerable static get OPEN_IN_DESKTOP() { return manager.GetLocalizedString( 226 ) }							//Open in Desktop
	@enumerable static get QUERY() { return manager.GetLocalizedString( 227 ) }									//Query
	@enumerable static get TEMPLATES() { return manager.GetLocalizedString( 1124 ) }							//Templates
	@enumerable static get CREATE_TEMPLATE_IN() { return manager.GetLocalizedString( 1125 ) }					//Create a template in
	@enumerable static get UPDATE_TEMPLATE_IN() { return manager.GetLocalizedString( 1126 ) }					//Update the template in

	//TimeWritting
	@enumerable static get TO() { return manager.GetLocalizedString( 229 ) }										//To
	@enumerable static get TIME() { return manager.GetLocalizedString( 232 ) }									//Time
	@enumerable static get OVW() { return manager.GetLocalizedString( 233 ) }										//Ovw
	@enumerable static get PAY() { return manager.GetLocalizedString( 234 ) }										//Pay
	@enumerable static get HOUR() { return manager.GetLocalizedString( 610 ) }									//Hour
	@enumerable static get ADD_LINE() { return manager.GetLocalizedString( 235 ) }								//Add line
	@enumerable static get WEEK() { return manager.GetLocalizedString( 237 ) }									//Week
	@enumerable static get BOOKED() { return manager.GetLocalizedString( 238 ) }									//Booked
	@enumerable static get SALDO() { return manager.GetLocalizedString( 239 ) }									//Saldo
	@enumerable static get YEAR() { return manager.GetLocalizedString( 240 ) }									//Year
	@enumerable static get CURRENT_YEAR() { return manager.GetLocalizedString( 241 ) }							//Current year
	@enumerable static get NORM_40() { return manager.GetLocalizedString( 242 ) }									//Norm 40
	@enumerable static get FROM_40() { return manager.GetLocalizedString( 243 ) }									//from 40
	@enumerable static get SELECT__RESERVATION_SUBJECT() { return manager.GetLocalizedString( 560 ) }				//Select reservation subject
	@enumerable static get SPECIFY_RESERVATION_DESCRIPTION() { return manager.GetLocalizedString( 561 ) }			//Specify reservation description
	@enumerable static get DESCRIPTION_MIN_LENGTH() { return manager.GetLocalizedString( 562 ) }					//description min length is 3
	@enumerable static get DESCRIPTION_MAX_LENGTH() { return manager.GetLocalizedString( 563 ) }					//description max length is 80
	@enumerable static get YEARVIEW() { return manager.GetLocalizedString( 623 ) }								//Yearview
	@enumerable static get DISSAPROVED() { return manager.GetLocalizedString( 624 ) }								//DISSAPROVED
	@enumerable static get YEAR_OVERVIEW() { return manager.GetLocalizedString( 625 ) }							//Year overview
	@enumerable static get NORM_40_HOURS() { return manager.GetLocalizedString( 626 ) }							//Norm 40 HOURS

    @enumerable static get COMMENT() { return manager.GetLocalizedString( 650 ) }							    //Comment
    @enumerable static get DISAP_REASON() { return manager.GetLocalizedString( 651 ) }							//Disapprove reason
    @enumerable static get DISAP_OVERVIEW() { return manager.GetLocalizedString( 652 ) }						//Disapproved booked hours overview
    @enumerable static get BOOKED_COUNTER() { return manager.GetLocalizedString( 653 ) }						//Booked counter
    @enumerable static get WEEK_DISAP_RECORDS() { return manager.GetLocalizedString( 654 ) }					//This week has disapproved booked time record(s)
    @enumerable static get BACK() { return manager.GetLocalizedString( 655 ) }							        //Back
    @enumerable static get RECOLRD_IS_DISAP() { return manager.GetLocalizedString( 656 ) }						//This booked time record is disapproved
    @enumerable static get DISAPPROVED() { return manager.GetLocalizedString( 657 ) }							//Disapproved
    @enumerable static get REASON() { return manager.GetLocalizedString( 658 ) }							//Reason
    @enumerable static get DURATION() { return manager.GetLocalizedString( 659 ) }							//Duration
	@enumerable static get WRITING_TIME_FOR_FUTURE_NOT_ALLOWED() { return manager.GetLocalizedString( 760 ) }	//Writing time for future days is not allowed

	//FlowMemo
	@enumerable static get CLICK_TO_ADD_A_NEW_MEMO() { return manager.GetLocalizedString( 1314 ) }	//Click to add a new memo


	//Portlets

    @enumerable static get MAIN_PORTLET() { return manager.GetLocalizedString(592) }							//Main portlet

    //message for mathing
    @enumerable static get NOT_AVAILABLE_CURRENT_ENTITY() { return manager.GetLocalizedString(647) }
    @enumerable static get NOT_AVAILABLE_LINKED_ENTITY() { return manager.GetLocalizedString(648) }


	//Agenda
	@enumerable static get ADD_TODO() { return manager.GetLocalizedString( 244 ) }								//Add ToDo
	@enumerable static get REMOVE_AFTER_DROP() { return manager.GetLocalizedString( 245 ) }						//remove after drop
	@enumerable static get TODO() { return manager.GetLocalizedString( 246 ) }									//ToDo
	@enumerable static get AGENDA_TODAY() { return manager.GetLocalizedString( 611 ) }							//TODAY
	@enumerable static get AGENDA_MONTH() { return manager.GetLocalizedString( 612 ) }							//MONTH
	@enumerable static get AGENDA_WORK_WEEK() { return manager.GetLocalizedString( 614 ) }						//WORK_WEEK
	@enumerable static get AGENDA_DAY() { return manager.GetLocalizedString( 615 ) } 							//DAY
    @enumerable static get AGENDA_WEEK() { return manager.GetLocalizedString(237) }							    //WEEk
	@enumerable static get AGENDA_LIST() { return manager.GetLocalizedString( 1506 ) }							//LIST
	@enumerable static get AGENDA_USERS() { return manager.GetLocalizedString( 1511 ) }							//USERS
	@enumerable static get AGENDA_TIMELINE_WEEK() { return manager.GetLocalizedString( 1516 ) }					//TIMELINE WEEK

	@enumerable static get SPECIAL_DAY() { return manager.GetLocalizedString( 113 ) }						    //Special day
    @enumerable static get REQUEST_LEAVE() { return manager.GetLocalizedString(968) }					 		//Request leave
	@enumerable static get REPORT_ILLNESS() { return manager.GetLocalizedString( 967 ) }					 	//Report illness
	@enumerable static get RECURRENT() { return manager.GetLocalizedString( 983 ) }					 		    //Recurrent
	@enumerable static get CLICK_TO_RESCHEDULE() { return manager.GetLocalizedString(881)}                      //Click to reschedule the appointment
	@enumerable static get STARTING_DATE_IS_OUT_OF_FREEZE_TIME() {return manager.GetLocalizedString(885)} //Starting date is out of freeze time
	@enumerable static get BOOK_TIME() { return manager.GetLocalizedString( 896 ) }						//Book time
	@enumerable static get CANNOT_ACCEPT_APPOINTMENT() { return manager.GetLocalizedString( 929 ) }			//Cannot book time
	@enumerable static get RECURRENCE_PATTERN() { return manager.GetLocalizedString( 987 ) }				//Recurrence pattern
	@enumerable static get STARTING_DATE() { return manager.GetLocalizedString( 945 ) }						//Starting date
	@enumerable static get MONTHS() { return manager.GetLocalizedString( 946 ) }								//Months
	@enumerable static get WEEKS() { return manager.GetLocalizedString( 950 ) }								//Weeks
	@enumerable static get WEEK_PLANNING() { return manager.GetLocalizedString( 947 ) }						//Week planning
	@enumerable static get ATTENDEES() { return manager.GetLocalizedString( 1195 ) }						//Attendees
	@enumerable static get AGENDA_NO_EVENTS_TO_DISPLAY() { return manager.GetLocalizedString( 1507 ) }			//No events to display

	//Mail
	@enumerable static get SEND_EMAIL() { return manager.GetLocalizedString( 247 ) }								//Send e-mail
	@enumerable static get ACTIONS() { return manager.GetLocalizedString( 248 ) }									//Actions
	@enumerable static get MARK_AS_READ() { return manager.GetLocalizedString( 249 ) }							//Mark as Read
	@enumerable static get MARK_AS_UNREAD() { return manager.GetLocalizedString( 250 ) }							//Mark as Unread
	@enumerable static get ADDRESS() { return manager.GetLocalizedString( 251 ) }									//Address
	@enumerable static get TOPIC() { return manager.GetLocalizedString( 252 ) }									//Topic
	@enumerable static get DATE() { return manager.GetLocalizedString( 253 ) }									//Date
	@enumerable static get OF() { return manager.GetLocalizedString( 254 ) }										//of
	@enumerable static get ADD_FROM() { return manager.GetLocalizedString( 1333 ) }								//Add from

	//Documents
	@enumerable static get OPEN_DOCUMENT_IN_SPHEERES() { return manager.GetLocalizedString(926) }					//Opening in the Spheeres client...


	//My settings
	@enumerable static get PROFILE() { return manager.GetLocalizedString( 255 ) }									//Profile
	@enumerable static get THEMES() { return manager.GetLocalizedString( 256 ) }									//Themes
	@enumerable static get PERSONAL_SETTINGS() { return manager.GetLocalizedString( 257 ) }						//Personal settings
	@enumerable static get NETWORK() { return manager.GetLocalizedString( 1298 ) }								//Network
	@enumerable static get LICENSE_HOLDER() { return manager.GetLocalizedString( 258 ) }							//License Holder

	//Profile
	@enumerable static get CURRENT_PASSWORD() { return manager.GetLocalizedString( 259, 'Current password' ) }						//Current Password
	@enumerable static get NEW_PASSWORD() { return manager.GetLocalizedString( 260, 'New password' ) }	//New Password
	@enumerable static get RETYPE_NEW_PASSWORD() { return manager.GetLocalizedString( 261 ) }						//Re-type New Password

	//Network
	@enumerable static get THERE_ARE_NO_REMOTE_DATABASE_CONNECTIONS() { return manager.GetLocalizedString( 1299 ) }					//There are no remote database connections

	//License holder
	@enumerable static get LICENSE_INFO() { return manager.GetLocalizedString( 262 ) }							//License Info
	@enumerable static get LICENSE_EXPIRATION_DATE() { return manager.GetLocalizedString( 264 ) }					//Expiration Date
	@enumerable static get MAXIMUM_USERS_ALLOWED() { return manager.GetLocalizedString( 265 ) }					//Maximum users allowed
	@enumerable static get MAXIMUM_CONNECT_USERS_ALLOWED() { return manager.GetLocalizedString(  1290) }			//Maximum connect users allowed
	@enumerable static get MAXIMUM_REMOTE_USERS_ALLOWED() { return manager.GetLocalizedString(  1291) }			//Maximum remote users allowed
	@enumerable static get MAXIMUM_LIMITED1_USERS_ALLOWED() { return manager.GetLocalizedString(  1292) }			//Maximum limited1 users allowed
	@enumerable static get MAXIMUM_LIMITED2_USERS_ALLOWED() { return manager.GetLocalizedString(  1293) }			//Maximum limited2 users allowed
	@enumerable static get MAXIMUM_LIMITED3_USERS_ALLOWED() { return manager.GetLocalizedString(  1294) }			//Maximum limited3 users allowed
	@enumerable static get MAXIMUM_PORTAL_USERS_ALLOWED() { return manager.GetLocalizedString( 1107 ) }			//Maximum portal users allowed
	@enumerable static get MAXIMUM_GDPR_USERS_ALLOWED() { return manager.GetLocalizedString( 1112 ) }				//Maximum GDPR users allowed
	@enumerable static get MAXIMUM_ROLE() { return manager.GetLocalizedString( 266 ) }							//Maximum role

	//Themes
	@enumerable static get REFRESH_PAGE_ALERT() { return manager.GetLocalizedString( 267 ) }						//If you want to be sure your changes applied , please refresh the page!
	@enumerable static get PREFF_F5_BUTTON_ALERT() { return manager.GetLocalizedString( 268 ) }					//Press refresh button in browser or "F5" button on the keyboard
	@enumerable static get BASIC_THEME_OPTIONS() { return manager.GetLocalizedString( 269 ) }						//BASIC THEME OPTIONS
	@enumerable static get THEME_STYLE() { return manager.GetLocalizedString( 270 ) }								//Theme Style
	@enumerable static get BORDERS_THEME() { return manager.GetLocalizedString( 271 ) }							//Borders Theme
	@enumerable static get SIDEBAR_POSITION() { return manager.GetLocalizedString( 272 ) }						//Sidebar Position
	@enumerable static get SIDEBAR_STYLE() { return manager.GetLocalizedString( 273 ) }							//Sidebar Style
	@enumerable static get SIDEBAR_MODE() { return manager.GetLocalizedString( 274 ) }							//Sidebar Mode
	@enumerable static get BASIS_THEME_COLOR() { return manager.GetLocalizedString( 275 ) }						//BASIS THEME COLOR
	@enumerable static get CUSTOM_THEMES() { return manager.GetLocalizedString( 276 ) }							//CUSTOM THEMES
	@enumerable static get SHOW_PREVIEW() { return manager.GetLocalizedString( 277 ) }							//Show preview

	@enumerable static get BACKGROUND_COLOR_FOR_SIDEBAR() { return manager.GetLocalizedString( 279 ) }								//Background color for sidebar
	@enumerable static get HEADER_BACKGROUND_COLOR() { return manager.GetLocalizedString( 280 ) }										//Header background color
	@enumerable static get BACKGROUND_COLOR_FOR_HEADER_TEXT() { return manager.GetLocalizedString( 281 ) }							//Background color for header text
	@enumerable static get BACKGROUND_COLOR_FOR_HOVERED_MENU_ITEM() { return manager.GetLocalizedString( 282 ) }						//Background color for hovered menu item
	@enumerable static get BACKGROUND_COLOR_FOR_HOVERED_MENU_ITEM_SECOND_LEVEL_ITEM() { return manager.GetLocalizedString( 283 ) }	//Background color for hovered menu second level item
	@enumerable static get BACKGROUND_COLOR_FOR_THEME_SQUARE() { return manager.GetLocalizedString( 284 ) }							//Background color for theme square

	@enumerable static get COLOR_FOR_HEADER_TEXT() { return manager.GetLocalizedString( 285 ) }										//Color for header text
	@enumerable static get COLOR_FOR_BORDERS_BETWEEN_MENU_LINKS() { return manager.GetLocalizedString( 286 ) }						//Color for borders between menu links
	@enumerable static get TEXT_COLOR_FOR_SIDEBAR_MENU() { return manager.GetLocalizedString( 287 ) }									//Text color for sidebar menu
	@enumerable static get ACTIVE_LINK_COLOR() { return manager.GetLocalizedString( 288 ) }											//Active link color
	@enumerable static get MENU_2_LEVEL_ACTIVE_LINK_COLOR() { return manager.GetLocalizedString( 289 ) }								//Menu 2 level active link color
	@enumerable static get CONTROLS_TEXT_COLOR() { return manager.GetLocalizedString( 290 ) }											//Controls text color
	@enumerable static get CONTROLS_BACKGROUND_COLOR() { return manager.GetLocalizedString( 291 ) }									//Controls background color
	@enumerable static get CONTROLS_BACKGROUND_BORDER_COLOR() { return manager.GetLocalizedString( 292 ) }							//Controls background border color

	@enumerable static get TABLE_HEADER_TEXT_COLOR() { return manager.GetLocalizedString( 293 ) }										//Table header text color
	@enumerable static get TABLE_HEADER_BACKGROUND_COLOR() { return manager.GetLocalizedString( 294 ) }								//Table header background color
	@enumerable static get TAB_BORDER_COLOR() { return manager.GetLocalizedString( 295 ) }											//Tab border color
	@enumerable static get ACTIVE_TAB_LEVEL_BACKGROUND_COLOR() { return manager.GetLocalizedString( 296 ) }							//Active tab label background color
	@enumerable static get ACTIVE_TAB_LEVEL_TEXT_COLOR() { return manager.GetLocalizedString( 297 ) }									//Active tab label text color

	@enumerable static get SQUARE_CORNERS() { return manager.GetLocalizedString( 298 ) }												//Square corners
	@enumerable static get ROUNDER_CORNERS() { return manager.GetLocalizedString( 299 ) }												//Rounded corners

	@enumerable static get WITH_BORDERS() { return manager.GetLocalizedString( 300 ) }												//With borders
	@enumerable static get WITHOUT_BORDERS() { return manager.GetLocalizedString( 301 ) }												//Without borders

	@enumerable static get LEFT() { return manager.GetLocalizedString( 302 ) }														//Left
	@enumerable static get RIGHT() { return manager.GetLocalizedString( 303 ) }														//Right

	@enumerable static get LIGHT() { return manager.GetLocalizedString( 305 ) }														//Light

	@enumerable static get FIXED() { return manager.GetLocalizedString( 306 ) }														//Fixed

	//Color selector
	@enumerable static get NO_COLOR() { return manager.GetLocalizedString( 308 ) }													//no-color

	//Database designer
	@enumerable static get SHOW_DELETED() { return manager.GetLocalizedString( 309 ) }												//Show deleted
	@enumerable static get UPDATE_DATABASE() { return manager.GetLocalizedString( 310 ) }												//Update Database
	@enumerable static get LANGUAGES() { return manager.GetLocalizedString( 311 ) }													//Languages
	@enumerable static get UPDATE_DATABASE_ALERT() { return manager.GetLocalizedString( 312 ) }										//You need to update database.
	@enumerable static get ENABLED() { return manager.GetLocalizedString( 907 ) }														//Enabled
	@enumerable static get KIND_NAME() { return manager.GetLocalizedString( 908 ) }													//Kind Name
	@enumerable static get SHOW_MODE() { return manager.GetLocalizedString( 910 ) }													//Show Mode
	@enumerable static get KINDS() { return manager.GetLocalizedString( 911 ) }														//Kinds
	@enumerable static get TYPE_SECURITY() { return 'Business Roles\'s Type Security' }																		//Type Security
	@enumerable static get ACTIONS_CHECK_LIST() { return manager.GetLocalizedString( 918 ) }											//Checklist
	@enumerable static get ALLOW_CUSTOM_VALUE() { return manager.GetLocalizedString( 927 ) }											//Allow custom value
	@enumerable static get FILTER_SUBTABLE() { return manager.GetLocalizedString( 1201 ) }											//Filter Sub Table
	@enumerable static get FILTER_BY_K_SEQ() { return manager.GetLocalizedString( 1263 ) }											//Filter by K_SEQ
	@enumerable static get ONE_PARENT() { return manager.GetLocalizedString( 1214 ) }												//One parent


	//Clean Database Modal
	@enumerable static get CLEAN_DATABASE() { return manager.GetLocalizedString( 514 ) }												//Clean Database.
	@enumerable static get USER_TABLES() { return manager.GetLocalizedString(761)}													//User tables
	@enumerable static get SYS_TABLES() { return manager.GetLocalizedString(762)}														//SYS tables
	@enumerable static get REMOVE_EXAMPLES() { return manager.GetLocalizedString(763)}												//Remove examples
	@enumerable static get DELETED_FIELDS() { return manager.GetLocalizedString(1323)}												//Deleted fields
	@enumerable static get DELETED_TABLES() { return manager.GetLocalizedString(1324)}												//Deleted tables

	@enumerable static get SUB() { return manager.GetLocalizedString( 314 ) }															//SUB
	@enumerable static get SCREENS() { return manager.GetLocalizedString( 315 ) }														//SCREENS

	@enumerable static get TABLE_NAME() { return manager.GetLocalizedString( 316 ) }													//Table name
	@enumerable static get SUBJECT_AREA() { return manager.GetLocalizedString( 317 ) }												//Subject area
	@enumerable static get DISABLE() { return manager.GetLocalizedString( 318 ) }														//Disable

	@enumerable static get FIELD_NAME() { return manager.GetLocalizedString( 319 ) }													//Field Name
	@enumerable static get FIELD_TYPE() { return manager.GetLocalizedString( 320 ) }													//Field Type
	@enumerable static get SIZE() { return manager.GetLocalizedString( 321 ) }														//Size
	@enumerable static get LOOKUP() { return manager.GetLocalizedString( 322 ) }														//Lookup
	@enumerable static get SORT() { return manager.GetLocalizedString( 323 ) }														//Sort
	@enumerable static get PROPERTIES() { return manager.GetLocalizedString( 1006 ) }													//Properties
	@enumerable static get FIELD_FLAGS() { return manager.GetLocalizedString( 1007 ) }													//Field Flags

	@enumerable static get FLAGS() { return manager.GetLocalizedString( 324 ) }														//Flags
	@enumerable static get VALIDATE() { return manager.GetLocalizedString( 325 ) }													//Validate
	@enumerable static get VIRTUAL() { return manager.GetLocalizedString( 326 ) }														//Virtual
	@enumerable static get ALIAS_DEFINITION() { return manager.GetLocalizedString( 327 ) }											//Alias definition

	@enumerable static get DELETED() { return manager.GetLocalizedString( 1014 ) } 														//Deleted

	@enumerable static get FIELD_SIZE() { return manager.GetLocalizedString( 1010 ) }													//Field size
	@enumerable static get DEFAULT_VALUE() { return manager.GetLocalizedString( 1011 ) }												//Default value

	@enumerable static get SYSTEM_FLAG() { return manager.GetLocalizedString( 382 ) }													//System
	@enumerable static get HIDDEN_FLAG() { return manager.GetLocalizedString( 329 ) }													//Hidden
	@enumerable static get REQUIRED_FLAG() { return manager.GetLocalizedString( 330 ) }												//Required
	@enumerable static get READ_ONLY_FLAG() { return manager.GetLocalizedString( 331 ) }												//Read only
	@enumerable static get SEARCH_FLAG() { return manager.GetLocalizedString( 332 ) }													//Search field
	@enumerable static get TRANSLATE_FLAG() { return manager.GetLocalizedString( 334 ) }												//Translate
    @enumerable static get UNIQUE_FLAG() { return manager.GetLocalizedString(1243) }												        //Unique

    @enumerable static get MATCHING() { return manager.GetLocalizedString( 1012 ) }														//Matching
	@enumerable static get CUSTOM_VALUES_ALLOWED() { return manager.GetLocalizedString( 1013 ) }										//Custom values allowed

	@enumerable static get TABLE() { return manager.GetLocalizedString( 336 ) }														//Table
	@enumerable static get MERGE_VIEW() { return manager.GetLocalizedString( 1502 ) }														//Merge view
	@enumerable static get SERACH_SCREEN_IF_ALERT() { return manager.GetLocalizedString( 337 ) }										//Validate
	@enumerable static get USER_CALCULATED_EXPRESSION() { return manager.GetLocalizedString( 338 ) }									//Use Calculated Expression
	@enumerable static get DEPENDS_ON() { return manager.GetLocalizedString( 339 ) }													//Depends On
	@enumerable static get FILL_FROM() { return manager.GetLocalizedString( 340 ) }													//Fill from

	@enumerable static get NOT_SET() { return manager.GetLocalizedString( 1005 ) }														//Not set

	@enumerable static get STATUS_DESIGNER() { return manager.GetLocalizedString( 341 ) }												//Status designer
	@enumerable static get ADD_NODE() { return manager.GetLocalizedString( 342 ) }													//Add node
	@enumerable static get GENERAL() { return manager.GetLocalizedString( 343 ) }														//General

	@enumerable static get KIND() { return manager.GetLocalizedString( 344 ) }														//Kind
	@enumerable static get ICON() { return manager.GetLocalizedString( 345 ) }														//Icon
	@enumerable static get TABLE_DEFAULT() { return manager.GetLocalizedString( 346 ) }												//Table default
    @enumerable static get FLOW_FOLDER() { return manager.GetLocalizedString(1189) }												//Flow folder
	@enumerable static get NEXT_STATUS() { return manager.GetLocalizedString(1354) }												//Next status

    @enumerable static get CD_FIELD() { return manager.GetLocalizedString( 458 ) }													//CD Field
	@enumerable static get USER_DB_FIELD() { return manager.GetLocalizedString( 459 ) }												//User DB field
	@enumerable static get TABLES() { return manager.GetLocalizedString( 460 ) }														//Tables
	@enumerable static get CLICK_TO_EXPAND_COLLAPSE() { return manager.GetLocalizedString( 461 ) }									//click to expand/collapse
	@enumerable static get COLUMNS() { return manager.GetLocalizedString( 462 ) }														//Columns
	@enumerable static get PRIMARY_KEY_ON_COLUMNS() { return manager.GetLocalizedString( 463 ) }										//Primary KEY on columns
	@enumerable static get INDEX_COLUMN() { return manager.GetLocalizedString( 464 ) }												//Index column
	@enumerable static get COLUMN() { return manager.GetLocalizedString( 465 ) }														//Column
	@enumerable static get UPDATE() { return manager.GetLocalizedString( 466 ) }														//Update
	@enumerable static get SEQUENCE_NAME() { return manager.GetLocalizedString( 511 ) }												//Sequence name
	@enumerable static get UNIQIUE_SEQUENCE_NAME() { return manager.GetLocalizedString( 512 ) }										//Make sure sequence name is unqiue
	@enumerable static get DOWNLOAD_METADATA() { return manager.GetLocalizedString( 515 ) }											//Download metadata
	@enumerable static get CLICK_TO_DOWNLOAD_METADATA() { return manager.GetLocalizedString( 516 ) }									//Click to download xsd file with database structure for Crystal Report
	@enumerable static get VIEW_EDITOR() { return manager.GetLocalizedString( 621 ) }													//View Editor
	@enumerable static get VIEW() { return manager.GetLocalizedString( 622 ) }														//View
	@enumerable static get DYNAMIC_VIEW() { return manager.GetLocalizedString( 970 ) }												//Dynamic view
	@enumerable static get RECIPE_VERSION() { return manager.GetLocalizedString( 1322 ) }												//Recipe Version
	@enumerable static get RECORD_ID() { return manager.GetLocalizedString( 672 ) }													//Record Id
	@enumerable static get VALIDATION() { return manager.GetLocalizedString( 906 ) }													//Validation
	@enumerable static get CANNOT_MAKE_AN_ACTIVE_VERSION_INACTIVE() { return manager.GetLocalizedString( 1337 ) }						//Cannot make an active version inactive


	@enumerable static get CLICK_TO_SET_SEQUENCE_VALUE() { return manager.GetLocalizedString( 886 ) }									//Click to set sequence value


	//Table Editor
	@enumerable static get ADD_RELATION_TYPE_TOOLTIP() { return manager.GetLocalizedString( 606 ) }											//Add relation types

	//Tab
	@enumerable static get SHOW_ALL() { return manager.GetLocalizedString( 448 ) }													//Show all
	@enumerable static get HIDE() { return manager.GetLocalizedString( 449 ) }														//Hide
	@enumerable static get NEW_TAB() { return manager.GetLocalizedString( 664 ) }														//New Tab

    //Portlet
	@enumerable static get EDIT_PORTLETSPACE() { return manager.GetLocalizedString( 450 ) }											//Edit PortletSpace

	//multiple portlets spaces
	@enumerable static get MORE() { return manager.GetLocalizedString( 548 ) }														//More dropdown
	@enumerable static get PORTLET_SPACE_INFO() { return manager.GetLocalizedString( 550 ) }											//Portlet space info
	@enumerable static get PORTLET_SPACE_NAME() { return manager.GetLocalizedString( 551 ) }											//Portlet space name
	@enumerable static get USE_AS_MAIN() { return manager.GetLocalizedString( 552 ) }													//Use as main

	@enumerable static get CREATE_NEW() { return manager.GetLocalizedString( 555 ) }													//Create new {entityName}
	@enumerable static get EDIT_BUTTON() { return manager.GetLocalizedString( 556 ) }													//Edit button
	@enumerable static get PLEASE_ENTER_PORTLET_SPACE_NAME() { return manager.GetLocalizedString( 557 ) }								//Please, enter a portlet space name
	@enumerable static get PORTLET_SPACE_WILL_BE_DELETED() { return manager.GetLocalizedString( 559 ) }								//Portlet space will be deleted
	@enumerable static get CONFIRMATION_FOR_UNSAVED_CHANGES() { return manager.GetLocalizedString( 567 ) }							//All unsaved changes will be lost. Save changes?
	@enumerable static get PORTLET_SPACE_WAS_UPDATED() { return manager.GetLocalizedString( 568 ) }									//Portlet Space was updated
	@enumerable static get SELECT_EXISTING_PORTLET_SPACE() { return manager.GetLocalizedString( 1512 ) }								//Select existing portlet space
	@enumerable static get EXPAND() { return manager.GetLocalizedString( 639 ) }
	@enumerable static get COLLAPSE() { return manager.GetLocalizedString( 640 ) }
	@enumerable static get PORTLET_SETTINGS() { return manager.GetLocalizedString( 641 ) }
	@enumerable static get MINIMIZE() { return manager.GetLocalizedString( 643 ) }
	@enumerable static get MAXIMIZE() { return manager.GetLocalizedString( 644 ) }

	//Buttons
	@enumerable static get HISTORY() { return manager.GetLocalizedString( 628 ) }
	@enumerable static get RESET_SECURITY() { return manager.GetLocalizedString( 629 ) }
	@enumerable static get SPECIAL() { return manager.GetLocalizedString( 630 ) }
	@enumerable static get ADD_TO_FAVORITES() { return manager.GetLocalizedString( 631 ) }
	@enumerable static get COPY_WITH_RELATIONS() { return manager.GetLocalizedString(715) }												//Copy with relations
	@enumerable static get CONSULT() { return manager.GetLocalizedString( 969 ) }
	@enumerable static get DASHBOARD() { return manager.GetLocalizedString( 1255 ) }
	@enumerable static get CTRL_S_SAVE() { return manager.GetLocalizedString( 1262 ) }												//Click or press Ctrl + S to Save the changes

	//Icon manager
	@enumerable static get SELECT_ICON() { return manager.GetLocalizedString( 451 ) }													//Select icon

	//Make example
	@enumerable static get MAKE_EXAMPLE() { return manager.GetLocalizedString( 537 ) }									//Make example

	//Recent And Favorites panel
	@enumerable static get NO_RECORDS_MESSAGE() { return manager.GetLocalizedString( 543 ) }							//There is no records yet
	@enumerable static get NO_NAME_RECORD() { return manager.GetLocalizedString( 544 ) }								//no name record

	@enumerable static get PARENT() { return manager.GetLocalizedString( 545 ) }					//Child
	@enumerable static get CHILD() { return manager.GetLocalizedString( 546 ) }					//Parent
	@enumerable static get ITSELF() { return manager.GetLocalizedString( 547 ) }					//Itself

	//Help pages
	@enumerable static get DOCUMENTATION() { return manager.GetLocalizedString( 595 ) }								//Documentation
	@enumerable static get THERE_IS_NO_DESCRIPTION_AVAILABLE() { return manager.GetLocalizedString( 596 ) }			//There is no description available
	@enumerable static get CONTROL() { return manager.GetLocalizedString( 1008 ) }									//Control
	@enumerable static get DASH() { return manager.GetLocalizedString( 1015 ) }										//Dash
    @enumerable static get STATUS_FLOW() { return manager.GetLocalizedString( 1063 ) }								//Status Flow
    @enumerable static get SETTING_THE_STATUS_RUNS() { return manager.GetLocalizedString( 1066 ) }					//Setting the status runs the DataWizard
    @enumerable static get FOLLOW_UP_DOES_NOT_CREATE_AN_ACTION() { return manager.GetLocalizedString( 1067 ) }		//FollowUp does not create an action
    @enumerable static get DURATION_IS_SET() { return manager.GetLocalizedString( 1068 ) }							//Duration of the related action is automatically set to
    @enumerable static get DEADLINE_IS_SET() { return manager.GetLocalizedString( 1069 ) }							//Deadline of the related action is automatically set to
	@enumerable static get BUSINESS_ROLES() { return manager.GetLocalizedString(1070) }								//Business roles
	@enumerable static get FUNCTIONS() { return manager.GetLocalizedString(1175) }									//Functions

	//Notifications
	@enumerable static get NOTIFIER_SUCCESS() { return manager.GetLocalizedString( 10 ) };						//Success
	@enumerable static get NOTIFIER_WARNING() { return manager.GetLocalizedString( 47 ) };                   //Attention
	@enumerable static get NOTIFIER_FAIL() { return manager.GetLocalizedString( 65 ) };						//Oops

	//BulkEmail
	@enumerable static get BULK_EMAIL() { return manager.GetLocalizedString( 263 ) };							//Bulk Email
	@enumerable static get RECIPIENTS() { return manager.GetLocalizedString(333) };								//Recipients
	@enumerable static get ADDRESS_FIELD() { return manager.GetLocalizedString(335) };							//Address field
	@enumerable static get MAILING_TEMPLATE() { return manager.GetLocalizedString(350) };						//Mailing template
	@enumerable static get REQUEST_TEMPLATE() { return manager.GetLocalizedString(671) };						//Request template
	@enumerable static get CONFIGURE() { return manager.GetLocalizedString(351) };								//Configure
	@enumerable static get START() { return manager.GetLocalizedString(669) };									//Start
	@enumerable static get STOP() { return manager.GetLocalizedString(670) };									//Stop

	//FollowUp
	@enumerable static get FOLLOW_UP() { return manager.GetLocalizedString(1268) }											//Follow Up
	@enumerable static get EXIST_ACTIVE_ACTIONS() { return manager.GetLocalizedString(663) }			                    //There are other active actions. Could not proceed to the next lifestatus.
    @enumerable static get RECORD_IS_DISABLED() { return manager.GetLocalizedString(1204) }			                        //Record is disabled.
    @enumerable static get ACTIVE_ACTIONS_NOT_FOUND() { return manager.GetLocalizedString(1205) }			                //Active ACTIONS record not found.
    
    //Actiion check list
	@enumerable static get THERE_ARE_NO_NEXT_STATUSES_WITH_CHECK_LIST() {return manager.GetLocalizedString(919)};
	@enumerable static get CHECKLIST_UPDATED() {return manager.GetLocalizedString(920)};

	//Scheduler
    @enumerable static get SCHEDULER_FREE_CAPACITY() { return manager.GetLocalizedString( 674 ) };                            //Free capacity
    @enumerable static get SCHEDULER_COMMON_FREE_TIME() { return manager.GetLocalizedString( 675 ) };                         //Common free time
	@enumerable static get PERIOD() { return manager.GetLocalizedString( 765 ) };												//Period
	@enumerable static get STARTING() { return manager.GetLocalizedString( 766 ) };											//Starting
    @enumerable static get AGENDA_IS_NOT_CONNECTED() { return manager.GetLocalizedString( 676 ) };                            //AGENDA is not connected to this table
	@enumerable static get AGENDA_IS_NOT_AVAILABLE() { return manager.GetLocalizedString(677) };                            //AGENDA is not available due to security settings
	@enumerable static get SCHEDULER_NOT_FOUND() { return manager.GetLocalizedString(853) };                                  //Scheduler not found
	@enumerable static get RE_PLANNING() { return manager.GetLocalizedString(854) };                                  //Re-planning
	@enumerable static get RE_PLAN_APPOINTMENT_TO_PERIOD() { return manager.GetLocalizedString(880) };                        //Click to schedule the appointment for this period
	@enumerable static get NUMBER_WEEKS() { return manager.GetLocalizedString(899)};											//number Weeks "W"
	@enumerable static get SUBJECT_TOOLTIP() { return manager.GetLocalizedString(1056)};										//Set the number of periods to 2 to show linked {entityname} records

	@enumerable static get NO_POSSIBLE_TO_CREATE_APPOINTMENT_IN_THE_PAST() {return manager.GetLocalizedString(681)};		//No possible to create an appointment in the past

	//Invoicing
	@enumerable static get PLEASE_SELECT_DATA_SOURCE() { return manager.GetLocalizedString(682) };							// Please, select data source
	@enumerable static get PLEASE_SELECT_WAITING_FOR_INVOICE_LIFESTATUS() { return manager.GetLocalizedString(683) };		// Please, select ready for invoice lifestatus
	@enumerable static get PLEASE_SELECT_INVOICE_ENTITY() { return manager.GetLocalizedString(684) };						// Please, select invoice entity
	@enumerable static get PLEASE_SELECT_OUTPUT_RECORD_TYPE() { return manager.GetLocalizedString(685) };					// Please, select output record type
    @enumerable static get RECORD_FOR_TABLE_CREATED() { return manager.GetLocalizedString(686) };							// {table} record has been created
	@enumerable static get DATA_SOURCE() { return manager.GetLocalizedString(701) };										// Data Source
	@enumerable static get WAITING_FOR_INVOICING_LIFESTATUS() { return manager.GetLocalizedString(702) };					// Wating for invoicing lifestatus
	@enumerable static get INVOICE_ENTITY() { return manager.GetLocalizedString(703) };										// Invoice entity
	@enumerable static get INVOICE_TYPE() { return manager.GetLocalizedString(704) };										// Invoice type

	//Lifestatus Designer
    @enumerable static get CONFIRM() { return manager.GetLocalizedString(716) };									// Confirm
    @enumerable static get DISABLE_NODE() { return manager.GetLocalizedString(717) };								// Are you sure you want to disable this node?
    @enumerable static get LINKS_WILL_BE_DELETED() { return manager.GetLocalizedString(718) };						// Please, note that all related links will be deleted.
    @enumerable static get REMOVE_THIS_LINK() { return manager.GetLocalizedString(719) };										// Are you sure you want to remove this link?
    @enumerable static get UNDO() { return manager.GetLocalizedString(720) };										// UNDO
    @enumerable static get REDO() { return manager.GetLocalizedString(721) };										// Redo
    @enumerable static get PRINT() { return manager.GetLocalizedString(722) };										// Print
    @enumerable static get AUTO_LAYOUT() { return manager.GetLocalizedString(723) };								// Auto-layout
    @enumerable static get TOGGLE_FULL_SCREEN() { return manager.GetLocalizedString(724) };						// Toggle full screen
    @enumerable static get ROTATE() { return manager.GetLocalizedString(725) };									// Rotate
    @enumerable static get ONLIFESTATUSTRIGGER() { return manager.GetLocalizedString(726) };						// OnLifestatusSet Trigger
    @enumerable static get PRESENTATION() { return manager.GetLocalizedString(727) };								// Presentation
    @enumerable static get FILL() { return manager.GetLocalizedString(728) };										// Fill
    @enumerable static get OUTLINE() { return manager.GetLocalizedString(729) };						// Outline
    @enumerable static get FONT_SIZE() { return manager.GetLocalizedString(730) };						// Font size
    @enumerable static get FONT_THICKNESS() { return manager.GetLocalizedString(731) };						// Font thickness
    @enumerable static get FONT_COLOR() { return manager.GetLocalizedString(732) };						// Font color
    @enumerable static get TRANSITION() { return manager.GetLocalizedString(733) };						// Transition
    @enumerable static get APPROVAL() { return manager.GetLocalizedString(734) };						// Approval
    @enumerable static get CONNECTION_TYPE() { return manager.GetLocalizedString(735) };						// Connection type
    @enumerable static get CONNECTION_STYLE() { return manager.GetLocalizedString(736) };						// Connection style
    @enumerable static get LINE_THICKNESS() { return manager.GetLocalizedString(737) };						// Line thickness
    @enumerable static get LINE_STYLE() { return manager.GetLocalizedString(738) };						// Line style
    @enumerable static get COLOR() { return manager.GetLocalizedString(739) };						// Color
    @enumerable static get ENABLE() { return manager.GetLocalizedString(740) };						// Enable
    @enumerable static get NEXT_ALLOWED() { return manager.GetLocalizedString(741) };						// Next Allowed
    @enumerable static get SOLID() { return manager.GetLocalizedString(742) };						// Solid
    @enumerable static get DOTTED() { return manager.GetLocalizedString(743) };						// Dotted
    @enumerable static get DASHED() { return manager.GetLocalizedString(744) };						// Dashed
	@enumerable static get BOLD() { return manager.GetLocalizedString(746) };						// Bold
	@enumerable static get INVALID_LIFESTATUS_NAME() { return manager.GetLocalizedString(747) };		// Invalid lifestatus name: Start
	@enumerable static get ADVANCED_MODE() { return manager.GetLocalizedString(773) };		// Advanced mode
	@enumerable static get TIME_FRAME() { return manager.GetLocalizedString(775) };		// Time Frame
	@enumerable static get DURATION_ELAPSED() { return manager.GetLocalizedString(776) };		// Duration elapsed
	@enumerable static get DEADLINE_ELAPSED() { return manager.GetLocalizedString(777) };		// Deadline elapsed
	@enumerable static get NO_ACTION_NODE() { return manager.GetLocalizedString(876) };		// No action node
	@enumerable static get SKIP_ACTION_CREATION_ON_FOLLOW_UP() { return manager.GetLocalizedString(882) };		// Skip action creation on follow up
	@enumerable static get CREATE_A_TRIGGER() { return manager.GetLocalizedString( 1048 ) };                      // Create a trigger to run functions when the status is set
	@enumerable static get MULTIPLE_ACTIONS() { return manager.GetLocalizedString(1144) };				// Multiple actions
	@enumerable static get SELECTED() { return manager.GetLocalizedString(1183) };											//Selected
	@enumerable static get ICON_NOT_SELECTED() { return manager.GetLocalizedString(1184) };								//Icon not selected
	@enumerable static get HIDE_LIFESTATUS_NAME() { return manager.GetLocalizedString(1185) };								//Hide lifestatus name
	@enumerable static get REFERENCE_FLOW() { return manager.GetLocalizedString(1304) };							//Reference Flow

	//Financial Dashboard
	@enumerable static get LEDGER() { return manager.GetLocalizedString(750)};					// Ledger
	@enumerable static get ACCOUNTS() { return manager.GetLocalizedString(751)};					// Accounts
	@enumerable static get NO_CONTROLS_OF_TYPE_INVOICING() { return manager.GetLocalizedString(752)}; //There are no controls of type Invoicing
	@enumerable static get READY_FOR_INVOICING_DATA() { return manager.GetLocalizedString(753)}; //Ready for invoicing data
	@enumerable static get INVOICED() { return manager.GetLocalizedString(755)}; //Invoiced
	@enumerable static get READY_FOR_PROCESSING_DATA() { return manager.GetLocalizedString(756)}; //Ready for processing data
	@enumerable static get SELECT_FINANCIAL_PROCESSOR() { return manager.GetLocalizedString(757)}; //Select Financial Processor

	//User Allowance
	@enumerable static get OVERRIDE_SECURITY() { return manager.GetLocalizedString(863)}; 						//Override security
	@enumerable static get CHANGE_RECORD_SECURITY() { return manager.GetLocalizedString(864)}; 					//Change record security
	@enumerable static get NATIVE_SQL() { return manager.GetLocalizedString(865)}; 								//Native SQL
	@enumerable static get PLANNING() { return manager.GetLocalizedString(866)}; 									//Planning
	@enumerable static get MAKE_PERSONAL_LABEL() { return manager.GetLocalizedString(867)}; 						//Make personal
	@enumerable static get EXPORT_DATA() { return manager.GetLocalizedString(868)}; 								//Export data1
	@enumerable static get MAKE_EXAMPLE_LABLE() { return manager.GetLocalizedString(869)}; 						//Make example

	//User Security
	@enumerable static get SUPER_USER() { return manager.GetLocalizedString( 870 )};								//SuperUser
	@enumerable static get DESIGNER_LEVEL1() { return manager.GetLocalizedString( 871 )};								//Designer Level 1
	@enumerable static get DESIGNER_LEVEL2() { return manager.GetLocalizedString( 872 )};								//Designer Level 2
	@enumerable static get DESIGNER_LEVEL3() { return manager.GetLocalizedString( 873 )};								//Designer Level 3
	@enumerable static get NETWORK_MANAGER() { return manager.GetLocalizedString( 1273)};								//Network Manager
	@enumerable static get US() { return manager.GetLocalizedString( 874 )};										//US


	@enumerable static get FINANCIAL() { return manager.GetLocalizedString( 893 )};								//Financial

	//General properties
	@enumerable static get PLEASE_SPECIFY_THE_URL() { return manager.GetLocalizedString( 953 ) }				//Please, specify the url
	@enumerable static get DROP_REPORT_HERE() { return manager.GetLocalizedString( 954 ) }						//Drop report here

	//Planner level control

	@enumerable static get LINK_UNDERLYING_RECORD() { return manager.GetLocalizedString( 913 )};					//Click to link a {UnderlyingSubject} record
	@enumerable static get ADD_AND_LINK_UNDERLYING_RECORD() { return manager.GetLocalizedString( 917 )};			//Click to add & link a {UnderlyingSubject} record
	@enumerable static get PROJECT_MANAGER() { return manager.GetLocalizedString( 972 )};							//Project manager
	
	@enumerable	static get OVERWRITE() { return manager.GetLocalizedString( 1155 )};											//Overwrite
	
	@enumerable	static get SKIP_EXISTING() { return manager.GetLocalizedString( 1156 )};										//Skip existing

	@enumerable	static get COPY_PLANNER_DATA() { return manager.GetLocalizedString(1159) };     //Copy planning data
	
	@enumerable static get COPY_PERIOD() { return manager.GetLocalizedString(1160) }; 	//Copy {periodName}

	@enumerable static get CONFIRM_SELECTION() { return manager.GetLocalizedString(1161) }; 	//Confirm selection

	//#region Deletion modes
	@enumerable static get ON_DELETE() { return manager.GetLocalizedString(1232) };																	//On delete
	@enumerable static get DISABLING_A_RECORD_WITH_A_CUSTOM_LIFESTATUS_IS_NOT_ALLOWED() { return manager.GetLocalizedString(1229) }; 				//Disabling a record with a custom lifestatus is not allowed
	//#endregion

	//#region Undefined
	@enumerable static get NOTIFICATIONS_LABEL() { return manager.GetLocalizedString( 778 )};						//Notifications
	@enumerable static get TABLE_VIEW_LABEL() { return manager.GetLocalizedString(779)}; 							//TableView
	@enumerable static get GRID_BULK_EDIT() { return manager.GetLocalizedString(781)}; 							//Bulk edit
	@enumerable static get SEND_BY_EMAIL() { return manager.GetLocalizedString(782)}; 							//Send by email
	@enumerable static get EDIT_RECORD_LABEL() { return manager.GetLocalizedString(783)}; 						//Edit record
	@enumerable static get ACCESS_DENIED_LABEL() { return manager.GetLocalizedString(784)}; 						//Access denied
	@enumerable static get UNLINK_RECORD_LABEL() { return manager.GetLocalizedString(785)}; 						//Unlink record
	@enumerable static get UNLINK_MANY_RECORDS_LABEL() { return manager.GetLocalizedString(1424)}; 				//Unlink many records
	@enumerable static get UNLINK_LABEL() { return manager.GetLocalizedString(1425)}; 							//Unlink
	@enumerable static get UNLINK_ALL_SELECTED_LABEL() { return manager.GetLocalizedString(1426)}; 				//Unlink all selected
	@enumerable static get ENABLE_RECORD_LABEL() { return manager.GetLocalizedString(786)}; 						//Enable record
	@enumerable static get DISABLE_RECORD_LABEL() { return manager.GetLocalizedString(787)}; 						//Disable record
	@enumerable static get ADD_TO_BASKET_LABEL() { return manager.GetLocalizedString(788)}; 						//Add to basket
	@enumerable static get REMOVE_FROM_BASKET_LABEL() { return manager.GetLocalizedString(789)}; 					//Remove from basket
	@enumerable static get PLANNER_LABEL() { return manager.GetLocalizedString(790)}; 							//Planner
	@enumerable static get SELECT_VALUE_LABEL() { return manager.GetLocalizedString(792)}; 						//Select value ...
	@enumerable static get OWNER_LABEL() { return manager.GetLocalizedString(793)}; 								//Owner
	@enumerable static get ADD_NEW_LINK_LABEL() { return manager.GetLocalizedString(794)}; 						//Add new link
	@enumerable static get REMOVE_LINK_LABEL() { return manager.GetLocalizedString(795)}; 						//Remove link
	@enumerable static get EDIT_LINK_LABEL() { return manager.GetLocalizedString(796)}; 							//Edit link
	@enumerable static get TABLE_TEXT_NON_HAS_FIELDS() { return manager.GetLocalizedString(797)}; 				//Link table does not have any Custom fields
	@enumerable static get RECORD_VIEWED_LABEL() { return manager.GetLocalizedString(791)}; 						//Record viewed
	@enumerable static get RECORD_EDITED_LABEL() { return manager.GetLocalizedString(798)}; 						//Record edited
	@enumerable static get RECORD_DELETED_LABEL() { return manager.GetLocalizedString(799)}; 						//Record deleted
	@enumerable static get LINK_CREATED_LABEL() { return manager.GetLocalizedString(800)}; 						//Link created
	@enumerable static get LINK_EDITED_LABEL() { return manager.GetLocalizedString(801)}; 						//Link edited
	@enumerable static get LINK_DELETED_LABEL() { return manager.GetLocalizedString(802)}; 						//Link deleted
	@enumerable static get GLOBAL_SETTINGS_LABEL() { return manager.GetLocalizedString(803)}; 					//Global settings
	@enumerable static get NO_EXAMPLE_LABEL() { return manager.GetLocalizedString(804)}; 							//No example
	@enumerable static get CLUSTERED_FLAG() { return manager.GetLocalizedString(805)}; 							//Clustered
	@enumerable static get FIELD_MATCHING_LABEL() { return manager.GetLocalizedString(806)}; 						//Field matching
	@enumerable static get LINKED_ENTITY_LABEL() { return manager.GetLocalizedString(807)}; 						//Linked entity
	@enumerable static get CURRENT_ENTITY_LABEL() { return manager.GetLocalizedString(808)}; 						//Current entity
	@enumerable static get SELECT_FORMAT_LABEL() { return manager.GetLocalizedString(809)}; 						//Select format
	@enumerable static get SELECT_LABEL() { return manager.GetLocalizedString(810)}; 								//Select
	@enumerable static get ACCOUNT_NAME_LABEL() { return manager.GetLocalizedString(811)}; 						//Account Name
	@enumerable static get ACTIVE_LABEL() { return manager.GetLocalizedString(813)}; 								//Active
	@enumerable static get ARCHIVE_LABEL() { return manager.GetLocalizedString(814)}; 							//Archive
	@enumerable static get REVOKE_LABEL() { return manager.GetLocalizedString(815)}; 								//Revoke
	@enumerable static get RLANNED_LABEL() { return manager.GetLocalizedString(816)}; 							//Planned
	@enumerable static get ACQUIRED_LABEL() { return manager.GetLocalizedString(817)}; 							//Acquired
	@enumerable static get MAINTAINED_LABEL() { return manager.GetLocalizedString(818)}; 							//Maintained
	@enumerable static get OPERATIONAL_LABEL() { return manager.GetLocalizedString(819)}; 						//Operational
	@enumerable static get RETIRED_LABEL() { return manager.GetLocalizedString(820)}; 							//Retired
	@enumerable static get SECURITY_EDITOR_LABEL() { return manager.GetLocalizedString(821)}; 					//Security Editor
	@enumerable static get SECURITY_LABEL() { return manager.GetLocalizedString(822)}; 							//Security
	@enumerable static get PASS_OWNER_LABEL() { return manager.GetLocalizedString(823)}; 							//Pass owner
	@enumerable static get PASS_INHERITANCE_SETTINGS_LABEL() { return manager.GetLocalizedString(1178)}			//Pass inheritance settings
	@enumerable static get USER_USER_GROUP_LABEL() { return manager.GetLocalizedString(824)}; 					//User/User group
	@enumerable static get RIGHTS_LABEL() { return manager.GetLocalizedString(825)}; 								//Rights
	@enumerable static get OPTIONALS_RIGHTS_LABEL() { return manager.GetLocalizedString(826)}; 					//Optional Rights
	@enumerable static get DO_NOT_PASS_SECURITY_LABEL() { return manager.GetLocalizedString(827)}; 				//Do not pass security
	@enumerable static get PASS_RIGHTS_LABEL() { return manager.GetLocalizedString(828)}; 						//Pass rights
	@enumerable static get PASS_OPTIONAL_RIGHTS_LABEL() { return manager.GetLocalizedString(829)}; 				//Pass optional rights
	@enumerable static get REFUSE_PASSED_SECURITY_LABEL() { return manager.GetLocalizedString(830)}; 				//Refuse passed security
	@enumerable static get CLOSE_LABEL() { return manager.GetLocalizedString(831)}; 								//Close
	@enumerable static get ROW_LABEL() { return manager.GetLocalizedString(833)}; 								//Row
	@enumerable static get REMOVE_LABEL() { return manager.GetLocalizedString(834)}; 								//Remove
	@enumerable static get PASTE_VARIABLE_LABEL() { return manager.GetLocalizedString(835)}; 						//Paste variable
	@enumerable static get DO_YOU_WANT_TO_CREATE_LABEL() { return manager.GetLocalizedString(836)}; 				//Do you want to create a link?
	@enumerable static get NO_ACCESS_LABEL() { return manager.GetLocalizedString(837)}; 							//No Access
	@enumerable static get SEARCH_ONLY_LABEL() { return manager.GetLocalizedString(838)}; 						//Search Only
	@enumerable static get EDITDELETE_LABEL() { return manager.GetLocalizedString(839)}; 							//Edit/Delete
	@enumerable static get EDIT_DELETE_SECURITY_LABEL() { return manager.GetLocalizedString(840)}; 				//Edit/Delete/Security
	@enumerable static get FULL_ACCESS_LABEL() { return manager.GetLocalizedString(841)}; 						//Full Access
	@enumerable static get SELECT_RIGHTS() { return manager.GetLocalizedString(992)}; 							//Select Rights
	@enumerable static get EDIT_CODE_LABEL() { return manager.GetLocalizedString(842)}; 							//Edit code
	@enumerable static get ADD_DEFAULT_SECURITY() { return manager.GetLocalizedString(955) };						//Add default security
	@enumerable static get SELECT_SECURITY_PROFILE() { return manager.GetLocalizedString(1206) };					//Select security profile
	@enumerable static get PLEASE_SELECT_THE_PROFILE() { return manager.GetLocalizedString(1207) };					//Please, select the profile
	@enumerable static get NO_ALTERNATIVE_ENTITIES_WERE_SELECTED() { return manager.GetLocalizedString(958) };		//No alternative entities were selected
	@enumerable static get SWITCH_TO_THE_DASH_TYPE() { return manager.GetLocalizedString(1009) };					// Switch to the dash type to add a field

	@enumerable static get FILL_LOOKUP() { return manager.GetLocalizedString(887)}; 								//Fill lookup
	@enumerable static get MAKE_A_BACK_LINK() { return manager.GetLocalizedString(888)}; 							//Make a back link
	@enumerable static get BUTTON_NAME() { return manager.GetLocalizedString(889)}; 								//Button Name
	@enumerable static get SUB_TABLE_VIEW() { return manager.GetLocalizedString(890)}; 							//Sub table view
	@enumerable static get INTENTION() { return manager.GetLocalizedString(891)}; 								//Intention
	@enumerable static get NO_RECORDS_TO_SELECT() { return manager.GetLocalizedString(952)}; 						//No records to select
	@enumerable static get CLICK_TO_COPY_URL() { return manager.GetLocalizedString(1440)};						//Click to copy URL
	//#endregion
	
	//#region Cluster Settings
	@enumerable static get CLUSTER_SETTINGS() {return manager.GetLocalizedString(932)}													//Cluster Settings
	@enumerable static get COPY_CLUSTER_MODE_DESCRIPTION() {return manager.GetLocalizedString(933)}										//The value of the clustered field is copied
	@enumerable static get DEFAULT_CLUSTER_MODE_DESCRIPTION() {return manager.GetLocalizedString(934)}									//The default value of the field is used (empty if not set)
	@enumerable static get DELTA_CLUSTER_MODE_DESCRIPTION() {return manager.GetLocalizedString(935)}										//The delta of calculation between the sum of the current values and the sum of the next values, Sum(QuantityCurrent) - Sum(QuantityNext),
	@enumerable static get INVALID_FIELD_DATA() {return manager.GetLocalizedString(971)}							//Invalid field data
	//#endregion

	//#region Book
	@enumerable static get BOOK_CANNOT_BE_RENDERED_ON_THE_BOOK_PAGE() {return manager.GetLocalizedString(1113)}		//Book cannot be rendered on the book page
	@enumerable static get THERE_ARE_NO_PAGES() {return manager.GetLocalizedString(1114)}							//There are no pages
	@enumerable static get SCREEN_NOT_FOUND_FOR_THE_PAGE() {return manager.GetLocalizedString(1115)}				//Screen not found for the page
	@enumerable static get ERROR_GETTING_BOOK_PAGE() {return manager.GetLocalizedString(1122)}						//Error getting book page
	//#endregion

	//#region Timer
	@enumerable static get TIMER() {return manager.GetLocalizedString(1127)}										//Timer
	@enumerable static get ACTIVE_TIMERS() {return manager.GetLocalizedString(1128)}								//Active Timers
	@enumerable static get START_TIMER() {return manager.GetLocalizedString(1466)}								//Start timer
	@enumerable static get CLICK_TO_SHOW_ACTIVE_TIMERS() {return manager.GetLocalizedString(1129)}				//Click to show active timers
	@enumerable static get SET_THE_MAXIMUM_DURATION_TO_HOURS() {return manager.GetLocalizedString(1130)}			//Set the maximum duration to ${hours} hours
	@enumerable static get NO_ACTIVE_TIMERS() {return manager.GetLocalizedString(1139)}							//No active timers
	@enumerable static get STOP_THE_TIMER_FOR() {return manager.GetLocalizedString(1491)}							//Stop the timer for "{recordName}"?
	@enumerable static get STOP_THE_TIMER_FOR_BEFORE_LOGOUT() {return manager.GetLocalizedString(1492)}			//Stop the timer for "{recordName}" before Logout?
	@enumerable static get THE_TIMER_FOR_STARTED_AUTOMATICALLY() {return manager.GetLocalizedString(1493)}		//The timer for "{recordName}" started automatically
	//#endregion

    //#region Menu Queries
    @enumerable static get MENU_QUERIES() { return manager.GetLocalizedString( 1141 ) }							        //Menu Queries
	//#endregion

	//#region Decimal
    @enumerable static get DECIMAL_DIGITS_VALIDATION_MESSAGE() { return manager.GetLocalizedString(1153) }		//Value must have at most {decimalDigits} digits in decimal part and at most {totalDigits} digits in total
	//#endregion

    //#region ProgressBar
    @enumerable static get DRAG_N_DROP_FLOWFOLDER() { return manager.GetLocalizedString(1190) }                                     //Drag-n-drop grid records to add them to the folder
	@enumerable static get STATUS_SELECTOR() { return manager.GetLocalizedString( 1514 ) }			//Status Selector

	//#endregion

	//#region Chart
	@enumerable static get SERIES() { return manager.GetLocalizedString(1244) }														//Series
	@enumerable static get NO_VALID_SUBTABLE_VIEW() { return manager.GetLocalizedString(1247) }										//No valid subtable view
	//#endregion

	//#region Network Designer
	@enumerable static get ACCESS_RECORD_EXISTS() { return manager.GetLocalizedString(1296) }										//This access record already exists
	@enumerable static get PLEASE_SELECT_USER() { return manager.GetLocalizedString(1300) }										//Please, select user
	@enumerable static get PLEASE_SPECIFY_REMOTE_USER() { return manager.GetLocalizedString(1301) }								//Please, specify remote user
	//#endregion

	//#region Translation editor
	@enumerable static get FILTER_BY_TYPE() { return manager.GetLocalizedString(1423) }											//Filter by type
	//#endregion

	//#region Login Page
	@enumerable static get LOGIN() { return manager.GetLocalizedString(1398, 'Login') }								//Login
	@enumerable static get RESET_PASSWORD() { return manager.GetLocalizedString(1399, 'Reset password') }				//Reset password
	@enumerable static get ENTER_LOGIN() { return manager.GetLocalizedString(1401, 'Please, enter a login') }				//Please, enter a login
	@enumerable static get ENTER_PASSWORD() { return manager.GetLocalizedString(1402, 'Please, enter a password') }				//Please, enter a password
	@enumerable static get SELECT_DATABASE() { return manager.GetLocalizedString(1404, 'Please, select database') }				//Please, select database

	@enumerable static get PASSWORD_EXPIRED() { return manager.GetLocalizedString(1405, 'Your password is expired') }			//Your password is expired
	@enumerable static get CONFIRM_NEW_PASSWORD() { return manager.GetLocalizedString(1408, 'Confirm new password') }			//Confirm new password
	@enumerable static get PASSWORD_EXPIRED_IN() { return manager.GetLocalizedString(1409, 'Your password will be expired in {amount} day(s)') }			//Your password will be expired in {amount} day(s)
	//#endregion

	//#region Recipe Editor
	@enumerable static get RECIPE_NAME() { return manager.GetLocalizedString(1336) }												//Recipe Name
	@enumerable static get LOCKED_THE_ROOT_NODE_IS_LINKED_TO_THE_LOCK_ENTITY() { return manager.GetLocalizedString(1345) }		//Locked. The root node is linked to the lock entity
	@enumerable static get WILL_BE_APPLICABLE_DURING_UNLINKING_OF_ANY_PART_OF_RECIPE() { return manager.GetLocalizedString(1346) }	//Will be applicable during Unlinking of any part of the recipe on any screen
	//#endregion

	@enumerable static get GENERATE_DOCUMENT_FROM_TEMPLATE() { return manager.GetLocalizedString(1434) } //Generate a document from a template

	//#region Report issue
	@enumerable static get SEND_TICKET() { return manager.GetLocalizedString(1450) }													//Send ticket
	@enumerable static get CREATE_TICKET() { return manager.GetLocalizedString(1451) }												//Create ticket
	@enumerable static get SUMMARY() { return manager.GetLocalizedString(1452) }														//Summary
	@enumerable static get SUMMARY_IS_REQUIRED() { return manager.GetLocalizedString(1453) }											//Summary is required
	@enumerable static get SUMMARY_SHOULD_HAVE_MAXIMUM_80_CHARACTERS() { return manager.GetLocalizedString(1454) }					//Summary should have maximum 80 characters
	@enumerable static get CATEGORY() { return manager.GetLocalizedString(1455) }														//Category
	@enumerable static get CATEGORY_IS_REQUIRED() { return manager.GetLocalizedString(1456) }											//Category is required
	@enumerable static get URGENCY() { return manager.GetLocalizedString(1457) }														//Urgency
	@enumerable static get URGENCY_IS_REQUIRED() { return manager.GetLocalizedString(1458) }											//Urgency is required
	@enumerable static get REPORT_ISSUE_DESCRIPTION_PLACEHOLDER() { return manager.GetLocalizedString(1459) }							//What exactly goes wrong?\nWhat are the steps to reproduce the issue?\nDoes it happen with all records or only with this one?\nCan other users reproduce the issue?\nThe more details, the better.
	@enumerable static get DESCRIPTION_IS_REQUIRED() { return manager.GetLocalizedString(1460) }										//Description is required
	@enumerable static get DESCRIPTION_SHOULD_HAVE_AT_LEAST_50_CHARACTERS() { return manager.GetLocalizedString(1461) }				//Description should have at least 50 characters
	@enumerable static get DESCRIPTION_SHOULD_HAVE_MAXIMUM_2000_CHARACTERS() { return manager.GetLocalizedString(1462) }				//Description should have maximum 2000 characters
	@enumerable static get SWITCH_TO_VERSION() { return manager.GetLocalizedString(1480) }												//Switch to this version
	//#endregion
}

//#region
export class CONFIRMATIONS {
	@enumerable static get ARE_YOUR_SURE_TO_DELETE_RECORDS() { return manager.GetLocalizedString(347) }								//Are you sure that you want to delete record(s)?
	@enumerable static get ARE_YOUR_SURE_TO_DISABLE_RECORDS() { return manager.GetLocalizedString(711) }							//Record(s) could not be deleted. Record(s) will be disabled.Proceed?
	@enumerable static get ALL_RELATED_DATA_WILL_BE_LOST_CONTINUE() { return manager.GetLocalizedString(1228) }						//All related data will be lost. Continue?
	@enumerable static get DELETION_NOT_ALLOWED_DUE_TO_EXISTING_RELATED_RECORDS() { return manager.GetLocalizedString(1230) }		//Deletion not allowed due to existing related records

	@enumerable static get FIELD_WILL_BE_REMOVED_FROM_NEXT_FIELD_COLLECTION() { return manager.GetLocalizedString( 348 ) }			//Field will be removed from next field collections: {DeletedFields}
	@enumerable static get ALL_CHANGES_WILL_BE_LOST() { return manager.GetLocalizedString(349) }									//All unsaved changes will be lost. Save changes?
	@enumerable static get DELETE_DATABASE_CONFIGURATION() { return manager.GetLocalizedString( 353 ) }								//Delete database configuration?
	@enumerable static get CLOSE() { return manager.GetLocalizedString( 356 ) }														//Close?
	@enumerable static get DISCARD() { return manager.GetLocalizedString( 357 ) }													//Discard?
	@enumerable static get SEND() { return manager.GetLocalizedString( 1329 ) }													//Send

	@enumerable static get FUNCTION_PACKAGE_DELETION() { return manager.GetLocalizedString( 597 ) };								//Current function package and all related data will be deleted. Proceed?
	@enumerable static get SUBFORM_AND_ITS_CONTROLS_WILL_BE_DELETED() { return manager.GetLocalizedString( 1264 ) }					//Subform and its controls will be deleted. Proceed?
	@enumerable static get DO_YOU_WANT_TO_STOP_RECORD_NAME() { return manager.GetLocalizedString( 1318 ) }							//Do you want to stop {recordName}?


	@enumerable static get UNLINK_RECORD() { return manager.GetLocalizedString( 360 ) }												//Unlink record?
	@enumerable static get UNLINK_USER() { return manager.GetLocalizedString( 361 ) }													//Unlink user?
	@enumerable static get CONTINUE() { return manager.GetLocalizedString( 364 ) }													//Do you want to continue?
	@enumerable static get CURRENT_RECORD_WILL_BE_DISABLED() { return manager.GetLocalizedString( 365 ) }								//Current record will be disabled and new one will be created. Proceed?
	@enumerable static get CANVAS_DATA_HAS_BEEN_CHANGED() { return manager.GetLocalizedString( 366 ) }								//Canvas data has been changed. Are you sure that you want to execute?
	@enumerable static get SAVE_CHANGES() { return manager.GetLocalizedString( 367 ) }												//Save changes?
	@enumerable static get DELETE_SCREEN() { return manager.GetLocalizedString( 368 ) }												//Are you sure that you want to delete this screen?
	@enumerable static get RESET_SCREEN() { return manager.GetLocalizedString( 369 ) }												//Reset screen?
	@enumerable static get REVERSE_LINK_TABLE() { return manager.GetLocalizedString( 455 ) }											//Do you want to reverse link table?
	@enumerable static get RELATION_WILL_BE_REMOVED_FROM_LINK_LIST() { return manager.GetLocalizedString( 456 ) }						//Link table reversing will remove relations from linklist control on {EntityName} edit screen. Proceed?
	@enumerable static get DO_YOU_WANT_TO_DELETE() { return manager.GetLocalizedString( 457 ) }										//Do you want to delete
	@enumerable static get ARE_SURE_TO_CHANGE_LIFESTATUS() { return manager.GetLocalizedString( 519 ) };								//Are sure you want to proceed from {fromstatus} to {tostatus}?
	@enumerable static get ARE_SURE_TO_CHECKIN_DOCUMENT() { return manager.GetLocalizedString( 578 ) };								//Document status will be changed to "Checkout". Proceed?
	@enumerable static get DEDUPLICATION_RECORDS_DELETION() { return manager.GetLocalizedString( 598 ) };								//{matchQuantity} records will be deleted. Proceed?
	@enumerable static get CONFIRM_ASSEMBLY_CHECKOUT() { return manager.GetLocalizedString( 1101 ) };								//This would change the status of all records listed below. Do you want to continue?
	@enumerable static get ARE_YOU_SURE_YOU_WANT_TO_DELETE_VERSION() { return manager.GetLocalizedString( 1334 ) };					//Are you sure you want to delete version {VersionName}?
	@enumerable static get YOU_CAN_LOSE_UNSAVED_DATA_IF_YOU_LEAVE() { return manager.GetLocalizedString( 1473 ) };					//You can lose unsaved data in the current Spreadsheet if You leave the current tab without saving. Proceed?


	@enumerable static get ARE_SURE_TO_CHANGE_CLASS() { return manager.GetLocalizedString( 642 ) };									//Are you sure you want to change the class?
	@enumerable static get ARE_SURE_TO_UPDATE_VERSION() { return manager.GetLocalizedString( 780 ) };									//Are you sure you want to update the version?

	@enumerable static get TWO_FA_IS_ENABLED_BUT_NOT_CONFIGURED() { return manager.GetLocalizedString( 1413, 'Two-Factor Authentication (2FA) is enabled but not configured on your account.' ) }            //Two-Factor Authentication (2FA) is enabled but not configured on your account.
	@enumerable static get SCAN_QR_CODE_WITH_TWO_FA() { return manager.GetLocalizedString( 1414, 'Scan the following QR code with your two-factor authenticator device.' ) }                             //Scan the following QR code with your two-factor authenticator device.
	@enumerable static get FOLLOW_TO_CONFIGURE_TWO_FA() { return manager.GetLocalizedString( 1415, 'Please follow the instructions below to configure 2FA.' ) }                             				//Please follow the instructions below to configure 2FA.
	@enumerable static get ENTER_CODE_FROM_TWO_FA_DEVICE() { return manager.GetLocalizedString( 1416, 'Enter 6-digit code from your two-factor authenticator device:' ) }                             //Enter 6-digit code from your two-factor authenticator device:

	@enumerable static get PLEASE_TYPE_YOUR_PASSWORD_TO_CONFIRM() { return manager.GetLocalizedString( 520 ) };						//Please, type your password to confirm
	@enumerable static get ENTER_YOUR_PASSWORD_TO_CONTINUE_CLEANING_TABLES() { return manager.GetLocalizedString( 1231 ) };			//Selected tables will be cleared. This action cannot be undone. Enter your password to continue
	@enumerable static get LINK_RECORD_WITH_NEXT_RELATION_TYPE() { return manager.GetLocalizedString( 571 ) };						//New link with next relation will be created. Proceed?

	@enumerable static get CAN_CAUSE_DUPLICATION_OF_ALIAS() { return manager.GetLocalizedString(883) };								//In the future, this can cause duplication of aliases. Proceed?
	@enumerable static get SELECTED_DAYS_WILL_NO_LONGER_TO_BE_EDITABLE() { return manager.GetLocalizedString(994) }; 					//Records of the selected and preceding days will no longer be editable. Continue?								//In the future, this can cause duplication of aliases. Proceed?

	@enumerable static get RESET_LINK_SENT() { return manager.GetLocalizedString(1395, 'Reset link will be sent to the e-mail assigned to {userName}. Proceed?') }			//Reset link will be sent to the e-mail assigned to {userName}. Proceed?
	@enumerable static get CREATE_NEW_ONE() { return manager.GetLocalizedString(1406, 'Please, create a new one') }		//Please, create a new one
	@enumerable static get DO_YOU_WANT_TO_COPY() { return manager.GetLocalizedString( 1060 ) }	
	
	@enumerable static get SELECT_CELL_SUBJECT() { return manager.GetLocalizedString( 1174 ) }                              //Select a subject to link to new plannings
	@enumerable static get THE_TIMER_IS_ALREADY_STARTED() { return manager.GetLocalizedString( 1317 ) }                     //The timer is already started. Do you want to stop it and start new?
	@enumerable static get REMOVE_SORT_DESCRIPTION() { return manager.GetLocalizedString( 1339 ) }                          //Are you sure you want to remove the description?
	@enumerable static get UNREPORTED_TIME() { return manager.GetLocalizedString( 1412 ) } 									//You have unreported {X} hour(s) during {Y} day(s)
	@enumerable static get GENERATE_DOCUMENT_FROM_TEMPLATE() { return manager.GetLocalizedString( 1435 ) } 								//Do you want to generate a document using ${template.Name} template for ${nameField.FieldValue || ''} record?
	@enumerable static get DEFAULT_RETIRED_STATUS_NOW() { return manager.GetLocalizedString( 1437 ) } 				//The {oldstatus} is default retired status now. Do you want to change it to {newstatus}?
	@enumerable static get ALL_CHILD_RECORDS_WILL_BE_MOVED_TO_THE_RETIRED_STATUSES() { return manager.GetLocalizedString( 1439 ) } 		//All child records will be moved to the {RetiredStatuses}. Proceed?

	//#region Deduplication
	@enumerable static get ARE_YOU_SURE_YOU_WANT_TO_DELETE_ALL_MATCH_RECORDS() { return manager.GetLocalizedString( 1449 ) }				//Are you sure you want to delete all match records?
	//#endregion

	//#region Document
	@enumerable static get SAVE_CHANGES_IN_FILE() { return manager.GetLocalizedString(1471) }					//Save changes in {fileName}?
	@enumerable static get ARE_YOU_SURE_YOU_WANT_TO_SAVE_THE_CHANGES_TO_FILE_AND_CLOSE() { return manager.GetLocalizedString( 1472 ) }				//Are you sure you want to save the changes to {fileName} and close?
	//#endregion

	@enumerable static get SWITCH_TO_VERSION() { return manager.GetLocalizedString( 1481 ) }				//Do you want to switch to this version?

	static get DELETE_DW_PACKAGE() { return manager.GetLocalizedString( 1499 ) } //Are you sure you want to delete <Name> DWPackage?	
}
//#endregion