import { record } from 'rrweb';
import * as _ from 'underscore';
import * as ko from 'knockout';

import 'lockr';
import 'knockout-fast-foreach';

import {LOCAL_STORAGE} from 'Core/Common/Enums/LocalStorageItems';
import {Guid} from "Core/Common/Guid";

import {FieldDataModel} from "Core/Screens/Common/LinkEditor/Models/DataModel";
import {ActionCell} from 'Core/Controls/Grid/BaseGrid/GridCell/ActionCell';
import {DataCell} from 'Core/Controls/Grid/BaseGrid/GridCell/DataCell';
import {FavoriteCell} from 'Core/Controls/Grid/BaseGrid/GridCell/FavoriteCell';
import {GridRowModel} from 'Core/Controls/Grid/Models/GridDataModel/GridRowModel';
import {BaseGrid} from 'Core/Controls/Grid/BaseGrid/BaseGrid';
import {Event} from 'Core/Common/Event';
import {UserVarsManager} from 'Core/UserVarsManager/UserVarsManager';
import {EVENTS} from 'Core/Controls/Grid/BaseGrid/Events';
import {GroupDetailsDropdown} from 'Core/Controls/Grid/BaseGrid/GroupDetailsDropdown/GroupDetailsDropdown';
import {LIFESTATUS, CONTROL_TYPES, TABLE_TYPES} from 'Core/Constant';
import {ScreenTypes} from 'Core/Common/Enums/ScreenTypes';
import {QueryExpressionModel} from 'Core/Controls/Grid/Models/GridDataModel/QueryExpression/QueryExpressionModel';
import {GridDataModel} from 'Core/Controls/Grid/Models/GridDataModel/GridDataModel';
import {GridStore} from 'Core/Controls/Grid/Stores/GridStore';
import {LOCK_EVENTS, LockManager} from 'Core/Components/Locker/LockManager';
import {Notifier} from 'Core/Common/Notifier';
import {States} from 'Core/Controls/Grid/BaseGrid/GridRow/States';
import {RelationshipTypes} from 'Core/Controls/Grid/BaseGrid/Enums/RelationshipTypes';
import {LABELS} from "Core/Components/Translation/Locales";
import {IForm} from "Core/Screens/IScreen";
import {IControl} from 'Core/Controls/IControl';
import {IGetDependsOnResponse, RecordStore} from "Core/Common/Stores/RecordStore";
import {UpdateDataModel} from "Core/Controls/LinkList/Models/UpdateDataModel";
import {SelectRowCell} from "Core/Controls/Grid/BaseGrid/GridCell/SelectRowCell";
import {EVENTS as TOOLBAR_EVENTS} from 'Core/Controls/Grid/Toolbar/Constants';
import {Serialize} from "libs/cerialize";
import {ConfigModel} from "Core/GeneralProperties/Models/ConfigModel";
import {PROPERTIES} from "Core/Controls/Grid/Constants";
import {FIELD_TYPES} from 'Core/Constant';
import {ColorConverter} from "Core/Components/ColorSelector/ColorConverter";
import {BlockUI} from 'Core/Common/BlockUi';
import {PUB_SUB_EVENTS} from 'MenuManager/PubSubEvents';
import {Util} from 'QueryBuilder/Util';
import {EVENTS as QUERY_RESULT_GRID_EVENTS} from "Core/Controls/Grid/BaseGrid/QueryResultPage/Events";
import { ControlDataModel } from 'Core/ScreenManager/Models/ControlDataModel';
import {IControlValue} from 'Core/Controls/BaseControl/BaseControl';

import Template from 'Core/Controls/Grid/BaseGrid/GridRow/Templates/GridRow.html';
import SortDescriptionRowTemplate from 'Core/Controls/Grid/BaseGrid/GridRow/Templates/SortDescriptionRow.html';

import { GridCellValueModel, RecordKey } from 'Core/Controls/Grid/Models/GridDataModel/GridCellValueModel';
import { DocumentManagerStore } from 'Core/Components/Controls/DocumentManager/Stores/DocumentManagerStore';

ko.templates['Core/Controls/Grid/BaseGrid/GridRow/Templates/GridRow'] = Template;
ko.templates['Core/Controls/Grid/BaseGrid/GridRow/Templates/SortDescriptionRow'] = SortDescriptionRowTemplate;

interface GroupedDataItem {
    Title: string;
    Value: string;
    Cell: DataCell;
}

export class GridRow extends Event {
    private _queryExpression: QueryExpressionModel;
    private _dataCells: KnockoutObservableArray<DataCell>;
    private _actionCell: ActionCell;
    private _favoriteCell: FavoriteCell;
    private _nestedGrids: KnockoutObservableArray<BaseGrid>;
    private _model: GridRowModel;
    private _isSelected: KnockoutObservable<boolean>;
    private _isEnableSelectRecord: KnockoutObservable<boolean>;
    private _isEditMode: KnockoutObservable<boolean>;
    private _screenType: string;
    private _isNewRecord: boolean;
    private _isLockConfirmated: boolean;
	private _isVisibleButtons: KnockoutObservable<boolean>;
	private _hasInlineControls: boolean;
    private _recordInBasket: KnockoutObservable<boolean>;
    private _maxRowHeight: string;
    private _state: States;
    private _relationshipType: string;
    private _labels = LABELS;
    private _form: IForm;
    private _linkEditorData: UpdateDataModel;
    private _selectRowCell: SelectRowCell;
    private _el: HTMLElement;
    private _gridProperties: ConfigModel;
    private _guid: string;
    private _rowColorEnabled: KnockoutObservable<boolean>;
    private _rowColor: KnockoutObservable<string>;
    private _groupedData: GroupedDataItem[];
    private _isEntity: boolean;
    private _crossTableWrapped: Array<GridRow>;
    private _isSubGrid: boolean;
    private _isSelectedForUnlinking: KnockoutObservable<boolean>;
    private _showSortBorder: KnockoutObservable<boolean>;
    private _isGroupActive: KnockoutObservable<boolean>;
    private _isGroupMode: boolean;

    ShowActionCell: KnockoutComputed<boolean>;
    SortDescriptionColumn: KnockoutComputed<DataCell>;

    Template: any;

    public IsLinkParent: boolean;

    constructor(
        model: GridRowModel,
        private _isEditable: KnockoutObservable<boolean> = ko.observable(true),
        private _hideLifeStatusToggle: KnockoutObservable<boolean> = ko.observable(false),
        private _hideUnlink: KnockoutObservable<boolean> = ko.observable(false),
        private _hideEdit: KnockoutObservable<boolean> = ko.observable(false),
        private _enableBasket: KnockoutObservable<boolean> = ko.observable(false),
        private _isDisableEditLinkButton: KnockoutObservable<boolean> = ko.observable(false),
        private _backLinkingButtons: KnockoutObservableArray<any> = ko.observableArray([]),
        isEnableSelectRecord: KnockoutObservable<boolean>,
        screenType: string,
        isVisibleButtons: boolean,
        queryExpression: QueryExpressionModel,
        isNewRecord: boolean,
        isVisibleFavorite: boolean,
        maxRowHeight: KnockoutObservable<string>,
        form: IForm,
        inlineControls: Array<IControl>,
        enableSelectRowCell: boolean,
        gridProperties: ConfigModel,
        isSubGrid: boolean,
        isGroupMode: boolean,
        private _isLinkJoin: boolean,
        private _hasProcessCards: boolean
    ) {
        super();
        this._state = States.NoChanges;
        this._queryExpression = queryExpression;
        this._crossTableWrapped = [];
        this._model = model;
        this._showSortBorder = ko.observable(false);
        this._screenType = screenType;
        this._dataCells = ko.observableArray([]);
        this._nestedGrids = ko.observableArray([]);
        this._recordInBasket = ko.observable(false);
        this._relationshipType = RelationshipTypes[model.RelationshipType];
        this._form = form;
        this._gridProperties = gridProperties;
        this._isSubGrid = isSubGrid;
        this._isSelectedForUnlinking = ko.observable(false);
        if (enableSelectRowCell) {
            this._selectRowCell = new SelectRowCell();
            this._selectRowCell.On(EVENTS.SELECT_RECORD, this, () => {
                this.Trigger(EVENTS.SELECT_RECORD, {Row: this});
            });
        }

        this._hasInlineControls = inlineControls && inlineControls.length > 0;
        _.each(inlineControls, (control) => {
            if (control.GetType() === CONTROL_TYPES.GenericButton || control.GetType() === CONTROL_TYPES.ButtonTemplate|| control.GetType() === CONTROL_TYPES.ButtonPlan) {
                (control as any).SetGridRow(this);
                const controlData = new ControlDataModel();

                const controlValue: IControlValue = {
                    Data: controlData,
                    SubjectEntityId: 0,
                    SubjectRecordId: 0,
                    RecordSpecsModel: null
                };
                
                control.SetValue(controlValue);
            }
        });

        let rowEntity = _.find(Util.GetAllQueryEntities(this._queryExpression), (item) => item.Metadata.Id == this._model.EntityId);

        this._isEntity = rowEntity && rowEntity.Metadata.Type === TABLE_TYPES.Entity;

        this._actionCell = new ActionCell(
            this._isEditable,
            this._enableBasket,
            this._recordInBasket,
            this._isDisableEditLinkButton,
            this._backLinkingButtons,
            this._model.Rights.IsEditingAllowed,
            this._queryExpression.IsCrossTable,
            inlineControls,
            this.HasSubGridsData,
            true,
            this._model.IsRetired,
            rowEntity ? rowEntity.Metadata : null,
            isSubGrid,
            this._isLinkJoin,
            this._model.ProcessCardsLifeStatuses,
            this.RecordId,
            this.EntityId
        );

        this._maxRowHeight = parseInt(maxRowHeight(), 10) < 100 ? '100' : maxRowHeight();

        if (isVisibleFavorite) {
            this._favoriteCell = new FavoriteCell();
        } else {
            this._favoriteCell = null;
        }

        this._isEditMode = ko.observable(false);
        this._isVisibleButtons = ko.observable(isVisibleButtons);
        this._isEnableSelectRecord = isEnableSelectRecord;

        let isEditOrListScreen =
            this._screenType === ScreenTypes[ScreenTypes.EditScreen]
            || this._screenType === ScreenTypes[ScreenTypes.ListScreen]
            || this._screenType === ScreenTypes[ScreenTypes.SpecialScreen];

        this._isLockConfirmated = !isEditOrListScreen;
        this._actionCell.IsLocked = !isEditOrListScreen;
        this._actionCell.ScreenType = screenType;
        this._actionCell.IsVisibleButtons = isVisibleButtons;
        this._actionCell.HideToggleDisableButton = this._model.HideToggleDisableButton || this._hideLifeStatusToggle();
        this._actionCell.HideUnlink = this._hideUnlink();
        this._actionCell.HideEdit = this._hideEdit();
        this._isSelected = ko.observable(false);
        this._isNewRecord = isNewRecord;
        this._guid = Guid.NewGuid();

        this.ShowActionCell = ko.computed(() =>(this._isEditable()
            && (!this._hideUnlink() || !this._hideEdit() || !this._actionCell.HideToggleDisableButton || this._enableBasket()))
            || this._hasInlineControls || this.HasSubGrids);

        this.SortDescriptionColumn = ko.computed(()=> _.find(this._dataCells(), (cell) => this._screenType === ScreenTypes[ScreenTypes.ConsultScreen] && cell.IsSortDescription && (!!cell.DisplayValue || cell.IsEditMode )));

        this._isGroupMode = !!isGroupMode;
        this._isGroupActive = ko.observable(this._isGroupMode ? true : true );
        this.SetRowColor();

        this.Init();
        this.InitWrappedRows();
    }

    SetRowColor() {
        this._rowColorEnabled = ko.observable(this._gridProperties && this._gridProperties.GetPropertyValue(PROPERTIES.ROW_COLOR));

        if (this._rowColorEnabled()) {
            const colorFields = this._model.Data.filter(field => field.Type == FIELD_TYPES.Color);
            if (colorFields.length > 0) {
                const color = ColorConverter.ToHex(colorFields[0].Value)
                this._rowColor = ko.observable(color ? ColorConverter.Hexify(color, 0.4) : '');
            } else {
                this._rowColor = ko.observable('');
            }
        }
    }

    IsSelectedChanged() {
        this.Trigger(EVENTS.ROW_SELECTION_CHANGED, {Selected: this._isSelected()});
        return true;
    }

    get Guid() {
        return this._guid;
    }

    SetVisibleButtons(isVisibleButton: boolean) {
        this._isVisibleButtons(isVisibleButton);
    }

    get IsNewRecord(): boolean {
        return this._isNewRecord;
    }

    get IsSelected(): KnockoutObservable<boolean> {
        return this._isSelected;
    }

    SetIsSelected(value: boolean) {
        this._isSelected(value);
    }

    get DataCells(): Array<DataCell> {
        return this._dataCells();
    }

    get ActionCell(): ActionCell {
        return this._actionCell;
    }

    get Model(): GridRowModel {
        return this._model;
    }

    get RecordTypeId() {
        return this._model.RecordTypeId;
    }

    get RecordKindId() {
        return this._model.KindId;
    }

    get HasWrappedColumn(): boolean {
        const wrappedCell = _.find(this._dataCells(), (dataCell) => dataCell.IsWrapped);

        return !!wrappedCell;
    }

    get HasGroupColumn(): boolean {
        const groupCell = _.find(this._dataCells(), (dataCell) => dataCell.IsGroup);

        return !!groupCell;
    }

    get IsLocked(): boolean {
        const lockedCells = _.where(this.DataCells, {IsLocked: true});

        return lockedCells && lockedCells.length > 0;
    }

    get IsStartGroup(): boolean {
        return this._model.IsStartGroup;
    }

    get IsEndGroup(): boolean {
        return this._model.IsEndGroup;
    }

    GetTemplateName() {
        return 'Core/Controls/Grid/BaseGrid/GridRow/Templates/GridRow';
    }

    GetSortDescriptionRowTemplateName(){
        return 'Core/Controls/Grid/BaseGrid/GridRow/Templates/SortDescriptionRow';
    }

    get EntityId(): number {
        return this._model.EntityId;
    }

    get RecordId(): number {
        return this._model.RecordId;
    }

    get RelationshipType(): number {
        return this._model.RelationshipType;
    }

    get KSeq(): number {
        return this._model.KSeq;
    }

    get LifestatusId(): number {
        return this._model.LifestatusId;
    }

    get IsEditMode(): boolean {
        return this._isEditMode();
    }

    set IsEditMode(isEditMode: boolean) {
        this._isEditMode(isEditMode);
        this.ActionCell.IsEditMode = isEditMode;

        _.each(this.DataCells, item => item.IsEditMode = isEditMode);
    }

    get RecordInBasket() {
        return this._recordInBasket();
    }

    set RecordInBasket(recordInBasket: boolean) {
        this._recordInBasket(recordInBasket);
    }

    get GroupGuid(): string {
        return this._model.GroupGuid;
    }

    get Form(): IForm {
        return this._form;
    }

    set GroupVisible(visible: boolean){
        this._isGroupActive(this._isGroupMode ? visible : true);
    }

    get GroupVisible(): boolean {
        return this._isGroupActive();
    }

    private GetDependsOnDataCells(dataCells: Array<DataCell>, dependsOn: number, type: string): Array<DataCell> {

        const filtered = _.filter(dataCells, (cell) => {
            return cell.Model.FieldMetadata.Type === type && cell.Model.FieldMetadata.DependsOn === dependsOn;
        });

        return filtered;
    }

    private UpdateDependsOnCells(recordId: number, dependsOnId: number) {
        let fieldIds: Array<number> = [];
        const dataCells = this.GetDependsOnDataCells(this.DataCells, dependsOnId, 'Text');
        const cellsToUpdate = {};

        _.forEach(dataCells, (cell) => {
            const key = cell.Model.FieldMetadata.FilledById;
            if (cellsToUpdate[key]) {
                cellsToUpdate[key].push(cell);
            } else {
                cellsToUpdate[key] = [];
                cellsToUpdate[key].push(cell);
                fieldIds.push(key);
            }
        });

        if (fieldIds.length) {
            if (recordId) {
                RecordStore.GetDependsOnValues({FieldIds: fieldIds, RecordId: recordId})
                    .then((response: IGetDependsOnResponse) => {
                        const controls = response.ResultObject;
                        _.forEach(controls, (item) => {
                            const cells = cellsToUpdate[item.Id];
                            _.forEach(cells, (cell: DataCell) => {
                                cell.ViewValue = item.Value;
                            });
                        });
                    })
                    .fail((error) => {
                        new Notifier().Failed('Error getting data');
                    });
            } else {
                _.forEach(fieldIds, (fieldId) => {
                    const cells = cellsToUpdate[fieldId];
                    _.forEach(cells, (cell: DataCell) => {
                        cell.ViewValue = '';
                    });
                });
            }
        }
    }

    private Init() {
        this.AddEvent(EVENTS.ROW_SELECTION_CHANGED);
        this.AddEvent(EVENTS.OPEN_HYPERLINK);
        this.AddEvent(EVENTS.EDIT_RECORD);
        this.AddEvent(EVENTS.EDIT_LINK);
        this.AddEvent(EVENTS.CHANGE_VISIBLE_GROUP);
        this.AddEvent(EVENTS.CANCEL_EDIT);
        this.AddEvent(EVENTS.SAVE_RECORD);
        this.AddEvent(EVENTS.DELETE_RECORD);
        this.AddEvent(EVENTS.ADD_TO_BASKET);
        this.AddEvent(EVENTS.REMOVE_FROM_BASKET);
        this.AddEvent(EVENTS.SAVE_LIFESTATUS);
        this.AddEvent(EVENTS.REFRESH);
        this.AddEvent(EVENTS.UNLINK_RECORD);
        this.AddEvent(EVENTS.LINK_NEXT_RELATION);
        this.AddEvent(EVENTS.MOVE_ROW_UP);
        this.AddEvent(EVENTS.MOVE_ROW_DOWN);
        this.AddEvent(EVENTS.HOVER_SORT);
        this.AddEvent(EVENTS.UN_HOVER_SORT);
        this.AddEvent(EVENTS.BACK_LINKING_POPUP_REQUESTED);
        this.AddEvent(EVENTS.SELECT_RECORD);
        this.AddEvent(EVENTS.SHOW_ORIGINAL_IMAGE);
        this.AddEvent(EVENTS.RECORD_DELETED);
        this.AddEvent(TOOLBAR_EVENTS.LINK_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.LINK_PARENT_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD);
        this.AddEvent(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD);
        this.AddEvent(EVENTS.LOAD_SUB_GRID);
        this.AddEvent(EVENTS.EDIT_CLUSTERED_LINK);

        this.ActionCell.IsLifeStatusEnabled = this._model.LifestatusId === LIFESTATUS.ENABLED;

        const cells = [];

        _.each(this._model.Data, gridCellValueModel => {
            const dataCell = new DataCell({
                Model: gridCellValueModel,
                ScreenType: this._screenType,
                MaxRowHeight: this._maxRowHeight,
                IsNewRecord: this._isNewRecord,
                Form: this._form,
                QueryExpression: this._queryExpression
            });

            dataCell.On(EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => this.Trigger(EVENTS.OPEN_HYPERLINK, eventArgs.data));
            dataCell.On(EVENTS.REFRESH, this, () => this.Trigger(EVENTS.REFRESH));
            dataCell.On(EVENTS.EDIT_CLUSTERED_LINK, this, (eventArgs) => {
                this.Trigger(EVENTS.EDIT_CLUSTERED_LINK, { gridRow: eventArgs.data.gridRow, grid: eventArgs.data.grid });                
            });

            dataCell.On(EVENTS.SAVE_RECORD, this, this.SaveRow);
            
            dataCell.On(EVENTS.CREATE_OR_DELETE_SORT_DESCRIPTION, this, ()=>{
                _.each(this._dataCells(), cell=>{
                    if(cell.IsSortDescription){
                        cell.CreateOrRemoveDescription();
                    }
                });
            });
            
            dataCell.On(EVENTS.LINK_NEXT_RELATION, this, (eventArgs: any) => this.Trigger(EVENTS.LINK_NEXT_RELATION, {field: eventArgs.data.field}));
            dataCell.On(EVENTS.DROPDOWN_VALUE_SELECTED, this, (eventArgs: any) => this.UpdateDependsOnCells(eventArgs.data.recordId, eventArgs.data.fieldId));

            dataCell.On(EVENTS.SHOW_ORIGINAL_IMAGE, this, (eventArgs: any) => {
                this.Trigger(EVENTS.SHOW_ORIGINAL_IMAGE, {dataCell: eventArgs.data.dataCell});
            });

            dataCell.On(EVENTS.MOVE_ROW_DOWN, this, () => {
                this.Trigger(EVENTS.MOVE_ROW_DOWN, {SortCell: dataCell});
            });
            dataCell.On(EVENTS.MOVE_ROW_UP, this, () => {
                this.Trigger(EVENTS.MOVE_ROW_UP, {SortCell: dataCell});
            });

            dataCell.On(EVENTS.HOVER_SORT, this, () => {
                this.Trigger(EVENTS.HOVER_SORT, {SortCell: dataCell});
            });
            dataCell.On(EVENTS.UN_HOVER_SORT, this, () => {
                this.Trigger(EVENTS.UN_HOVER_SORT, {SortCell: dataCell});
            });


            dataCell.On(EVENTS.PRINT_LABEL, this, () => {
                this.PrintLabel(dataCell);
            });

            cells.push(dataCell);
        });

        this._dataCells(cells);

        LockManager.Instance.On(LOCK_EVENTS.RELEASED, this, (eventArgs: any) => {
            if (eventArgs.data.TableId === this._model.EntityId && eventArgs.data.RecordId === this._model.RecordId) {
                this.Trigger(EVENTS.CANCEL_EDIT);
            }
        });

        this._actionCell.On(EVENTS.EDIT_RECORD, this, (eventArgs: any) => {
            this.Trigger(EVENTS.EDIT_RECORD);
        });

        this._actionCell.On(EVENTS.EDIT_LINK, this, (eventArgs: any) => {
            this.Trigger(EVENTS.EDIT_LINK, { gridRow: this });
        });

        this._actionCell.On(EVENTS.CHANGE_LIFESTATUS, this, (eventArgs: any) => {
            this._model.LifestatusId = eventArgs.source.IsLifeStatusEnabled ? LIFESTATUS.ENABLED : LIFESTATUS.DISABLED;
            this.Trigger(EVENTS.SAVE_LIFESTATUS, this);
        });

        this._actionCell.On(EVENTS.CANCEL_EDIT, this, (eventArgs: any) => {
            LockManager.Instance.ReleaseLock(this._model.EntityId, this._model.RecordId);
            this.Trigger(EVENTS.CANCEL_EDIT);
        });

        this._actionCell.On(EVENTS.SAVE_RECORD, this, (eventArgs: any) => {
            this.SaveRow();
        });

        this._actionCell.On(EVENTS.DELETE_RECORD, this, (eventArgs: any) => this.Trigger(EVENTS.DELETE_RECORD));

        this._actionCell.On(EVENTS.UNLINK_RECORD, this, (eventArgs: any) => this.Trigger(EVENTS.UNLINK_RECORD));

        this._actionCell.On(EVENTS.CLICK_UNLINK_CHECKBOX, this, (eventArgs: any) => {
            if (!this._isSelectedForUnlinking()) {
                this._isSelectedForUnlinking(true);
            } else {
                this._isSelectedForUnlinking(false);
            }

            this._model.IsUnlinkCheckboxChecked = this._isSelectedForUnlinking();
        });

        this._actionCell.On(EVENTS.ADD_TO_BASKET, this, () => {
            this.Trigger(EVENTS.ADD_TO_BASKET, {Row: this})
        });
        this._actionCell.On(EVENTS.REMOVE_FROM_BASKET, this, () => {
            this.Trigger(EVENTS.REMOVE_FROM_BASKET, {Row: this})
        });

        this._actionCell.On('REFRESH', this, () => {
            this.Trigger(EVENTS.REFRESH);
        });

        this._actionCell.On(EVENTS.BACK_LINKING_POPUP_REQUESTED, this, eventArgs => {
            this.Trigger(EVENTS.BACK_LINKING_POPUP_REQUESTED, {
                ButtonName: eventArgs.data.ButtonName,
                SubTableView: eventArgs.data.SubTableView,
                Intention: eventArgs.data.Intention,
                LeftTableId: this._form.GetScreen().GetEntityId(),
                RightTableId: this.Model.EntityId,
                LeftRecordId: this._form.GetScreen().GetRecordId(),
                RightRecordId: this.Model.RecordId,
                Sequence: this.Model.KSeq
            });
        });

        _.each(this._model.NestedData, (subGridData: GridDataModel) => {
            subGridData.QueryExpression = this._queryExpression;
            subGridData.GridParentEntityId = this._model.EntityId;

            let enableSubGridBasket = ko.observable(false);

            if (this._enableBasket() && subGridData.Rows.length > 0) {
                if (this._model.EntityId === subGridData.Rows[0].EntityId) {
                    enableSubGridBasket = this._enableBasket;
                }
            }

            const grid = new BaseGrid({
                hideLifeStatusToggle: this._hideLifeStatusToggle,
                hideUnlink: this._hideUnlink,
                hideEdit: this._hideEdit,
                enableBasket: enableSubGridBasket,
                isEditable: ko.observable(true),
                screenType: this._screenType,
                isNested: true,
                properties: this._gridProperties,
                form: this._form,
                ParentRow: this
            });

            grid.On(EVENTS.ADD_TO_BASKET, this, (eventArgs) => {
                this.Trigger(EVENTS.ADD_TO_BASKET, {Row: eventArgs.data.Row})
            });

            grid.On(EVENTS.REMOVE_FROM_BASKET, this, (eventArgs) => {
                this.Trigger(EVENTS.REMOVE_FROM_BASKET, {Row: eventArgs.data.Row})
            });

            grid.On(EVENTS.RECORD_DELETED, this, () => {
                this.Trigger(EVENTS.RECORD_DELETED);
            });

            grid.On(EVENTS.REFRESH, this, () => {
                this.Trigger(EVENTS.REFRESH);
            });

            grid.On(EVENTS.DATA_SAVED, this, (eventArgs) => {
                this.Trigger(EVENTS.REFRESH, { SubGrid: eventArgs.data.SubGrid || grid, ParentRowId: eventArgs.data.ParentRowId || this.Model.RowId, UpdateRow: eventArgs.data.UpdateRow || this });
            });

            grid.On(EVENTS.OPEN_HYPERLINK, this, (eventArgs: any) => {
                UserVarsManager.Instance.AddRecent(eventArgs.data.EntityId, eventArgs.data.RecordId, eventArgs.data.RecordTypeId);
                Lockr.set(LOCAL_STORAGE.HYPERLINK_SOURCE, this._form ? this._form.GetScreen().GetEntityId().toString() : null)

                this.GoToRecordScreen(eventArgs.data);
            });

            grid.On(TOOLBAR_EVENTS.LINK_RECORD, this, (eventArgs) => {
                let record = this.GetMainRecord(subGridData.SubQueryGuid, subGridData.QueryExpression);
                
                this.Trigger(TOOLBAR_EVENTS.LINK_RECORD, eventArgs.data.MainRecordId && eventArgs.data.MainEntityId && eventArgs.data.RelatedEntityId ? eventArgs.data : {
                    MainRecordId: record.RecordId,
                    MainEntityId: record.EntityId,
                    RelatedEntityId: grid.Model.GridSubjectEntityId
                });
            });

            grid.On(TOOLBAR_EVENTS.LINK_PARENT_RECORD, this, (eventArgs) => {
                this.Trigger(TOOLBAR_EVENTS.LINK_PARENT_RECORD, eventArgs.data.Row && eventArgs.data.RelatedEntityId ? eventArgs.data : {
                    Row: this,
                    RelatedEntityId: grid.Model.GridSubjectEntityId
                });
            });

            grid.On(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, this, (eventArgs) => {
                let record = this.GetMainRecord(subGridData.SubQueryGuid, subGridData.QueryExpression);
                this.Trigger(TOOLBAR_EVENTS.ADD_AND_LINK_RECORD, eventArgs.data.Row && eventArgs.data.RelatedEntityId ? eventArgs.data : {
                    MainRecordId: record.RecordId,
                    MainEntityId: record.EntityId,
                    RelatedEntityId: grid.Model.GridSubjectEntityId
                });
            });

            grid.On(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, this, (eventArgs) => {
                let record = this.GetMainRecord(subGridData.SubQueryGuid, subGridData.QueryExpression);
                this.Trigger(TOOLBAR_EVENTS.SCAN_AND_LINK_RECORD, eventArgs.data.Row && eventArgs.data.RelatedEntityId ? eventArgs.data : {
                    MainRecordId: record.RecordId,
                    MainEntityId: record.EntityId,
                    RelatedEntityId: grid.Model.GridSubjectEntityId
                });
            });

            grid.On(EVENTS.EDIT_LINK, this, (eventArgs: any) => {
                let record = this.GetMainRecord(subGridData.SubQueryGuid, subGridData.QueryExpression);

                this.Trigger(EVENTS.EDIT_LINK, eventArgs.data.mainRecordId && eventArgs.data.mainEntityId && eventArgs.data.gridRow ? eventArgs.data : {
                    mainEntityId: record.EntityId,
                    mainRecordId: record.RecordId,
                    gridRow: eventArgs.data.gridRow
                });
            });

            grid.On(EVENTS.LOAD_SUB_GRID, this, (eventArgs) => {
                this.Trigger(EVENTS.LOAD_SUB_GRID, { SubGrid: eventArgs.data.SubGrid || grid, ParentRowId: eventArgs.data.ParentRowId || this.Model.RowId });
            });

            grid.On(EVENTS.CHANGE_VISIBLE_GROUP, this, (eventArgs: any) => {
                this.Trigger(EVENTS.CHANGE_VISIBLE_GROUP, { gridRow: eventArgs.data.gridRow });
            });

            grid.SetData(subGridData);
            this._nestedGrids.push(grid);

            this._actionCell.On(EVENTS.EXPAND, this, () => {
                 grid.Expand();
                 this.Trigger(EVENTS.LOAD_SUB_GRID, { SubGrid: grid, ParentRowId: this.Model.RowId });
            });

            this._actionCell.On(EVENTS.COLLAPSE, this, () => grid.Colapse());
        });

        if (this._favoriteCell) {
            this._favoriteCell.IsFavorite =
                UserVarsManager.Instance.IsInFavorites(this._model.RecordId, this._model.EntityId, 0);
        }

        if (this._queryExpression.EntityJoins.length === 0
            && this._queryExpression.SubEntityJoins.length > 0
            && this._queryExpression.SubEntityJoins[0].Entity.Metadata.IsView) {
            if (this._screenType === ScreenTypes[ScreenTypes.ConsultScreen]) {
                this._isEditable(false);
            }
        }

        if (this._screenType === ScreenTypes[ScreenTypes.Portlet]) {
            this._isEditable(this._hasProcessCards);
        }
    }

    GetMainRecord(guid: string, query: QueryExpressionModel): RecordKey{
        let entity = Util.GetEntityByGuid(guid, query);
        let parentEntity = Util.GetParentEntity(entity, query);
        let record = _.find(this._model.RecordKeys, (recordKey)=> recordKey.QueryEntityGuid === parentEntity.Guid);
        return record;
    }

    PrintLabel(dataCell: DataCell){
        let label = dataCell.DisplayValue;
        if(_.isEmpty(label)){
            let alias = _.find(this._dataCells(), (cell)=> cell.Model.FieldMetadata.Type == FIELD_TYPES.Alias);
            if(alias){
                label = alias.DisplayValue;
            }
        }        
        DocumentManagerStore.PrintLabel({ Barcode: label }).fail(err=>new Notifier().Failed(err.message));
    }

    private InitWrappedRows(){
        let maxWrapped = _.max(this._model.Data, (item) => item.CrossWrapped.length);

        if(this._model.Data.length > 0 && maxWrapped.CrossWrapped.length > 0){
            for(let indx=0; indx<maxWrapped.CrossWrapped.length; indx++){

                let model = new GridRowModel();
                _.each(this._model.Data, (cell)=>{
                    let wrappedCell = cell.CrossWrapped[indx];
                    if(wrappedCell){
                        model.Data.push(wrappedCell);
                    }else{
                        let emptyCell = new GridCellValueModel();
                        model.Data.push(emptyCell)
                    }
                });

                let row = new GridRow(
                    model,
                    this._isEditable,
                    ko.observable(true),
                    ko.observable(false),
                    ko.observable(false),
                    ko.observable(false),
                    ko.observable(true),
                    ko.observableArray([]),
                    this._isEnableSelectRecord,
                    null,
                    this._isVisibleButtons(),
                    this._queryExpression,
                    this._isNewRecord,
                    !!this._favoriteCell,
                    ko.observable(this._maxRowHeight),
                    this._form,
                    [],
                    false,
                    this._gridProperties,
                    this._isSubGrid,
                    this._isGroupMode,
                    false,
                    false
                )
                this._crossTableWrapped.push(row);
            }
        }
    }

    get CrossTableWrapped(): Array<GridRow> {
        return this._crossTableWrapped
    }

    get HasSubGridsData(): boolean {
        var result = false;
        _.each(this._model.NestedData, (nestedData: any) => {
            if (nestedData.TotalRecords > 0) {
                result = true;
            }
        });
        return result;
    }

    get HasSubGrids(): boolean {
        return this._model.NestedData.length > 0;
    }

    GoToRecordScreen(data: any) {
        data.IsOpenInModal = data.ShowInModal ? data.ShowInModal : this._form ? this._form.GetScreen().IsInModal() : true;
        data.Owner = this._form ? this._form.GetScreen() : null;
        PubSub.publish(PUB_SUB_EVENTS.GO_TO_RECORD_SCREEN, data);
    }

    SaveRow(): boolean {
        let isDataValid = true;

        _.each(this.DataCells, item => {
            if (!item.IsDataValid() && !item.IsReadOnly()) {
                isDataValid = false;
            }
        });

        if (isDataValid) {
            _.each(this.DataCells, item => {
                item.SaveControlValue(this.GetLinkEditorFieldData(item.Model.FieldMetadata.Id));
            });
            this.Trigger(EVENTS.SAVE_RECORD);
        }
        this.SetRowColor();
        return isDataValid;
    }

    SaveLinkEditorData(controls: Array<IControl>) {
        _.each(controls, (control) => {
            let controlField = control.GetFieldModel();
            if (controlField) {
                let cellByFieldId = _.find(this._dataCells(), (dataCell) => {
                    return dataCell.Model.FieldMetadata.Id === controlField.Id && dataCell.IsEnableEdit();
                });

                if (cellByFieldId) {
                    cellByFieldId.SaveLinkEditorValue(control);
                }
            }
        });
    }

    GetLinkEditorFieldData(fieldId: number): FieldDataModel {
        if (this._linkEditorData) {
            let linkEditorData = _.find(this._linkEditorData.LinkEditorChanges, (linkEditorChange) => {
                return linkEditorChange.EntityId == this.EntityId && linkEditorChange.RecordId == this.RecordId;
            });

            if (linkEditorData) {
                let fieldValue = _.find(linkEditorData.FieldValues, (fieldValue) => {
                    return fieldValue.FieldId === fieldId;
                });
                return fieldValue;
            }
        }
        return null;
    }

    EnterKey() {
        if (this._isEditMode) return true;
        this.Trigger(
            EVENTS.OPEN_HYPERLINK,
            { EntityId: this._model.EntityId, RecordId: this._model.RecordId, RecordTypeId: this._model.RecordTypeId }
        );
    }

    SelectRecord() {
        this.Trigger(
            EVENTS.SELECT_RECORD,
            {
                Row: this
            }
        );
    }

    get State(): States {
        return this._state;
    }

    set State(state: States) {
        this._state = state;
    }

    MarkRecodsInBasket(records: Array<number>) {
        this.RecordInBasket = records.indexOf(this.RecordId) >= 0;

        _.each(this._nestedGrids(), (nestedGrid) => {
            nestedGrid.MarkRecodsInBasket(records);
        });
    }

    set GroupedData(data) {
        this._groupedData = data;
    }

    get GroupedData() {
        return this._groupedData;
    }

    GetGroupedDataTooltip() {
        return this._groupedData.map(item => `${item.Title}: ${item.Value || ''}; `).join('');
    }

    OnHyperlinkClick(item: GroupedDataItem, event: JQuery.ClickEvent<HTMLElement>) {
        const recordkey = item.Cell.Model.RecordKeys[0];
        const isOpenAgenda = item.Cell.Model.EntityMetadata.Name === 'AGENDA';
        const entityId = recordkey.EntityId;
        const recordTypeId = item.Cell.Model.RecordTypeId;

        let ids = _.uniq(item.Cell.Model.RecordKeys.map(item=>item.RecordId));

        if(ids.length > 1){
            this.ShowGroupDetail(entityId, ids, isOpenAgenda, event);
        }else{
            this.ExecuteHyperlinkTrigger(entityId, isOpenAgenda, recordkey.RecordId, recordTypeId, event.ctrlKey);
        }
    }

    private ShowGroupDetail(
        entityId: number,
        recordIds: Array<number>,
        isOpenAgenda: boolean,
        event: JQuery.ClickEvent<HTMLElement>
    ){
        BlockUI.Block();
        GridStore.GetGroupDetail({ EntityId: entityId, RecordIds: recordIds })
            .always(() => {
                BlockUI.Unblock();
            })
            .then(result => {
                if (event.target instanceof HTMLElement) {
                    const groupDetailsDropdown = new GroupDetailsDropdown();
                    groupDetailsDropdown.SetData(result);
                    groupDetailsDropdown.Show(event.target);
                    groupDetailsDropdown.On(QUERY_RESULT_GRID_EVENTS.RECORD_SELECTED, this, (eventArgs) => {
                        if (eventArgs.data.SelectedRecord.Model) {
                            groupDetailsDropdown.Close();
                            this.ExecuteHyperlinkTrigger(entityId, isOpenAgenda, eventArgs.data.SelectedRecord.Model.RecordId, eventArgs.data.SelectedRecord.Model.RecordTypeId, event.ctrlKey);
                        }
                    });
                }
            });
    }

    private ExecuteHyperlinkTrigger(
        entityId: number,
        isOpenAgenda: boolean,
        recordId: number,
        recordTypeId: number,
        showInModal: boolean
    ){
        this.Trigger(EVENTS.OPEN_HYPERLINK, {
            EntityId: entityId,
            IsOpenAgenda: isOpenAgenda,
            RecordId: recordId,
            RecordTypeId: recordTypeId,
            ShowInModal: showInModal
        });
    }

    set LinkEditorData(linkEditorData: UpdateDataModel) {
        this._linkEditorData = linkEditorData;
        _.each(this._linkEditorData.LinkEditorChanges, (item) => {
            item.Guid = this.Guid;
        });
    }

    get LinkEditorData(): UpdateDataModel {
        return this._linkEditorData;
    }

    GridUnlinkMultipleRecords() {
        this._actionCell.IsMultipleUnlinkModeActive = true;
    }

    GridConfirmMultipleUnlinking() {
        this._actionCell.IsMultipleUnlinkModeActive = false;
    }

    GridCancelMultipleUnlinking() {
        this._actionCell.IsMultipleUnlinkModeActive = false;
    }

    SubGridUnlinkMultipleRecords() {
        this._actionCell.IsMultipleUnlinkModeActive = true;
    }

    SubGridConfirmMultipleUnlinking() {
        this._actionCell.IsMultipleUnlinkModeActive = false;
    }

    SubGridCancelMultipleUnlinking() {
        this._actionCell.IsMultipleUnlinkModeActive = false;
    }



    SerializeLinkEditorData(): UpdateDataModel {
        if (this._linkEditorData) {
            return Serialize(this._linkEditorData);
        }
        return null;
    }

    GetColumns(isWrapped: boolean) {        
        return _.filter(this._dataCells(), (cell) => {
            return cell.IsWrapped === isWrapped && !cell.IsSortDescription;
        })
    }

    GetWrappedColumns() {
        let result = [];

        let wrappedCells = _.filter(this._dataCells(), (cell) => cell.IsWrapped);

        let wrapGroups = _.groupBy(wrappedCells, cell=>cell.Model.WrapGroup);
        _.each(wrapGroups, (group)=>{
            result.push(group);
        });

        return result;
    }

    GridGroupToggle(): void {
        this._isGroupActive(!this._isGroupActive());
        this.Trigger(EVENTS.CHANGE_VISIBLE_GROUP, {GroupActive: this._isGroupActive()});
    }

    AfterRender(elements: Array<HTMLElement>) {
        this._el = _.find(elements, (el) => !!el.className && el.className.includes('flex-grid-row parent-row parent have-related'));
        
        const $el: any = $(this._el);

        if (
            this._form &&
            this._screenType !== ScreenTypes[ScreenTypes.EditScreen] &&
            this._isEntity &&
            !this.Model.IsGroup
        ) {
            const screen = this._form.GetScreen();

            if (screen.GetTableTypeFlowFolder()) {
                setTimeout(() => {
                    const gridContainer = $el.closest('.grid-relative-container');

                    const resizeObserver = new ResizeObserver(() => {
                        $el.popover('destroy');

                        $el.popover({
                            content: '<i class="fa fa-ellipsis-v" /><i class="fa fa-ellipsis-v" />',
                            template: `
                                <div class="popover grid-relative-container__flow-folder-popover">
                                    <div class="popover-content" />
                                </div>
                            `,
                            html: true,
                            placement: 'left',
                            trigger: 'manual',
                            animation: false,
                            container: gridContainer,
                            viewport: gridContainer
                        });

                        $el.on('inserted.bs.popover', () => {
                            const $grip = $(`#${$el.attr('aria-describedby')}`);

                            $grip.data('EntityId', this.EntityId);
                            $grip.data('RecordId', this.RecordId);

                            $grip.draggable({
                                helper: () =>
                                    $('<div />')
                                        .append($grip.clone().removeAttr('id')),
                                classes: { 'ui-draggable-dragging': 'flow-folder-dragging-helper' },
                                appendTo: 'body',
                                scrollSensitivity: 75,
                                zIndex: 100000
                            });
                        });

                        $el.popover('show');
                    });

                    resizeObserver.observe(gridContainer[0]);

                    ko.utils.domNodeDisposal.addDisposeCallback($el[0], () => {
                        $el.popover('destroy');

                        resizeObserver.unobserve(gridContainer[0]);
                    });
                }, 500);
            }
        }
    }

    get El(): HTMLElement {
        return this._el;
    }

    get IsGridRow(): boolean{
        return true;
    }

    HighlightSort(){
        this._showSortBorder(true);
    }

    UnHighlightSort(){
        this._showSortBorder(false);
    }
}